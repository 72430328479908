import { Box, Flex, Grid, HStack, Spacer, VStack, useBreakpointValue } from '@chakra-ui/react';
import { PATH_NAME } from 'configs';
import { PermissionGuard } from 'guards';
import React from 'react';
import { Trans } from 'react-i18next';
import { DeviceMode } from 'types';
import useResizeObserver from 'use-resize-observer';

import { InternalPermission } from 'types/permission';

import { Card, PrimaryButton, Typography } from 'components/atoms';

import { useDeviceMode, useRouter, useScreenMode, useTranslate } from 'hooks/common';
import { useCompanySummary } from 'hooks/components/company';

import { LAYOUT_CONFIG } from 'configs/layout';

import { Donut, DonutProps } from './Donut';

export const CompanyWidget: React.FC = () => {
  const router = useRouter();
  const device = useDeviceMode();
  const screen = useScreenMode();
  const t = useTranslate();

  const minWidth = useBreakpointValue({ base: 200, xl: 230, lxl: 230, '2xl': 250 }, screen);
  const thickness = useBreakpointValue({ base: 20, xl: 20, lxl: 20, '2xl': 25 }, screen);

  const { ref, width = 250 } = useResizeObserver<HTMLDivElement>();
  const [, loading, , overview] = useCompanySummary();

  const companyOverall: DonutProps['dataset'] = [
    { value: overview?.totalActive || 0, label: t('label.enabledCompanies'), color: 'primary' },
    { value: overview?.totalDisable || 0, label: t('label.disabledCompanies'), color: 'secondary' },
  ];

  const handleGoNew = () => {
    router.push(PATH_NAME.COMPANY_NEW);
  };

  const handleGoList = () => {
    router.push(PATH_NAME.COMPANY);
  };

  const renderContent = () => {
    switch (device) {
      case DeviceMode.Desktop:
        return (
          <>
            <Flex h="100%">
              <VStack w="40%">
                <Box w="100%">
                  <Typography.Title variant="bold" size="lg" textTransform="uppercase">
                    {t('label.company')}
                  </Typography.Title>
                </Box>
                <Spacer />
                <VStack>
                  <Typography.Title fontSize={{ lg: 'sm-3xl', '2xl': '3xl' }} lineHeight="1">
                    {overview?.total || 0}
                  </Typography.Title>
                  <Typography.Text textAlign="center">
                    <Trans
                      t={t}
                      i18nKey={'message.totalNumberOfPartnerCompanies'}
                      components={[<br key={0} />]}
                    />
                  </Typography.Text>
                </VStack>
                <Spacer />
                <VStack>
                  <PermissionGuard
                    permissions={InternalPermission.CREATE_COMPANY}
                    component={PrimaryButton}
                    w="100%"
                    onClick={handleGoNew}
                    textTransform="uppercase"
                  >
                    {t('button.addCompany')}
                  </PermissionGuard>
                  <PrimaryButton w="100%" bg="secondary" onClick={handleGoList}>
                    {t('label.allCompanies')}
                  </PrimaryButton>
                </VStack>
                <Spacer />
              </VStack>
              <Box flex={1} ref={ref}>
                <Flex h="100%" justifyContent="flex-end" alignItems="center">
                  <Donut
                    width={Math.min(width, minWidth ?? 200)}
                    thickness={thickness}
                    dataset={companyOverall}
                    animationDuration={800}
                  />
                </Flex>
              </Box>
            </Flex>
          </>
        );
      case DeviceMode.Tablet:
        return (
          <>
            <VStack>
              <HStack w="100%" justify="space-between">
                <Typography.Title
                  variant="bold"
                  size="lg"
                  alignSelf="self-start"
                  textTransform="uppercase"
                >
                  {t('label.company')}
                </Typography.Title>

                <VStack>
                  <Typography.Title fontSize="19px" lineHeight="1">
                    {overview?.total || 0}
                  </Typography.Title>
                  <Typography.Text textAlign="center">
                    <Trans
                      t={t}
                      i18nKey={'message.totalNumberOfPartnerCompanies'}
                      components={[<br key={0} />]}
                    />
                  </Typography.Text>
                </VStack>
              </HStack>

              <Donut
                width={Math.min(width, minWidth ?? 200)}
                thickness={thickness}
                dataset={companyOverall}
                animationDuration={800}
                align="center"
              />

              <Grid templateColumns="1fr 1fr" columnGap="15px">
                <PrimaryButton onClick={handleGoNew} textTransform="uppercase">
                  {t('button.addCompany')}
                </PrimaryButton>

                <PrimaryButton bg="secondary" onClick={handleGoList} textTransform="uppercase">
                  {t('label.allCompanies')}
                </PrimaryButton>
              </Grid>
            </VStack>
          </>
        );

      case DeviceMode.Mobile:
        return (
          <>
            <VStack spacing="20px">
              <Typography.Title
                variant="bold"
                size="lg"
                alignSelf="self-start"
                textTransform="uppercase"
              >
                {t('label.company')}
              </Typography.Title>

              <VStack>
                <Typography.Title fontSize="lg" lineHeight="1">
                  {overview?.total || 0}
                </Typography.Title>
                <Typography.Text textAlign="center">
                  <Trans
                    t={t}
                    i18nKey={'message.totalNumberOfPartnerCompanies'}
                    components={[<br key={0} />]}
                  />
                </Typography.Text>
              </VStack>

              <Donut
                width={Math.min(width, minWidth ?? 200)}
                thickness={thickness}
                dataset={companyOverall}
                animationDuration={800}
                align="center"
              />

              <VStack w="100%" pt="15px">
                <PrimaryButton w="100%" onClick={handleGoNew} textTransform="uppercase">
                  {t('button.addCompany')}
                </PrimaryButton>
                <PrimaryButton
                  w="100%"
                  bg="secondary"
                  onClick={handleGoList}
                  textTransform="uppercase"
                >
                  {t('label.allCompanies')}
                </PrimaryButton>
              </VStack>
            </VStack>
          </>
        );

      default:
        return <div />;
    }
  };

  return (
    <Card
      loading={device ? loading : true}
      round={LAYOUT_CONFIG.dashboard.round}
      boxShadow={LAYOUT_CONFIG.dashboard.boxShadow}
      fitParent
      p={{ ...LAYOUT_CONFIG.dashboard.widgetPadding, base: '20px 15px' }}
    >
      {renderContent()}
    </Card>
  );
};
