import { Box, Center, Grid, GridItem, Spacer, VStack } from '@chakra-ui/react';
import { PATH_NAME } from 'configs';
import { PermissionGuard } from 'guards';
import { withPermission } from 'hocs/withPermission';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import { companyApi } from 'apis/company';

import { PartnerPermission } from 'types/permission';

import { Card, PrimaryButton, TermsAndConditionsDownload, Typography } from 'components/atoms';

import { useExportQuery, useRouter, useToasts, useTranslate } from 'hooks/common';
import { useCurrentUser } from 'hooks/components/auth';
import { useHasPermissions } from 'hooks/components/auth/useHasPermissions';
import { useRatingOverview } from 'hooks/components/dashboard/useRatingOverview';
import { useFirstLocation } from 'hooks/components/location';

import { LAYOUT_CONFIG } from 'configs/layout';

import { Rate } from '.';

export const CompanyRatingOverviewWidget: FC = () => {
  const router = useRouter();
  const location = useFirstLocation();
  const { notiFailed } = useToasts();
  const t = useTranslate();
  const user = useCurrentUser();
  const canViewRating = useHasPermissions(PartnerPermission.VIEW_RATING_OVERVIEW);
  const canDownloadContract = useHasPermissions(PartnerPermission.DOWNLOAD_SERVICE_CONTRACT);

  const [data] = useRatingOverview();

  const companyId = user?.company?.id;

  const [exportServiceContract, exportLoadingServiceContract] = useExportQuery(
    companyApi.exportServiceContract,
    () => `jod cfs agreement v3.pdf`,
  );

  const handleViewFeedback = () => {
    if (location) {
      router.push(PATH_NAME.LOCATION_FEEDBACK(location.id), {
        location,
        // fromPath: router.pathname,
        fromPath: PATH_NAME.LOCATION,
      });
    } else {
      notiFailed(t('message.somethingWentWrong'));
    }
  };

  const handleDownloadServiceContract = () => {
    if (companyId) {
      exportServiceContract(companyId);
    }
  };

  if (canDownloadContract && !canViewRating) {
    return (
      <Card
        round={LAYOUT_CONFIG.dashboard.round}
        boxShadow={LAYOUT_CONFIG.dashboard.boxShadow}
        p={{ base: '20px', '2xl': '45px 15px 0px 30px', lg: '20px 15px' }}
        fitParent
      >
        <Grid
          pt={{ base: '0', lg: '20px' }}
          templateColumns={{ base: '100%', md: '1fr 1fr' }}
          w="100%"
          rowGap={{ base: '10px', '2xl': '40px', lg: '20px' }}
          justifyItems={{ md: 'center', lg: 'initial' }}
          columnGap={{ md: '10px', lg: 'initial' }}
        >
          <GridItem mt={{ base: '15px' }}>
            <Typography.Text
              textAlign={{ base: 'left', lg: 'center' }}
              // fontSize={{ base: 'md', lg: 'lg' }}
              fontSize="md"
              w="100%"
            >
              <Trans
                t={t}
                i18nKey="label.serviceContract"
                components={[
                  <Box key={0} as="br" display={{ base: 'none', lg: 'inline-block' }} />,
                ]}
              />
            </Typography.Text>
          </GridItem>
          <GridItem w={{ base: '100%' }} justifySelf="flex-end">
            <Center h="100%">
              <PrimaryButton
                bg="secondary"
                w={{ base: '100%', md: '148px', '2xl': '140px', lg: '100px' }}
                onClick={handleDownloadServiceContract}
                isLoading={exportLoadingServiceContract}
              >
                {t('button.download')}
              </PrimaryButton>
            </Center>
          </GridItem>
        </Grid>
      </Card>
    );
  }

  return (
    <Card
      round={LAYOUT_CONFIG.dashboard.round}
      boxShadow={LAYOUT_CONFIG.dashboard.boxShadow}
      p={{ base: '20px', '2xl': '45px 15px 0px 30px', lg: '20px 15px' }}
      fitParent
    >
      <VStack h="100%">
        <Typography.Title
          mb={{ base: '20px', lg: '0' }}
          alignSelf={{ base: 'flex-start', lg: 'center' }}
          fontSize={{ base: 'lg', lg: 'xl' }}
          textAlign="center"
          uppercase
        >
          {t('title.ratingOverview')}
        </Typography.Title>
        <Spacer />
        <Grid
          pt={{ base: '0', lg: '20px' }}
          templateColumns={{
            base: '100%',
            md: 'repeat(4, 1fr)',
            lg: '1fr',
            xl: '160px 1fr',
            '2xl': 'auto 1fr',
          }}
          templateRows={{ base: '1fr 1fr', md: 'auto', lg: '1fr 1fr' }}
          w="100%"
          rowGap={{ base: '10px', '2xl': '40px', lg: '20px' }}
          justifyItems={{ md: 'center', lg: 'initial' }}
          columnGap={{ md: '10px', lg: 'initial' }}
        >
          <GridItem>
            <VStack
              flexDirection={{ base: 'row-reverse', md: 'column', lg: 'column' }}
              h="100%"
              justify={{ base: 'space-between', lg: 'center' }}
            >
              <Rate value={data} size={{ base: '25px', '2xl': '25px', lg: '20px' }} viewOnly />
              <Typography.Text fontSize="md">{t('label.generalRating')}</Typography.Text>
            </VStack>
          </GridItem>
          <GridItem w={{ base: '100%' }} justifySelf="flex-end">
            <Center h="100%">
              <PermissionGuard
                permissions={PartnerPermission.VIEW_LOCATION_FEEDBACK}
                component={PrimaryButton}
                bg="primary"
                w={{ base: '100%', md: '148px', '2xl': '140px', lg: '148px', xl: '100px' }}
                onClick={handleViewFeedback}
                disabled={!location}
              >
                {t('button.rating')}
              </PermissionGuard>
            </Center>
          </GridItem>

          <PermissionGuard permissions={PartnerPermission.DOWNLOAD_SERVICE_CONTRACT}>
            <GridItem mt={{ base: '15px' }}>
              <Typography.Text
                textAlign={{ base: 'left', lg: 'center' }}
                // fontSize={{ base: 'md', lg: 'lg' }}
                fontSize="md"
                w="100%"
              >
                <Trans
                  t={t}
                  i18nKey="label.serviceContract"
                  components={[
                    <Box key={0} as="br" display={{ base: 'none', lg: 'inline-block' }} />,
                  ]}
                />
              </Typography.Text>
            </GridItem>
            <GridItem w={{ base: '100%' }} justifySelf="flex-end">
              <Center h="100%">
                <PrimaryButton
                  bg="secondary"
                  w={{ base: '100%', md: '148px', '2xl': '140px', lg: '148px', xl: '100px' }}
                  onClick={handleDownloadServiceContract}
                  isLoading={exportLoadingServiceContract}
                >
                  {t('button.download')}
                </PrimaryButton>
              </Center>
            </GridItem>
          </PermissionGuard>
          <TermsAndConditionsDownload />
        </Grid>
        <Spacer />
      </VStack>
    </Card>
  );
};

export const CompanyRatingOverviewWidgetWithPermission = withPermission(
  [PartnerPermission.VIEW_RATING_OVERVIEW, PartnerPermission.DOWNLOAD_SERVICE_CONTRACT],
  CompanyRatingOverviewWidget,
);
