import {
  Avatar,
  Box,
  BoxProps,
  Center,
  Collapse,
  Flex,
  Grid,
  HStack,
  Icon,
  Tag,
  useBoolean,
} from '@chakra-ui/react';
import noop from 'lodash/noop';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useResizeObserver from 'use-resize-observer';

import { authActions, currentUserSelector } from 'store/modules/auth';

import { colors } from 'themes/foundations/colors';

import { mapContactNumber, mapFullName } from 'apis/utils';

import { Card, PrimaryButton } from 'components/atoms';
import { Typography } from 'components/atoms';

import { useTranslate } from 'hooks/common';
import { useRouter } from 'hooks/common/useRouter';

import {
  EditCircleIcon,
  GlobeIcon,
  LetterIcon,
  PhoneIcon,
  PhotoIcon,
  TriangleIcon,
} from 'assets/icons';

import { LAYOUT_CONFIG } from 'configs/layout';
import { PATH_NAME } from 'configs/pathname';

import { LANGUAGES, Language } from 'constant/language';
import { useProfileContext } from 'containers/ProfileEditContainer';
import { hqStorage, userStorage } from 'configs/browser-storage';
import { hqUserApi } from 'apis/hq-user';
import i18n from 'configs/i18n';

export type ProfileInfoProps = {
  onClickProfileIcon?: () => void;
} & BoxProps;

export const CollapsableProfileInfo: FC<ProfileInfoProps> = ({
  onClickProfileIcon = noop,
  ...props
}) => {
  const user = useSelector(currentUserSelector);
  const router = useRouter();
  const t = useTranslate();

  const { setIsSuperHq } = useProfileContext();
  const dispatch = useDispatch();
  const hq = hqStorage.get()?.data;

  const { height: pinedHeight = 40, ref: pinedRef } = useResizeObserver();
  const [expanded, { toggle: toggleExpanded }] = useBoolean(false);
  const [superHqExpanded, { toggle: toggleSuperHqExpanded }] = useBoolean(false);
  const language = LANGUAGES.find((item) => item.code === user?.language_code) || LANGUAGES[0];
  const handleGoEdit = (isSuperHq: boolean) => {
    setIsSuperHq(isSuperHq);
    onClickProfileIcon();
    router.push(PATH_NAME.ACCOUNT_SETTINGS);
  };
  const handleSelectHQ = async () => {
    hqStorage.clear();
    const res = await hqUserApi.removeImpersonateHq();
    userStorage.set(res.data.user);

    dispatch(authActions.authen(res.data));

    if (i18n.language !== res.data.user.language_code) {
      i18n.changeLanguage(res.data.user.language_code ?? Language.English);
    }

    router.push(PATH_NAME.DASHBOARD);
  };

  const avatarHeight = expanded ? '80px' : '40px';

  const superHqAvatarHeight = superHqExpanded ? '80px' : '40px';


  return (
    <>
    {hq && (
      <Box {...props}>
          <Card
            boxShadow={`${colors.tangoOpacity[50]} 0px 0px 3px 0px`}
            bg="tango.100"
            pl="15px"
            position="relative"
            width='100%'
            mb={5}
          >
            <HStack w='100%' spacing="20px">
              <Typography.Title size="lg" fontWeight={700}>{user?.company?.name}</Typography.Title>
              <PrimaryButton onClick={handleSelectHQ} fontSize='12px' height={30}>
                Select HQ
              </PrimaryButton>
            </HStack>
          </Card>
        <Typography.Title size="md" lineHeight="1" noOfLines={3} breakWord mb={2}>
                    Logged in as:
                  </Typography.Title>
        <Card
          boxShadow={`${colors.tangoOpacity[50]} 0px 0px 3px 0px`}
          bg="tango.200"
          pl="15px"
          pr="25px"
          position="relative"
        >
          <HStack spacing="0">
            <Center w={superHqExpanded ? '108px' : '60px'} transition="width 0.2s ease-in">
              <Avatar
                transition="all 0.2s ease-in"
                boxSize={superHqAvatarHeight}
                bg="white"
                color="tango.600"
                src={user?.avatar_url ? user?.avatar_url : ''}
                icon={
                  <Icon
                    as={PhotoIcon}
                    transition="all 0.2s ease-in"
                    width={superHqExpanded ? '32px' : '16px'}
                    height={superHqExpanded ? '24px' : '12px'}
                  />
                }
              />
            </Center>
            <Box position="relative" flex={1}>
              <Collapse in={superHqExpanded} startingHeight={pinedHeight}>
                <Box maxW="150px" wordBreak="break-word" ref={pinedRef}>
                  <Typography.Title size="lg" lineHeight="1" noOfLines={3} breakWord>
                    {user && mapFullName(user)}
                  </Typography.Title>
                </Box>
                <Typography.Text
                  size="md"
                  lineHeight="1"
                  opacity={user?.job_title ? 1 : 0}
                  mt="10px"
                  w="108px"
                >
                  {user?.job_title ?? '...'}
                </Typography.Text>
              </Collapse>

              <Center
                position="absolute"
                right="0"
                bottom="0"
                pr={superHqExpanded ? '10px' : '20px'}
                height={superHqExpanded ? '24px' : '100%'}
                transition="height 0.2s ease-in, padding-right 0.2s ease-in"
              >
                <Icon boxSize="24px" cursor="pointer" as={EditCircleIcon} onClick={() => handleGoEdit(true)} />
              </Center>
            </Box>
          </HStack>

          <Center h="100%" top="0" bottom="0" right="10px" position="absolute" ml="0">
            <Icon
              w="16px"
              h="auto"
              cursor="pointer"
              transform={superHqExpanded ? 'rotate(0)' : 'rotate(180deg)'}
              transition="transform 0.2s ease-in"
              as={TriangleIcon}
              onClick={toggleSuperHqExpanded}
            />
          </Center>
        </Card>

      <Collapse in={superHqExpanded}>
        <Box mt="30px" pl={LAYOUT_CONFIG.sidebar.paddingLeft.base} pb="2px">
          <Grid
            templateColumns="fit-content(200px) 1fr"
            rowGap={{ base: '16px', '2xl': '18px' }}
            columnGap={{ base: '10px', '2xl': '16px' }}
          >
            <HStack>
              <PhoneIcon />
              <Typography.Text
                variant="bold"
                whiteSpace={{ '2xl': 'nowrap' }}
                maxWidth={{ base: '200px', lg: '60px', '2xl': '200px' }}
              >
                {t('label.phoneNumber')}
              </Typography.Text>
            </HStack>
            <Flex alignItems="center">
              <Typography.Text>{user && mapContactNumber(user)}</Typography.Text>
            </Flex>

            <HStack>
              <LetterIcon />
              <Typography.Text variant="bold">{t('label.email')}</Typography.Text>
            </HStack>

            <Typography.Text isTruncated showTooltip>
              {user?.email}
            </Typography.Text>

            <HStack>
              <GlobeIcon />
              <Typography.Text variant="bold">{t('label.language')}</Typography.Text>
            </HStack>
            <Box>{language && <Tag variant="outlineShadow">{language?.label}</Tag>}</Box>
          </Grid>
        </Box>
      </Collapse>
      </Box>
    )}

      <Box {...props}>
        {hq && (
            <Typography.Title size="md" lineHeight="1" noOfLines={3} breakWord mb={2}>
              HQ Manager:
            </Typography.Title>
        )}
        <Card
          boxShadow={`${colors.tangoOpacity[50]} 0px 0px 3px 0px`}
          bg={hq ? "tango.100" : "tango.200"}
          pl="15px"
          pr="25px"
          position="relative"
        >
          <HStack spacing="0">
            <Center w={expanded ? '108px' : '60px'} transition="width 0.2s ease-in">
              <Avatar
                transition="all 0.2s ease-in"
                boxSize={avatarHeight}
                bg="white"
                color="tango.600"
                src={hq?.avatar_url ? hq?.avatar_url : user?.avatar_url}
                icon={
                  <Icon
                    as={PhotoIcon}
                    transition="all 0.2s ease-in"
                    width={expanded ? '32px' : '16px'}
                    height={expanded ? '24px' : '12px'}
                  />
                }
              />
            </Center>
            <Box position="relative" flex={1}>
              <Collapse in={expanded} startingHeight={pinedHeight}>
                <Box maxW="150px" wordBreak="break-word" ref={pinedRef}>
                  <Typography.Title size="lg" lineHeight="1" noOfLines={3} breakWord>
                    {hq ? hq && mapFullName(hq) : user && mapFullName(user)}
                  </Typography.Title>
                </Box>
                <Typography.Text
                  size="md"
                  lineHeight="1"
                  opacity={user?.job_title ? 1 : 0}
                  mt="10px"
                  w="108px"
                >
                  {hq ? hq?.job_title : user?.job_title }
                </Typography.Text>
              </Collapse>

              <Center
                position="absolute"
                right="0"
                bottom="0"
                pr={expanded ? '10px' : '20px'}
                height={expanded ? '24px' : '100%'}
                transition="height 0.2s ease-in, padding-right 0.2s ease-in"
              >
                <Icon boxSize="24px" cursor="pointer" as={EditCircleIcon} onClick={() => handleGoEdit(false)} />
              </Center>
            </Box>
          </HStack>

          <Center h="100%" top="0" bottom="0" right="10px" position="absolute" ml="0">
            <Icon
              w="16px"
              h="auto"
              cursor="pointer"
              transform={expanded ? 'rotate(0)' : 'rotate(180deg)'}
              transition="transform 0.2s ease-in"
              as={TriangleIcon}
              onClick={toggleExpanded}
            />
          </Center>
        </Card>

        <Collapse in={expanded}>
          <Box mt="30px" pl={LAYOUT_CONFIG.sidebar.paddingLeft.base} pb="2px">
            <Grid
              templateColumns="fit-content(200px) 1fr"
              rowGap={{ base: '16px', '2xl': '18px' }}
              columnGap={{ base: '10px', '2xl': '16px' }}
            >
              <HStack>
                <PhoneIcon />
                <Typography.Text
                  variant="bold"
                  whiteSpace={{ '2xl': 'nowrap' }}
                  maxWidth={{ base: '200px', lg: '60px', '2xl': '200px' }}
                >
                  {t('label.phoneNumber')}
                </Typography.Text>
              </HStack>
              <Flex alignItems="center">
                <Typography.Text>{hq ? hq && mapContactNumber(hq) : user && mapContactNumber(user)}</Typography.Text>
              </Flex>

              <HStack>
                <LetterIcon />
                <Typography.Text variant="bold">{t('label.email')}</Typography.Text>
              </HStack>

              <Typography.Text isTruncated showTooltip>
                {hq ? hq?.email : user?.email}
              </Typography.Text>

              <HStack>
                <GlobeIcon />
                <Typography.Text variant="bold">{t('label.language')}</Typography.Text>
              </HStack>
              <Box>{language && <Tag variant="outlineShadow">{language?.label}</Tag>}</Box>
            </Grid>
          </Box>
        </Collapse>
      </Box>
    </>
  );
};
