import { useBreakpointValue } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeviceMode, ScreenMode } from 'types';

import { configActions, deviceSelector, screenSelector } from 'store/modules/config';

export const useTrackingDeviceScreen = () => {
  const storedDevice = useSelector(deviceSelector);
  const storedScreen = useSelector(screenSelector);
  const dispatch = useDispatch();

  const device = useBreakpointValue({
    base: DeviceMode.Mobile,
    md: DeviceMode.Tablet,
    lg: DeviceMode.Desktop,
  });
  const screen = useBreakpointValue({
    base: ScreenMode.Mobile,
    md: ScreenMode.Tablet,
    lg: ScreenMode.Desktop,
    xl: ScreenMode.DesktopXl,
    lxl: ScreenMode.DesktopLxl,
    '2xl': ScreenMode.Desktop2Xl,
  });

  useEffect(() => {
    if (device === undefined) return;
    if (device !== storedDevice) {
      dispatch(configActions.setDevice(device));
    }
  }, [dispatch, device, storedDevice]);

  useEffect(() => {
    if (screen === undefined) return;
    if (screen !== storedScreen) {
      dispatch(configActions.setScreen(screen));
    }
  }, [dispatch, screen, storedScreen]);
  return { device, screen };
};
