import { Box, Stack, VStack, useBreakpointValue, Grid } from '@chakra-ui/react';
import { PATH_NAME } from 'configs';
import { PermissionGuard } from 'guards';
import { withPermission } from 'hocs/withPermission';
import { useEffect, useState } from 'react';
import { Option } from 'types';
import useResizeObserver from 'use-resize-observer';

import { colors } from 'themes/foundations/colors';

import { JobStatusText } from 'models/job';
import { Role } from 'models/user';

import { PartnerPermission } from 'types/permission';

import { BarChar, Card, PrimaryButton, SelectBox, Typography } from 'components/atoms';

import { useRouter, useScreenMode, useTranslate } from 'hooks/common';
import { useCheckAllowedRole } from 'hooks/components/auth';
import { useJobOverview } from 'hooks/components/dashboard/useJobOverview';
import { useJobStatusTextList } from 'hooks/components/job';
import { useLocationOptions } from 'hooks/components/location';

import { LAYOUT_CONFIG } from 'configs/layout';
import DatePicker from 'components/atoms/DatePicker';
import { Dayjs } from 'dayjs';

export const JobOverviewWidget = () => {
  const router = useRouter();
  const screen = useScreenMode();
  const t = useTranslate();
  const [location, setLocation] = useState<Option | null>(null);
  const chartFixedHeight = useBreakpointValue(
    {
      base: 235,
      md: 283,
      lg: 283,
      xl: 283,
      lxl: 283,
      '2xl': 283,
    },
    screen,
  );

  const [jobOverview, reFetch] = useJobOverview();
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const {
    total_active_jobs,
    total_open_jobs,
    total_completed_jobs_of_current_month,
    total_cancelled_jobs,
    jobData,
    timeData,
  } = jobOverview;
  const translatedTimeData = timeData?.map((item) => {
    const translatedString = String(t(('value.' + item[0].toLowerCase()) as any));
    return [
      translatedString.length > 3
        ? translatedString.slice(0, 2) + translatedString.split(' ').at(-1)
        : translatedString,
      item[1],
    ];
  });
  const canFilterByLocation = useCheckAllowedRole([Role.AreaManager, Role.HqManager]);
  const [locationOptions, loadingOptions] = useLocationOptions(!canFilterByLocation);
  const defaultOption: Option = {
    label: t('placeholder.allLocations'),
    value: 'all',
  };
  const displayOptions: Option[] = [defaultOption, ...(locationOptions ?? [])];

  const { ref, width: chartWidth, height: chartWrapperHeight } = useResizeObserver();

  const jobStatusTextList = useJobStatusTextList();

  const chartHeight = Math.max(chartFixedHeight ?? 0, chartWrapperHeight ?? 0) || undefined;
  const height = useBreakpointValue({ base: '40px', md: '40px', lg: '40px', xl: '40px', lxl: '40px', '2xl': '48px' },screen);

  const handleGoJobPosting = (status: JobStatusText) => () => {
    router.push(PATH_NAME.JOB, {
      jobStatus: jobStatusTextList.find((item) => item.value === status),
    });
  };

  const handleChangeLocation = (value: Option | null) => setLocation(value);

  const handleDateChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  useEffect(() => {
    let locationId: number | undefined = undefined;
    if (location && location.value !== 'all') {
      locationId = location.value;
    }

    const formattedStartDate = startDate !== null ? startDate.format('YYYY-MM') : undefined;
    const formattedEndDate = endDate !== null ? endDate.format('YYYY-MM') : undefined;

    reFetch({locationId, startDate: formattedStartDate, endDate: formattedEndDate});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, startDate, endDate]);
  return (
    <Card
      round={LAYOUT_CONFIG.dashboard.round}
      boxShadow={LAYOUT_CONFIG.dashboard.boxShadow}
      p={{ base: '20px', lg: '20px 15px 20px 15px', '2xl': '20px 20px 20px 20px' }}
      fitParent
    >
      <VStack align="stretch" h="100%">
        <Stack
          mb={{ base: '10px', lg: '0' }}
          width={{ base: 'auto', md: '100%', '2xl': 'auto' }}
          direction={{ base: 'column', lg: 'row', '2xl': 'row' }}
          justifyContent={{
            base: 'initial',
            md: 'space-between',
          }}
          spacing={{base: '10px', sm: '10px', md: '10px', lg: '25px', '2xl': '0px' }}
        >
          <Typography.Title
            alignSelf="flex-start"
            fontSize={{ base: 'lg', lg: 'xl' }}
            uppercase
          >
            {t('title.jobOverview')}
          </Typography.Title>
          <Stack direction={{ base: 'column', lg: 'row' }}>
            <Box maxWidth={{ xl: '200px' }} flexBasis={{ lg: '165px', xl: 'unset' }}>
              <DatePicker.RangePicker
                value={[startDate, endDate]}
                picker="month"
                format="YYYY-MM"
                placeholder={[t('label.startDate'), t('label.endDate')]}
                id="dateRange"
                onChange={handleDateChange}
                style={{ height: height }}
              />
            </Box>
            {canFilterByLocation && (
              <Box maxWidth={{ xl: '200px' }} flexBasis={{ lg: '165px', xl: 'unset' }}>
                <SelectBox
                  width="100%"
                  options={displayOptions ?? undefined}
                  onChange={handleChangeLocation}
                  loading={loadingOptions}
                  initialValue={defaultOption}
                  allowClear={false}
                />
              </Box>
            )}
          </Stack>
        </Stack>
        <Stack direction={{ base: 'column', sm: 'column', md: 'column', lg: 'row' }}>
          <Card
            round={LAYOUT_CONFIG.dashboard.round}
            boxShadow={LAYOUT_CONFIG.dashboard.boxShadow}
            p={{ base: '15px', lg: '20px 15px 0px 15px', '2xl': '20px 35px 0px 35px' }}
            flex={3}
            h="100%"
          >
            <VStack align="stretch">
              <Typography.Title 
                flex={1}
                alignSelf="flex-start"
                fontSize={{ base: 'sm', md: 'md', lg: 'md' }}
                uppercase
              >
                {t('title.job_data_numbers')}
              </Typography.Title>
              <VStack
                spacing="20px"
                align="stretch"
              >
                <Grid
                  templateColumns={{ base: '1fr 1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr 1fr', lg: '1fr 1fr 1fr 1fr' }}
                  gap={{ base: '20px', lg: '30px', '2xl': '40px' }}
                  alignItems={{ lg: 'initial', '2xl': 'initial' }}
                  alignSelf="center"
                  justifyItems="center"
                  p="10px"
                >
                  <VStack textAlign="center">
                    <Typography.Title
                      fontSize={{ base: 'md', md: 'lg', lg: 'lg', 'xl': '2xl' }}
                      lineHeight="1"
                    >
                      {total_open_jobs}
                    </Typography.Title>
                    <Typography.Text>{t('label.openJobs')}</Typography.Text>
                  </VStack>
                  <VStack textAlign="center">
                    <Typography.Title
                      fontSize={{ base: 'md', md: 'lg', lg: 'lg', 'xl': '2xl' }}
                      lineHeight="1"
                    >
                      {total_active_jobs}
                    </Typography.Title>
                    <Typography.Text>{t('label.activeJobs')}</Typography.Text>
                  </VStack>
                  <VStack textAlign="center">
                    <Typography.Title
                      fontSize={{ base: 'md', md: 'lg', lg: 'lg', 'xl': '2xl' }}
                      lineHeight="1"
                    >
                      {total_completed_jobs_of_current_month}
                    </Typography.Title>
                    <Typography.Text>
                      {t('label.completedJobs')}
                      <br />
                      <span style={{ fontSize: '10px' }}>{t('label.currentMonth')}</span>
                    </Typography.Text>
                  </VStack>
                  <VStack textAlign="center">
                    <Typography.Title
                      fontSize={{ base: 'md', md: 'lg', lg: 'lg', 'xl': '2xl' }}
                      lineHeight="1"
                    >
                      {total_cancelled_jobs}
                    </Typography.Title>
                    <Typography.Text>{t('label.cancelled')}</Typography.Text>
                  </VStack>
                </Grid>
              </VStack>
            </VStack>
          </Card>
          <Card
            round={LAYOUT_CONFIG.dashboard.round}
            boxShadow={LAYOUT_CONFIG.dashboard.boxShadow}
            flex={2}
            p={{ base: '10px', lg: '15px', '2xl': '20px' }}
            alignSelf="flex-start"
            h="100%"
            w="100%"
          >
            <VStack
              w="100%"
              h="100%"
              alignItems="flex-start"
              justifyContent="center"
              align="stretch"
            >
              <Typography.Title 
                alignSelf="flex-start"
                fontSize={{ base: 'sm', md: 'md', lg: 'md' }}
                uppercase
              >
                {t('title.job_selection')}
              </Typography.Title>
              <PermissionGuard permissions={PartnerPermission.VIEW_JOB}>
                <PrimaryButton
                  mb={{ base: '5px', lg: 'initial' }}
                  w="100%"
                  onClick={handleGoJobPosting(JobStatusText.Open)}
                >
                  {t('label.openJobs')}
                </PrimaryButton>
                <PrimaryButton
                  w="100%"
                  bg="#6F9C3D"
                  whiteSpace="normal"
                  onClick={handleGoJobPosting(JobStatusText.Active)}
                >
                  {t('label.activeJobs')}
                </PrimaryButton>
              </PermissionGuard>
            </VStack>
          </Card>
        </Stack>
        <Card
          flex="1"
          position="relative"
          ref={ref}
          round={LAYOUT_CONFIG.dashboard.round}
          boxShadow={LAYOUT_CONFIG.dashboard.boxShadow}
          fitParent
        >
          <BarChar
            datasets={jobData ?? []}
            labels={translatedTimeData ?? []}
            colors={[colors.graphOpen, colors.graphActive, colors.graphCompleted, colors.graphCancelled, colors.graphRejected, colors.graphNoShow]}
            stacked={true}
            legend={true}
            numOfColumns={1}
            width={chartWidth}
            height={chartHeight}
            hightLightEndLabel
            tooltip={{
              y: {
                formatter: (val) => {
                  return val.toLocaleString('en-US');
                },
              },
            }}
          />
        </Card>
      </VStack>
    </Card>
  );
};

export const JobOverviewWidgetWithPermission = withPermission(
  PartnerPermission.VIEW_JOB_OVERVIEW,
  JobOverviewWidget,
);
