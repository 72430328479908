import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CertificateState } from './type';

const initialState: CertificateState = {
  summary: {
    total: 0,
    init: false,
  },
  options: []
};

export const { actions: certificateActions, reducer: certificateReducer } = createSlice({
  name: 'certificate',
  initialState,
  reducers: {
    updateSummary(state, action: PayloadAction<Omit<CertificateState['summary'], 'init'>>) {
      const { payload } = action;

      state.summary = {
        ...payload,
        total: payload.total,
        init: false,
      };
    },
    updateOption(state, action: PayloadAction<CertificateState['options']>) {
      const { payload } = action;
      state.options = payload;
    },
  },
});

export * from './type';
export * from './selector';
