import { PermissionGuard } from 'guards';
import { ComponentProps, FC, ReactNode } from 'react';

import { SystemPermission } from 'types/permission';

export const withPermission = <T extends FC<any>>(
  permissions: SystemPermission | SystemPermission[],
  Component: T,
  fallback?: ReactNode,
) => {
  return (props: ComponentProps<T>) => {
    return (
      <PermissionGuard
        permissions={permissions}
        component={Component}
        fallback={fallback}
        {...props}
      />
    );
  };
};
