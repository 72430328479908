import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { creditActions, creditSelector } from 'store/modules/credit';

export const useAvailableCreditOfCompany = () => {
  const dispatch = useDispatch();
  const credit = useSelector(creditSelector);

  useEffect(() => {
    dispatch(creditActions.getAvailableCreditOfCompany());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return credit.availableCreditsOfCompany;
};
