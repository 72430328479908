import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VoidFunc } from 'types';

import {
  PaymentState,
  consumedCreditsSelector,
  isFetchingConsumedCreditsSelector,
  paymentActions,
} from 'store/modules/payment';

export const useLocationConsumedCredits = () => {
  const dispatch = useDispatch();
  const consumedCredits = useSelector(consumedCreditsSelector);
  const loading = useSelector(isFetchingConsumedCreditsSelector);

  const fetchConsumedCredits = () => {
    dispatch(paymentActions.getLocationConsumedCredits());
  };

  useEffect(() => {
    fetchConsumedCredits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const result: [VoidFunc, PaymentState['consumedCredits'], boolean] = [
    fetchConsumedCredits,
    consumedCredits,
    loading,
  ];

  return result;
};
