import { useMemo } from 'react';

import { Role } from 'models/user';

import { useCurrentRole, useCurrentUser } from 'hooks/components/auth';

import { RouteConfig, privateRoutes } from './routes';
import { useSelector } from 'react-redux';
import { isTwoFaVerifiedSelector } from 'store/modules/auth';
import { PATH_NAME } from 'configs';

export const useAuthorizedRoutes = () => {
  const currentRole = useCurrentRole();
  const isPartnerUser =
    currentRole && [Role.AreaManager, Role.HqManager, Role.LocationManager].includes(currentRole);

  const isSuperHqUser =
    currentRole && [Role.SuperHqInternal, Role.SuperHqExternal].includes(currentRole);
  
  const currentUser = useCurrentUser();
  const isTwoFaVerified = useSelector(isTwoFaVerifiedSelector);
  const react2fa = process.env.REACT_APP_TFA_AUTHENTICATION;
  const partnerRoles = [
    Role.HqManager,
    Role.AreaManager,
    Role.AreaManagerApproval,
    Role.LocationManager,
    Role.SuperHqInternal,
    Role.SuperHqExternal,
  ];

  const shouldUse2FA = 
    (react2fa === 'ALL') ||
    (react2fa === 'INTERNAL' && currentRole && !partnerRoles.includes(currentRole)) ||
    (react2fa === 'PARTNER' && currentRole && partnerRoles.includes(currentRole));

  const authorizedRoutes = useMemo<RouteConfig[]>(() => {
    const routes: RouteConfig[] = [];
    if (!currentRole) return routes;
    for (let currRoute of privateRoutes) {
      if (currRoute.roles?.length === 0 && currRoute.permissions?.length === 0) break;

      const haveMultiComponent =
        !!currRoute.roleComponents && Object.values(currRoute.roleComponents).length > 0;
      const matchedComponent = currRoute.roleComponents
        ? currRoute.roleComponents[currentRole]
        : undefined;

      if (haveMultiComponent && matchedComponent) {
        currRoute = { ...currRoute, component: matchedComponent };
      }
      const { type } = currRoute;

      const isValidRole =
        type === 'all' ? true : isSuperHqUser ? type === 'superHq' : isPartnerUser ? type === 'partner' : type === 'internal';

      if (isValidRole && currRoute.component) {
        routes.push(currRoute);
      }
    }
    // filter valid route
    // for (let currRoute of privateRoutes) {
    //   if (currRoute.roles?.length === 0 && currRoute.permissions?.length === 0) break;

    //   const haveMultiComponent =
    //     !!currRoute.roleComponents && Object.values(currRoute.roleComponents).length > 0;
    //   const matchedComponent = currRoute.roleComponents
    //     ? currRoute.roleComponents[currentRole]
    //     : undefined;

    //   if (haveMultiComponent && matchedComponent) {
    //     currRoute = { ...currRoute, component: matchedComponent };
    //   }
    //   const { roles, permissions } = currRoute;

    //   const isValidRole = roles?.length ? roles.includes(currentRole) : true;
    //   const isValidPermission = permissions?.length ? checKHasPermission(permissions) : true;

    //   if (isValidRole && isValidPermission && currRoute.component) {
    //     routes.push(currRoute);
    //   }
    // }

    if (!isTwoFaVerified && shouldUse2FA) {
      switch (currentUser?.tfa_method) {
        case 'AUTHENTICATOR':
        case 'SMS':
        case 'EMAIL':
          return routes.filter(route =>
            [PATH_NAME.SELECT_2FA_METHOD, PATH_NAME.AUTH_OTP, PATH_NAME.AUTH_OTP_QR, PATH_NAME.SUCCESSFUL_LOGIN].includes(route.path)
          );
        default:
          return routes.filter(route =>
            [PATH_NAME.SELECT_2FA_METHOD, PATH_NAME.AUTH_OTP, PATH_NAME.AUTH_OTP_QR, PATH_NAME.SUCCESSFUL_LOGIN].includes(route.path)
          );
      }
    }

    return routes;
  }, [currentRole, isPartnerUser, isSuperHqUser, isTwoFaVerified]);

  return authorizedRoutes;
};
