import { Box, Center, HStack, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import { PATH_NAME } from 'configs';
import React, { useMemo } from 'react';
import { DeviceMode } from 'types';
import useResizeObserver from 'use-resize-observer';

import { colors } from 'themes/foundations/colors';

import { DashboardApi, dashboardApi } from 'apis/dashboard';

import { IPaymentSummaryChart } from 'models/dashboard';

import { BarChar, Card, Legend, PrimaryButton } from 'components/atoms';

import { useDeviceMode, useQuery, useRouter, useScreenMode, useTranslate } from 'hooks/common';

import { LAYOUT_CONFIG } from 'configs/layout';

export const PaymentSummaryWidget: React.FC = (props) => {
  const router = useRouter();

  const device = useDeviceMode();
  const screen = useScreenMode();
  const t = useTranslate();

  const chartFixedHeight = useBreakpointValue(
    {
      base: 235,
      md: 370,
      lg: 370,
      xl: 370,
      lxl: 370,
      '2xl': 370,
    },
    screen,
  );

  const { ref, width: chartWidth, height: chartWrapperHeight } = useResizeObserver();
  const chartHeight = device === DeviceMode.Desktop ? chartWrapperHeight : chartFixedHeight;
  const [data, , ,] = useQuery<IPaymentSummaryChart[], null, DashboardApi['getPaymentsStatistics']>(
    null,
    dashboardApi.getPaymentsStatistics,
    { preventInitCall: false },
  );

  const unProcessedData = useMemo(() => {
    return data?.map((entity) => entity.unprocessed_credits);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const processedData = useMemo(() => {
    return data?.map((entity) => entity.processed_credits);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const timeData = useMemo(() => {
    return data?.map((entity) => [entity.month, entity.year]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const translatedTimeData = timeData?.map((item) => [
    t(('value.' + item[0].toLowerCase()) as any),
    item[1],
  ]);
  const handleGoPaymentDetail = () => {
    router.push(PATH_NAME.PAYMENT_SUMMARY);
  };
  return (
    <Card
      round={LAYOUT_CONFIG.dashboard.round}
      bg="secondary"
      color="white"
      p={{
        base: LAYOUT_CONFIG.dashboard.widgetPadding.base,
        md: '20px 20px 0 20px',
        xl: '25px 25px 0 25px',
        '2xl': '30px 30px 0 30px',
      }}
      textAlign="center"
      fitParent
    >
      <Stack direction="column" h="100%" spacing={{ base: '25px', md: 0 }}>
        <Center w="100" position="relative">
          {device !== DeviceMode.Mobile && (
            <Box position="absolute" top="0" left="0">
              <PrimaryButton
                onClick={handleGoPaymentDetail}
                w={LAYOUT_CONFIG.button.width}
                textTransform="uppercase"
              >
                {t('label.paymentDetails')}
              </PrimaryButton>
            </Box>
          )}
          <Text fontSize={{ base: 'lg', lg: 'xl' }} variant="bold" textTransform="uppercase">
            {t('label.paymentSummary')}
          </Text>
        </Center>
        {device !== DeviceMode.Mobile && (
          <HStack justify="center">
            <Legend color="tango.100" label={t('label.unprocessedCredits')} />
            <Legend color="tango.500" label={t('label.processedCredits')} />
          </HStack>
        )}
        <Box flex="1" ref={ref}>
          <BarChar
            datasets={[
              {
                name: t('label.unprocessedCredits'),
                data: unProcessedData ?? [],
              },
              {
                name: t('label.processedCredits'),
                data: processedData ?? [],
              },
            ]}
            labels={translatedTimeData ?? []}
            colors={[colors.tango[100], colors.tango[500]]}
            theme={{ mode: 'dark' }}
            width={chartWidth}
            numOfColumns={2}
            height={chartHeight}
            hightLightEndLabel
            tooltip={{
              y: {
                formatter: (val) => {
                  return val.toLocaleString('en-US');
                },
              },
            }}
          />
        </Box>

        {device === DeviceMode.Mobile && (
          <>
            <HStack justify="flex-start">
              <Legend color="tango.100" label={t('label.unprocessedCredits')} />
              <Legend color="tango.500" label={t('label.processedCredits')} />
            </HStack>
            <PrimaryButton onClick={handleGoPaymentDetail} w="100%" textTransform="uppercase">
              {t('label.paymentDetails')}
            </PrimaryButton>
          </>
        )}
      </Stack>
    </Card>
  );
};
