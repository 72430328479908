export const colors = {
  primary: '#F07615',
  danger: '#F03411',
  success: '#4CAF50',
  info: '#2196F3',
  secondary: '#324562',
  disable: '#A8ACB8',
  white: '#FEFFFF',
  lightGrey: '#e4e5e5',
  tango: {
    100: '#FCF5F0',
    200: '#FDDCC1',
    300: '#FD9543',
    400: '#F19B2E',
    500: '#F07615', // primary
    600: '#F05E14',
    700: '#FF6600',
    800: '#F03411',
  },
  tangoOpacity: {
    10: '#F05E141A',
    20: '#F05E1433',
    30: '#F05E144D',
    40: '#F05E1466',
    50: '#F05E1480',
    60: '#F05E1499',
    70: '#F05E14B3',
    80: '#F05E14CC',
    90: '#F05E14E6',
    100: '#F05E14',
  },
  vulcan: {
    100: '#f1f9ff',
    200: '#d7e9f5',
    300: '#90bed5',
    400: '#7184a0', // secondary
    500: '#324562', // secondary
    600: '#2c3e57',
    700: '#26344a',
    800: '#1c2636',
    900: '#0f151f',
  },
  vulcanOpacity: {
    10: '#3245621A',
    20: '#32456233',
    30: '#3245624D',
    40: '#32456266',
    50: '#32456280',
    60: '#32456299',
    70: '#324562B3',
    80: '#324562CC',
    90: '#324562E6',
    100: '#324562',
  },
  graphOpen: '#FF9240',
  graphActive: '#7DC1F6',
  graphCompleted: '#DBF4D3',
  graphCancelled: '#FCED65',
  graphRejected: '#F5E5F0',
  graphNoShow: '#BDB8DB',

};
