import { As, Box, BoxProps, Center, Grid, GridItem, Icon, Stack, VStack } from '@chakra-ui/react';
import { PATH_NAME } from 'configs';
import { ACCEPTED_IMG_FILE_TYPES } from 'constant';
import { PermissionGuard } from 'guards';
import i18next from 'i18next';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DeviceMode, ErrorDescription } from 'types';

import { dashboardActions } from 'store/modules/dashboard';

import { dashboardApi } from 'apis/dashboard';

import { InternalPermission } from 'types/permission';

import { Card, PrimaryButton, Typography, UploadButton } from 'components/atoms';

import { useConfirmState, useDeviceMode, useRouter, useToasts, useTranslate } from 'hooks/common';
import { useApplicantSummary } from 'hooks/components/applicant';
import { useCurrentPermission } from 'hooks/components/auth';
import { useAdminLogo } from 'hooks/components/dashboard';
import { useEducationalInstituteSummary } from 'hooks/components/educational-institute';
import { useJobTypeSummary } from 'hooks/components/job-type';
import { useLocationSummary } from 'hooks/components/location';

import { EditCircleIcon, EducationIcon, PlaceIcon, ResumeIcon, SearchIcon } from 'assets/icons';

import { LAYOUT_CONFIG } from 'configs/layout';

import { ConfirmModal } from '.';

type ActorsProps = {
  onSubmitLogo?: (...arg: any[]) => void | Promise<void>;
  useFormLogo?: any;
};

export const ActorsWidget: React.FC<ActorsProps> = () => {
  const router = useRouter();
  const device = useDeviceMode();
  const adminLogo = useAdminLogo();
  const t = useTranslate();
  const [, onLogo, , modalControllerLogo] = useConfirmState();
  const [logo, setLogo] = useState<FileList | null>(null);
  const [isViewUpload, setIsViewUpload] = useState<boolean | null>(null);
  const { notiFailed, notiSuccess } = useToasts();
  const dispatch = useDispatch();

  const [, jobTypeLoading, , jobTypeSummary] = useJobTypeSummary();
  const [, instituteLoading, , instituteSummary] = useEducationalInstituteSummary();
  const [, applicantLoading, , applicantSummary] = useApplicantSummary();
  const [, locationLoading, , locationSummary] = useLocationSummary();

  const [, checkHasPermission] = useCurrentPermission();

  const sections: Array<{
    icon: As;
    title: string | React.ReactNode;
    total: number;
    h?: BoxProps['h'];
    order: [number, number];
    visible: boolean;
  }> = [
    {
      icon: SearchIcon,
      title: i18next.t<string>('title.jobTypes'),
      total: jobTypeSummary?.total,
      order: [1, 2],
      visible: checkHasPermission(InternalPermission.VIEW_JOB_TYPE),
    },
    {
      icon: PlaceIcon,
      title: i18next.t<string>('title.locations'),
      total: locationSummary?.total,
      order: [3, 4],
      visible: checkHasPermission(InternalPermission.VIEW_LOCATION),
    },
    {
      icon: ResumeIcon,
      title: i18next.t<string>('title.applicants'),
      total: applicantSummary?.total,
      order: [5, 6],
      visible: checkHasPermission(InternalPermission.VIEW_APPLICANT),
    },
    {
      icon: EducationIcon,
      h: { base: '40px', '2xl': '55px' },
      title: (
        <Trans
          t={t}
          i18nKey={'title.educationalInstitutesHasTrans'}
          components={[<br key={0} />]}
        />
      ),
      // device === DeviceMode.Desktop ? (
      //   'Educational Institutes'
      // ) : (
      //   <>
      //     <Trans
      //       t={t}
      //       i18nKey={'title.educationalInstitutesHasTrans'}
      //       components={[<br key={0} />]}
      //     />
      //   </>
      // ),
      total: instituteSummary?.total,
      order: [7, 8],
      visible: checkHasPermission(InternalPermission.VIEW_EDUCATIONAL_INSTITUTE),
    },
  ];

  const handleLogoOk = async () => {
    if (!logo) return;
    try {
      const res = await dashboardApi.uploadAdminLogo(logo);
      if (res) {
        setIsViewUpload(true);
        dispatch(dashboardActions.updateAdminLogo(res.data.admin_logo_url));
      }

      notiSuccess(res.message);
    } catch (error: any) {
      const errorDescription: string = error.description.adminLogo[0];
      if (errorDescription) {
        notiFailed(errorDescription);
      }
    }
  };
  const handleChangeLogo = async (data: FileList | null) => {
    if (data) {
      onLogo();
      setLogo(data);
      setIsViewUpload(false);
    }
  };
  const handleOkFailed = (error: any) => {
    const errorDescription: ErrorDescription = error.description;
    if (errorDescription) {
      Object.entries(errorDescription).forEach(([key, value]) => {
        notiFailed(value[0]);
      });
    }
  };

  const handleGoReport = () => {
    router.push(PATH_NAME.REPORT);
  };

  const hasBase64 = isViewUpload ? true : false;

  return (
    <Card
      loading={jobTypeLoading || instituteLoading || applicantLoading || locationLoading}
      round={LAYOUT_CONFIG.dashboard.round}
      boxShadow={LAYOUT_CONFIG.dashboard.boxShadow}
      fitParent
      p={LAYOUT_CONFIG.dashboard.widgetPadding}
    >
      <ConfirmModal
        controller={modalControllerLogo}
        header={false}
        type="card"
        onOk={handleLogoOk}
        bodyProps={{
          textAlign: 'center',
        }}
        footerProps={{
          paddingLeft: { base: '0', lg: 'initial' },
          paddingRight: { base: '0', lg: 'initial' },
          justifyContent: 'center',
        }}
        onOkFailed={handleOkFailed}
        footer={(close, ok) => {
          return (
            <VStack w={{ base: '100%', lg: 'inherit' }}>
              <PrimaryButton w={LAYOUT_CONFIG.button.width} onClick={ok} textTransform="uppercase">
                {t('button.save')}
              </PrimaryButton>
              <Typography.Text cursor="pointer" onClick={close} size="md">
                {t('button.cancel')}
              </Typography.Text>
            </VStack>
          );
        }}
      >
        <Typography.Title whiteSpace="nowrap" align="center" fontSize={{ base: 'md', lg: 'lg' }}>
          {t('message.wantToChangeTheLogo')}
        </Typography.Title>
      </ConfirmModal>
      <Stack
        direction={{ base: 'column', md: 'row', lg: 'column' }}
        spacing={{ base: '30px', lg: '50px' }}
        align="center"
        justify="center"
      >
        <VStack spacing="30px" w={{ base: '100%', md: '50%', lg: 'auto' }}>
          <form>
            <Box position="relative">
              <UploadButton
                id="adminLogo"
                onChange={(e) => handleChangeLogo(e.target.files)}
                isUploadLogo={true}
                isIconUpload={true}
                previewImage={true}
                hasBase64={hasBase64}
                boxSize={{ base: '160px', '2xl': '200px' }}
                bg="tango.300"
                borderRadius="9999"
                allowFileExtensions={ACCEPTED_IMG_FILE_TYPES}
                placeholder="LOGO"
                labelStyles={{
                  fontWeight: 'bold',
                  color: '#fff',
                  fontStyle: 'normal',
                  fontSize: { base: 'md', lg: 'lg' },
                }}
                defaultImage={adminLogo.adminLogo ?? undefined}
                isViewOnly={!checkHasPermission(InternalPermission.CHANGE_DASHBOARD_LOGO)}
              />
              <Box as="label" htmlFor="adminLogo" position="absolute" bottom="5%" right="10%">
                <Icon width="32px" height="32px" cursor="pointer" as={EditCircleIcon} />
              </Box>
            </Box>
          </form>

          {device !== DeviceMode.Mobile && (
            <PermissionGuard
              permissions={InternalPermission.VIEW_REPORT}
              component={PrimaryButton}
              onClick={handleGoReport}
              w={LAYOUT_CONFIG.button.width}
              textTransform="uppercase"
            >
              {t('title.report')}
            </PermissionGuard>
          )}
        </VStack>
        <Grid
          w={{ base: '100%', md: '50%', lg: 'auto' }}
          rowGap="40px"
          columnGap={{ base: '10px', md: '20px', '2xl': '30px' }}
          templateColumns={{
            base: 'max-content 1fr 16px max-content 1fr',
            // md: 'repeat(4, max-content)',
            md: 'max-content 1fr max-content 1fr',
            lg: 'repeat(2, max-content)',
          }}
        >
          {device === DeviceMode.Mobile && (
            <GridItem rowSpan={2} order={3} visibility="hidden"></GridItem>
          )}

          {sections
            .filter((item) => item.visible)
            .map(
              ({ icon: SectionIcon, title, total, h, order: [iconOrder, contentOrder] }, index) => (
                <React.Fragment key={index}>
                  <GridItem order={iconOrder}>
                    <Center>
                      <Icon
                        h={h ?? { base: '40px', '2xl': '72px' }}
                        w="auto"
                        as={SectionIcon}
                        color="secondary"
                      />
                    </Center>
                  </GridItem>
                  <GridItem justifySelf="flex-start" order={contentOrder}>
                    <VStack alignItems={{ base: 'center', lg: 'flex-start' }} spacing="0">
                      <Typography.Title
                        fontSize={{ base: 'lg', lg: 'xl', '2xl': '2xl' }}
                        lineHeight="1"
                      >
                        {total}
                      </Typography.Title>
                      <Typography.Text
                        variant="italic"
                        textAlign="center"
                        fontSize={{ base: 'md', lg: 'sm-md', '2xl': 'md' }}
                      >
                        {title}
                      </Typography.Text>
                    </VStack>
                  </GridItem>
                </React.Fragment>
              ),
            )}
        </Grid>
        {device === DeviceMode.Mobile && (
          <PermissionGuard
            permissions={InternalPermission.VIEW_REPORT}
            component={PrimaryButton}
            onClick={handleGoReport}
            w="100%"
            textTransform="uppercase"
          >
            {t('title.report')}
          </PermissionGuard>
        )}
      </Stack>
    </Card>
  );
};
