import { current } from '@reduxjs/toolkit';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { currentUserPermissionSelector } from 'store/modules/auth';

import { SystemPermission } from 'types/permission';

export const useCurrentPermission = () => {
  const currentPermissions = useSelector(currentUserPermissionSelector);

  const checkHasPermission = useCallback(
    (permissions?: SystemPermission | SystemPermission[], strategy?: 'some' | 'every'): boolean => {
      if (!permissions) return true;
      if (!currentPermissions?.length) return false;
      if (permissions instanceof Array) {
        return currentPermissions[strategy ?? 'some']((item) => permissions.includes(item));
      }

      return currentPermissions.includes(permissions);
    },
    [currentPermissions],
  );

  return useMemo<[typeof currentPermissions, typeof checkHasPermission]>(
    () => [currentPermissions, checkHasPermission],
    [checkHasPermission, currentPermissions],
  );
};
