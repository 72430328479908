import i18next from 'i18next';
import { EnglishTranslation } from 'locales';

export const pluralize = (count: number, noun: string, suffix?: string) => {
  return `${noun}${count > 1 ? suffix ?? 's' : ''}`;
};

export const translatedPluralize = (
  count: number,
  key: keyof EnglishTranslation,
  suffix?: string,
) => {
  return i18next.t<string>(count > 1 ? `${key}${suffix ?? 's'}` : key);
};
