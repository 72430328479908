import classNames from 'classnames';
import React from 'react';

import { Text, TextProps, fontSizes } from './Text';

export interface TitleProps extends TextProps {
  htmlFor?: string;
  capitalize?: boolean;
  uppercase?: boolean;
}
export const Title: React.FC<TitleProps> = ({
  children,
  size,
  className,
  capitalize,
  uppercase,
  ...props
}) => {
  return (
    <Text
      as="p"
      className={classNames(className, {
        'text-capitalize': capitalize,
        'text-uppercase': uppercase,
      })}
      fontSize={size ? fontSizes[size] : undefined}
      variant="bold"
      {...props}
    >
      {children}
    </Text>
  );
};
