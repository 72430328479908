import { Alert, AlertIcon, Box, Center, Grid, GridItem, VStack, useBoolean } from '@chakra-ui/react';
import { LAYOUT_CONFIG } from 'configs/layout';
import { useDeviceMode, useRouter, useTranslate } from 'hooks/common';
import { DeviceMode } from 'types';
import { FC, useMemo } from 'react';
import useResizeObserver from 'use-resize-observer';
import { Logo, PrimaryButton } from 'components/atoms';
import { ENV, PATH_NAME } from 'configs';
import { BackIcon } from 'assets/icons';
import packageJson from '../../package.json';
import { Typography } from 'components/atoms';
import { useSelector } from 'react-redux';
import { isTwoFaVerifiedSelector } from 'store/modules/auth';
import { Role } from 'models/user';
import { useCurrentUser } from 'hooks/components/auth';

const Error404: FC = (props) => {
  const t = useTranslate();
  const device = useDeviceMode();
  const { height: sidebarHeight = 0 } = useResizeObserver();
  const [visibleMenu] = useBoolean();
  const router = useRouter();
  const isTwoFaVerified = useSelector(isTwoFaVerifiedSelector);
  const currentUser = useCurrentUser();
  const react2fa = process.env.REACT_APP_TFA_AUTHENTICATION;
  const partnerRoles = [
    Role.HqManager,
    Role.AreaManager,
    Role.AreaManagerApproval,
    Role.LocationManager,
    Role.SuperHqInternal,
    Role.SuperHqExternal,
  ];

  const shouldUse2FA = 
    (react2fa === 'ALL') ||
    (react2fa === 'INTERNAL' && currentUser?.user_type && !partnerRoles.includes(currentUser.user_type)) ||
    (react2fa === 'PARTNER' && currentUser?.user_type && partnerRoles.includes(currentUser.user_type));

  const layoutMinHeight = useMemo(() => {
    if (device === DeviceMode.Desktop) return '100vh';
    if (sidebarHeight < window.screen.height) return '100vh';
    return `${sidebarHeight + 80}px`;
  }, [device, sidebarHeight]);

  const handleClickHome = () => {
    if (shouldUse2FA) {
      if (!isTwoFaVerified) {
        if (currentUser?.tfa_method !== null) {
          if (currentUser?.tfa_method === 'SMS' || currentUser?.tfa_method === 'EMAIL') {
            router.push(PATH_NAME.AUTH_OTP);
          } else {
            router.push(PATH_NAME.SELECT_2FA_METHOD);
          }
        } else {
          router.push(PATH_NAME.SELECT_2FA_METHOD);
        }
      } else {
        router.push(PATH_NAME.DASHBOARD);
      }
    } else {
      router.push(PATH_NAME.DASHBOARD);
    }
  };

  const handleClickLogo = () => {
    router.push(PATH_NAME.DASHBOARD);
  };

  // return <Center h="100%">404 Page Not Found</Center>;
  return (
    
    <Grid
    className="Main"
    pl={{ base: 0, lg: LAYOUT_CONFIG.gap }}
    pr={{ base: 0, lg: '1.25rem', '2xl': '1.5rem' }}
    pb={{ base: 0, lg: '22px' }}
    minHeight={layoutMinHeight}
    maxHeight={device === DeviceMode.Mobile && visibleMenu ? `${sidebarHeight}px` : undefined}
    overflow={{ base: 'hidden', lg: 'initial' }}
    templateColumns={{ base: '100%', lg: `repeat(${LAYOUT_CONFIG.columns}, 1fr)` }}
    templateRows={{
      base: 'min-content 0px 1fr',
      md: 'min-content 0px 1fr',
      lg: '75px 1fr',
    }}
    columnGap={{ lg: LAYOUT_CONFIG.gap }}
    position="relative"
  >
    <GridItem colSpan={LAYOUT_CONFIG.sidebar.colSpan}>
            <Center h="100%">
              <Box maxWidth="80%" position="relative">
                <Logo onClick={handleClickLogo} cursor="pointer" />
                <Box
                  position="absolute"
                  top="2.5px"
                  right={ENV.INSTANCE.VIETNAM ? '-70px' : '-40px'}
                  color="#707070b3"
                  fontWeight="500"
                  lineHeight={1.5}
                  fontSize="14px"
                >
                  {ENV.INSTANCE.VIETNAM && 'beta '}
                  v{packageJson.version}
                </Box>
              </Box>
            </Center>
          </GridItem>
    <GridItem
      colSpan={{ base: 1, lg: LAYOUT_CONFIG.columns }}
      py={device !== DeviceMode.Desktop ? '80px' : undefined}
      pb={{ base: '52px', md: '40px', lg: 'initial' }}
    >
      <VStack spacing={4} align="center"> {/* Added VStack */}
        <Box p="20px" w="90%" m="auto">
          <Alert status="error">
            <AlertIcon />
            {t('message.youNoPermissionAccessPage')}
          </Alert>
        </Box>
          <PrimaryButton onClick={handleClickHome} px="0" h={10} minW={10} pl={3}>
            <BackIcon />
            <Typography.Text pl={3} pr={3}>
              {isTwoFaVerified ?  'GO TO HOME' : 'Please Authenticate First' }
            </Typography.Text>
          </PrimaryButton>
      </VStack>
    </GridItem>
  </Grid>
    // <Box p="20px">
    //   <Alert status="error">
    //     <AlertIcon />
    //     {t('message.youNoPermissionAccessPage')}
    //   </Alert>
    // </Box>
  );
};

export default Error404;
