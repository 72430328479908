import { Box, Grid, GridItem, HStack, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';

import { colors } from 'themes/foundations/colors';

import { Typography } from 'components/atoms';

import {
  JodFbAmazingAttitude,
  JodFbFastLearner,
  JodFbGoodWorkEthic,
  JodFbHighlyDependable,
  JodFbProficientSkills,
} from 'assets/icons';

export type EmployeeBadgesProps = {
  badges?: Array<{
    id: number;
    icon: string;
    name: string;
    label: string;
    status: 0 | 1;
    tooltip: string;
  }>;
};

export const EmployeeBadges: React.FC<EmployeeBadgesProps> = ({ badges }) => {
  const [hoveredBadge, setHoveredBadge] = useState<string | null>(null);

  // array badges
  const data_badges = [
    {
      id: 1,
      name: 'amazing_attitude',
      label: 'Amazing Attitude',
      status: 0,
    },
    {
      id: 2,
      name: 'good_work_ethic',
      label: 'Good Work Ethic',
      status: 1,
    },
    {
      id: 3,
      name: 'fast_learner',
      label: 'Fast Learner',
      status: 0,
    },
    {
      id: 4,
      name: 'team_player',
      label: 'Team Player',
      status: 1,
    },
    {
      id: 5,
      name: 'highly_dependable',
      label: 'Highly Dependable',
      status: 0,
    },
  ];

  const badgeIcons: { [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } = {
    amazing_attitude: JodFbAmazingAttitude,
    good_work_ethic: JodFbGoodWorkEthic,
    fast_learner: JodFbFastLearner,
    team_player: JodFbProficientSkills,
    highly_dependable: JodFbHighlyDependable,
  };

  return (
    <>
      <Grid templateColumns="" gap={6}>
        <HStack spacing={3}>
          {data_badges.map((badge) => {
            const BadgeIconComponent = badgeIcons[badge.name];
            const status = badges?.find((b) => b.name === badge.name) ? true : false;
            return (
              <GridItem key={badge.id}>
                <Box
                  w="100px"
                  h="auto"
                  textAlign="center"
                  paddingX="4"
                  sx={{
                    opacity: status ? 1 : 0.5,
                    bg: 'transparent',
                    _active: {
                      bg: 'transparent',
                    },
                    _focus: {
                      boxShadow: 'none',
                    },
                  }}
                >
                  <VStack spacing={2}>
                    <BadgeIconComponent
                      width={30}
                      height={30}
                      colorProfile={status ? colors.graphOpen : colors.vulcan[500]}
                    />
                    <Typography.Text
                      color={status ? colors.graphOpen : colors.vulcan[500]}
                      fontSize="sm"
                    >
                      {badge.label}
                    </Typography.Text>
                  </VStack>
                </Box>
              </GridItem>
            );
          })}
        </HStack>
      </Grid>
    </>
  );
};
