import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteConfig } from 'routes';
import { configActions } from 'store/modules/config';

export type ConfigProps = {
  pageTitle?: string;
  pageBreakLineTitle?: RouteConfig['breakLineTitle'];
};
export const Config: React.FC<ConfigProps> = ({ pageTitle = '', pageBreakLineTitle, ...props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      configActions.setHeaderTitle({
        headerTitleBreakLine: pageBreakLineTitle,
        headerTitle: pageTitle,
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{props.children}</>;
};
