import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { firstLocationSelector, locationActions } from 'store/modules/location';

export const useFirstLocation = () => {
  const dispatch = useDispatch();
  const location = useSelector(firstLocationSelector);

  useEffect(() => {
    dispatch(locationActions.getFirstLocation());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return location;
};
