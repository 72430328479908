import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  assignedLocationOptionsSelector,
  isFetchingAssignedLocationOptionsSelector,
  jobActions,
} from 'store/modules/job';

import { LocationOption } from 'models/location';

export const useAssignedLocationOption = () => {
  const dispatch = useDispatch();
  const options = useSelector(assignedLocationOptionsSelector);
  const isFetching = useSelector(isFetchingAssignedLocationOptionsSelector);

  useEffect(() => {
    dispatch(jobActions.getAssignedLocationOptions());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo<[LocationOption[] | null, boolean]>(
    () => [options, isFetching],
    [isFetching, options],
  );
};
