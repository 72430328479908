import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  isFetchingJobTemplateOptionsSelector,
  jobActions,
  jobTemplateOptionsSelector,
} from 'store/modules/job';

import { JobTemplateOption } from 'models/job';

export const useJobTemplateOptions = () => {
  const dispatch = useDispatch();
  const options = useSelector(jobTemplateOptionsSelector);
  const isFetching = useSelector(isFetchingJobTemplateOptionsSelector);

  useEffect(() => {
    dispatch(jobActions.getAllTemplates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo<[JobTemplateOption[] | null, boolean]>(
    () => [options, isFetching],
    [isFetching, options],
  );
};
