import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import classNames from 'classnames';
import { t } from 'i18next';
import RCPicker from 'rc-picker';
import { GenerateConfig } from 'rc-picker/lib/generate/index';
import { PickerMode } from 'rc-picker/lib/interface';
import * as React from 'react';

import { Components, PickerDateProps, PickerProps, PickerTimeProps, getTimeProps } from '.';
import enUS from '../locale/en_US';
import { getPlaceholder } from '../util';

export default function generatePicker<DateType>(generateConfig: GenerateConfig<DateType>) {
  type DatePickerProps = PickerProps<DateType>;

  function getPicker<InnerPickerProps extends DatePickerProps>(
    picker?: PickerMode,
    displayName?: string,
  ) {
    class Picker extends React.Component<InnerPickerProps> {
      static displayName: string;

      pickerRef = React.createRef<RCPicker<DateType>>();

      focus = () => {
        if (this.pickerRef.current) {
          this.pickerRef.current.focus();
        }
      };

      blur = () => {
        if (this.pickerRef.current) {
          this.pickerRef.current.blur();
        }
      };

      render() {
        const {
          prefixCls: customizePrefixCls,
          getPopupContainer: customizeGetPopupContainer,
          className,
          placeholder,
          size,
          isInvalid,
          ...restProps
        } = this.props;
        const { format, showTime } = this.props as any;

        const additionalProps = {
          showToday: true,
        };

        let additionalOverrideProps: any = {};
        if (picker) {
          additionalOverrideProps.picker = picker;
        }
        const mergedPicker = picker || this.props.picker;

        additionalOverrideProps = {
          ...additionalOverrideProps,
          ...(showTime ? getTimeProps({ format, picker: mergedPicker, ...showTime }) : {}),
          ...(mergedPicker === 'time'
            ? getTimeProps({ format, ...this.props, picker: mergedPicker })
            : {}),
        };

        const prefixCls = 'date-picker';

        return (
          <RCPicker<DateType>
            ref={this.pickerRef}
            placeholder={getPlaceholder(picker, enUS, placeholder)}
            prefixCls={prefixCls}
            suffixIcon={mergedPicker === 'time' ? <ClockCircleOutlined /> : <CalendarOutlined />}
            clearIcon={<CloseCircleFilled />}
            allowClear
            {...additionalProps}
            {...restProps}
            {...additionalOverrideProps}
            locale={{
              ...enUS.lang,
              shortMonths: [
                t('value.jan'),
                t('value.feb'),
                t('value.mar'),
                t('value.apr'),
                t('value.may'),
                t('value.jun'),
                t('value.jul'),
                t('value.aug'),
                t('value.sep'),
                t('value.oct'),
                t('value.nov'),
                t('value.dec'),
              ],
              shortWeekDays: [
                t('value.su'),
                t('value.mo'),
                t('value.tu'),
                t('value.we'),
                t('value.th'),
                t('value.fr'),
                t('value.sa'),
              ],
              ok: t('button.ok'),
            }}
            generateConfig={generateConfig}
            components={Components}
            prevIcon={<span className={`${prefixCls}-prev-icon`} />}
            nextIcon={<span className={`${prefixCls}-next-icon`} />}
            superPrevIcon={<span className={`${prefixCls}-super-prev-icon`} />}
            superNextIcon={<span className={`${prefixCls}-super-next-icon`} />}
            className={classNames(
              { 'size-sm': size === 'sm', 'size-md': size === 'md' },
              { invalid: isInvalid },
              className,
            )}
          />
        );
      }
    }

    if (displayName) {
      Picker.displayName = displayName;
    }

    return Picker as React.ComponentClass<InnerPickerProps>;
  }

  const DatePicker = getPicker<DatePickerProps>();
  const WeekPicker = getPicker<Omit<PickerDateProps<DateType>, 'picker'>>('week', 'WeekPicker');
  const MonthPicker = getPicker<Omit<PickerDateProps<DateType>, 'picker'>>('month', 'MonthPicker');
  const YearPicker = getPicker<Omit<PickerDateProps<DateType>, 'picker'>>('year', 'YearPicker');
  const TimePicker = getPicker<Omit<PickerTimeProps<DateType>, 'picker'>>('time', 'TimePicker');
  const QuarterPicker = getPicker<Omit<PickerTimeProps<DateType>, 'picker'>>(
    'quarter',
    'QuarterPicker',
  );

  return {
    DatePicker,
    WeekPicker,
    MonthPicker,
    YearPicker,
    TimePicker,
    QuarterPicker,
  };
}
