import { PaymentApprovalStatus } from './payment-approval';
import { IJobSlotUserTransactionLogs, WorkerStatus } from './job';

export enum PaymentStatus {
  Unprocessed = 1,
  Processed = 2,
  InProgress = 3,
}

export type PaymentStatusLabel = 'Unprocessed' | 'Processed' | 'In Progress';

// export const PAYMENT_STATUS_LABEL: Record<PaymentStatus, PaymentStatusLabel> = {
//   [PaymentStatus.Unprocessed]: 'Unprocessed',
//   [PaymentStatus.Processed]: 'Processed',
//   [PaymentStatus.InProgress]: 'In Progress',
// };

// export const PAYMENT_STATUS_COLOR: Record<PaymentStatus, PaymentStatusLabel> = {
//   [PaymentStatus.Unprocessed]: 'Unprocessed',
//   [PaymentStatus.Processed]: 'Processed',
//   [PaymentStatus.InProgress]: 'In Progress',
// };

// export const PAYMENT_STATUS_LABEL_LIST: Option<number>[] = Object.entries(PAYMENT_STATUS_LABEL).map(
//   ([value, label]) => ({
//     value: +value,
//     label,
//   }),
// );

export enum PaymentClockStatus {
  ClockedOut = 0,
  ClockedIn = 1,
}

export interface IPayment {
  payment_id: number;
  job_id: number;
  slot_id: number;
  slot_user_status: WorkerStatus;
  payment_status: PaymentStatus;
  processed_date: string | null;
  bank_paid: 0 | 1;

  location_id: number;
  location_name: string;

  user_id: string;
  full_name: string;
  avatar_url: string;
  unique_id: string;
  is_deleted?: 0 | 1;

  job_title: string;
  clock_in: string;
  clock_out: string;
  hourly_rate: number;
  break_time: number;
  total_hours: number;
  total_credits: number;
  net_pay: number;
  bank_code: string;
  v1_bank_name?: string;
  account_holder_name: string;
  account_number: string;
  insurance_amount: number;

  has_adjustment: 0 | 1;
  admin_clock_in: string;
  admin_clock_out: string;
  admin_hourly_rate: number;
  admin_break_time: number;
  admin_jod_allowance: number;
  admin_jod_allowance_deduction: 0 | 1,
  admin_comment: string;
  admin_total_hours: number;
  admin_total_credits: number;
  admin_net_pay: number;
  admin_insurance_amount: number;
  adjusted_jod_credit: number;
  adjustment_id: number;
  adjusted_clock_in: string;
  adjusted_clock_out: string;
  adjusted_total_hour: number;
  adjusted_break_time: number;
  adjusted_sponsored_break_time: number | null;
  adjusted_hourly_rate: number,
  adjusted_net_pay: number,
  total_adjusted_credit: number,
  readjusted_jod_credit: number,
  adjusted_insurance_amount: number,
  adjusted_sponsored_insurance_amount: number | null,
  adjusted_comment: string,
  adjusted_jod_allowance: number,
  adjusted_jod_allowance_deduction: 0 | 1,
  adjusted_date: string,
  adjusted_bank_code: string,
  adjusted_account_number: string,
  adjusted_account_holder_name: string,
  adjusted_status: PaymentApprovalStatus,
  slot_user_transaction_logs: Array<IJobSlotUserTransactionLogs>;
}

export type IConsumedCreditsMonthly = {
  consumed_credits_previous_month: number;
  consumed_credits_current_month: number;
};
