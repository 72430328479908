import { Alert, AlertIcon, Box } from '@chakra-ui/react';
import { t } from 'i18next';
import React from 'react';

export type ErrorBoundaryProps = {
  fallback?: ((error: any) => React.ReactNode) | React.ReactNode;
};
export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { hasError: boolean; error: any }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.error('componentDidCatch', error, errorInfo);
    this.setState({ error });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (this.props.fallback)
        return typeof this.props.fallback === 'function'
          ? this.props.fallback(this.state.error)
          : this.props.fallback;
      return (
        <Box p="20px">
          <Alert status="error">
            <AlertIcon />
            {/* Something went wrong! */}
            {t('message.somethingWentWrong1')}
          </Alert>
        </Box>
      );
    }

    return this.props.children;
  }
}
