import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  ModalBodyProps,
  ModalContentProps,
  ModalFooterProps,
  ModalHeaderProps,
  ModalOverlayProps,
  useBoolean,
} from '@chakra-ui/react';
import { FocusableElement } from '@chakra-ui/utils';
import noop from 'lodash/noop';
import React, { useLayoutEffect, useRef } from 'react';
import { isFirefox } from 'react-device-detect';

import { colors } from 'themes/foundations/colors';

import { useTranslate } from 'hooks/common';

import { addHexOpacity } from 'utils/opacity';

import { Loading } from './Loading';

export type OkFunction = () => Promise<{ status: boolean; error?: any }>;
type Content =
  | ((
      close: VoidFunction,
      ok: OkFunction,
      loading: boolean,
      initFocusRef: React.RefObject<FocusableElement>,
    ) => React.ReactNode)
  | React.ReactNode
  | false;

export type ConfirmModalProps = {
  controller: ReturnType<typeof useBoolean>;
  header?: Content;
  children?: Content;
  footer?: Content;
  closeButtonProps?: ButtonProps;
  closeText?: string;
  okButtonProps?: ButtonProps;
  okText?: string;
  closeOnReceiveError?: boolean;
  closeOnLoading?: boolean;
  type?: 'full' | 'card';
  containerRef?: React.RefObject<HTMLElement | null>;
  overlayProps?: ModalOverlayProps;
  typeOverlay?: 'blurWhite' | 'blurTango';
  contentProps?: ModalContentProps;
  contentContainerProps?: ModalContentProps['containerProps'];
  headerProps?: ModalHeaderProps;
  footerProps?: ModalFooterProps;
  bodyProps?: ModalBodyProps;
  showBehindBlur?: boolean;
  behindBlurStyleProps?: BoxProps;
  autoFocusCenter?: boolean;
  onOk?(...arg: any[]): void | Promise<void>;
  onOkFailed?(error: any): void;
} & Partial<AlertDialogProps>;

export const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
  const {
    controller,
    header,
    children,
    footer,
    closeButtonProps,
    closeText,
    okButtonProps,
    okText,
    closeOnReceiveError = true,
    closeOnLoading,
    type = 'full',
    typeOverlay = 'blurTango',
    containerRef,
    overlayProps,
    contentProps,
    footerProps,
    headerProps,
    bodyProps,
    contentContainerProps,
    showBehindBlur,
    behindBlurStyleProps,
    autoFocusCenter,
    onOk,
    onOkFailed = noop,
    ...modalProps
  } = props;

  const t = useTranslate();

  const [isOpen, { off: close }] = controller;
  const [loading, { on, off }] = useBoolean();

  const leaveFocusRef = useRef<HTMLButtonElement>(null);
  const contentRef = useRef<HTMLElement>(null);

  const triggerClose = () => {
    if (loading && !closeOnLoading) return;
    close();
  };

  const handleOk = async (...arg: any[]) => {
    if (!onOk) {
      triggerClose();
      return { status: true };
    }
    const res = onOk(...arg);
    if (res instanceof Promise) {
      on();
      try {
        await res;
        off();
        triggerClose();
        return { status: true };
      } catch (error: any) {
        off();
        onOkFailed(error);
        closeOnReceiveError && triggerClose();
        return { status: false, error };
      }
    } else {
      triggerClose();
      return { status: true };
    }
  };

  const renderContent = (content: Content) => {
    if (content === false) return;
    if (typeof content === 'function')
      return content(triggerClose, handleOk, loading, leaveFocusRef);
    return content;
  };

  const getOverlayStyles = (): ModalOverlayProps => {
    let defaultStyles: ModalOverlayProps = {
      backdropFilter: 'blur(30px) brightness(115%)',
      zIndex: 'overlay',
      bg: addHexOpacity(colors.tango[500], 30),
      opacity: '0.6 !important',
    };

    if (type === 'card' && typeOverlay === 'blurTango') {
      defaultStyles = {
        background: !isFirefox ? addHexOpacity(colors.tango[300], 15) : '#FD954326',
        backdropFilter: !isFirefox ? 'blur(9px) brightness(100%)' : undefined,
        borderRadius: '20px',
      };
    } else if (type === 'card' && typeOverlay === 'blurWhite') {
      defaultStyles = {
        background: !isFirefox ? addHexOpacity(colors.tango[100], 10) : '#FD954326',
        backdropFilter: !isFirefox ? 'blur(20px) brightness(110%)' : undefined,
        opacity: '.985 !important',
        borderRadius: '5px',
      };
    }
    if (containerRef) {
      defaultStyles = { ...defaultStyles, position: 'absolute', w: '100%', h: '100%' };
    }
    return defaultStyles;
  };

  const getContentContainerStyles = (): ModalContentProps['containerProps'] | undefined => {
    if (containerRef) return { position: 'absolute', w: '100%' };
  };

  const getContentStyles = (): ModalContentProps => {
    let defaultStyles: ModalContentProps = {
      boxShadow: `0 2px 3px ${colors.tangoOpacity[40]}`,
      bg: 'white',
      w: { base: '100%', lg: 'fit-content' },
    };

    if (type === 'card' && typeOverlay === 'blurTango') {
      defaultStyles.boxShadow = 'unset';
      defaultStyles.bg = 'white';
    } else if (type === 'card' && typeOverlay === 'blurWhite') {
      defaultStyles.boxShadow = `0 1px 3px ${colors.tangoOpacity[50]}`;
      defaultStyles.paddingBottom = '10px';
    }
    return defaultStyles;
  };

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      if (autoFocusCenter && isOpen && contentRef.current) {
        contentRef.current.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        });
      }
    }, 100);
    return () => clearTimeout(timeout);
  }, [isOpen, autoFocusCenter]);

  return (
    <AlertDialog
      isCentered={true}
      {...modalProps}
      leastDestructiveRef={leaveFocusRef}
      isOpen={isOpen}
      onClose={triggerClose}
      portalProps={containerRef ? { containerRef } : undefined}
      blockScrollOnMount={containerRef !== undefined ? false : true}
    >
      <AlertDialogOverlay className="confirm-overlay" {...getOverlayStyles()} {...overlayProps} />
      <AlertDialogContent
        ref={contentRef}
        className="confirm-content"
        borderRadius="15px"
        px="20px"
        maxWidth={{ base: '85vw', md: '50vw', lg: '80vw' }}
        containerProps={{
          height: '100%',
          ...getContentContainerStyles(),
          ...contentContainerProps,
        }}
        {...getContentStyles()}
        {...contentProps}
      >
        {showBehindBlur && (
          <Box position="absolute" inset="0" filter="blur(1px)" {...behindBlurStyleProps} />
        )}
        <Loading loading={loading}>
          <AlertDialogHeader fontSize="lg" fontFamily="heading" {...headerProps}>
            {renderContent(header)}
          </AlertDialogHeader>

          <AlertDialogBody px={{ base: '0', md: '20px' }} {...bodyProps}>
            {renderContent(children)}
          </AlertDialogBody>

          <AlertDialogFooter
            pt={{ base: '0', md: 'initial' }}
            justifyContent="center"
            {...footerProps}
          >
            {footer && renderContent(footer)}
            {footer === undefined && (
              <>
                <Button variant="secondaryOutline" {...closeButtonProps} onClick={triggerClose}>
                  {closeText ?? t('button.cancel')}
                </Button>
                <Button ref={leaveFocusRef} ml={3} {...okButtonProps} onClick={handleOk}>
                  {okText ?? t('button.ok')}
                </Button>
              </>
            )}
          </AlertDialogFooter>
        </Loading>
      </AlertDialogContent>
    </AlertDialog>
  );
};
