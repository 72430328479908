import { TextProps as ChakraTestProps, Text as ChakraText, Tooltip } from '@chakra-ui/react';
import React from 'react';

import { createResponsiveStyle } from 'themes/foundations/breakpoints';

export const fontSizes = {
  sm: createResponsiveStyle({ base: 'sm' }),
  md: createResponsiveStyle({ base: 'md', lg: 'sm-md', '2xl': 'md' }),
  lg: createResponsiveStyle({ base: 'lg', lg: 'sm-lg', '2xl': 'lg' }),
  xl: createResponsiveStyle({ md: 'lg', lg: 'sm-xl', '2xl': 'xl' }),
  '2xl': createResponsiveStyle({ base: 'lg', lg: 'sm-2xl', '2xl': '2xl' }),
  '3xl': createResponsiveStyle({ lg: 'sm-3xl', '2xl': '3xl' }),
};

export interface TextProps extends ChakraTestProps {
  size?: keyof typeof fontSizes;
  showTooltip?: boolean;
  breakWord?: boolean;
}
export const Text: React.FC<TextProps> = ({ children, size, showTooltip, breakWord, ...props }) => {
  const tooltip =
    (props.isTruncated || props.noOfLines) && showTooltip ? (children as string) : undefined;
  return tooltip ? (
    <Tooltip label={tooltip}>
      <ChakraText
        as="span"
        display="inline-block"
        fontSize={size ? fontSizes[size] : undefined}
        wordBreak={breakWord ? 'break-word' : undefined}
        {...props}
      >
        {children}
      </ChakraText>
    </Tooltip>
  ) : (
    <ChakraText
      as="span"
      display="inline-block"
      wordBreak={breakWord ? 'break-word' : undefined}
      fontSize={size ? fontSizes[size] : undefined}
      {...props}
    >
      {children}
    </ChakraText>
  );
};
