import { useMemo } from 'react';

import { SystemPermission } from 'types/permission';

import { useCurrentPermission } from './useCurrentPermission';

export const useHasPermissions = (
  permissions?: SystemPermission | SystemPermission[],
  strategy?: 'some' | 'every',
): boolean => {
  const [, checKHasPermission] = useCurrentPermission();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => checKHasPermission(permissions, strategy), [checKHasPermission]);
};
