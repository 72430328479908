import { httpClient } from 'apis';
import { AxiosResponse } from 'axios';
import i18next from 'i18next';
import { DataResponse } from 'types';
import { parseToFormData } from 'utils';

import {
  ICreditOverview,
  IHiringOverview,
  IJobOverview,
  IPaymentSummaryChart,
  IRatingOverview,
} from 'models/dashboard';

import { mapErrorDescriptions } from './utils';

const entity = 'dashboard';
const basePath = `portal/${entity}` as const;

export const dashboardApi = {
  async uploadAdminLogo(file: FileList): Promise<DataResponse<{ admin_logo_url: string }>> {
    try {
      if (!file) {
        throw new Error(i18next.t<string>('message.userAdminLogoFileHasNotBeenUploadYet'));
      }
      const formData = parseToFormData({
        admin_logo: file,
      });
      const res: AxiosResponse<{ message: string; data: string }> = await httpClient.post(
        `${basePath}/up-admin-logo`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      const { data } = res;
      return {
        status: true,
        data: { admin_logo_url: data.data },
        message: data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.updateFailed'), description } = error;
      return Promise.reject({
        status: false,
        description: mapErrorDescriptions({ admin_logo: 'adminLogo' }, description),
        message: message,
      });
    }
  },
  async getAdminLogo(): Promise<DataResponse<{ admin_logo_url: string }>> {
    try {
      const res: AxiosResponse<{ message: string; data: { admin_logo_url: string } }> =
        await httpClient.get(`${basePath}/admin-logo`);
      const { data } = res;

      return {
        status: true,
        data: { admin_logo_url: data.data.admin_logo_url },
        message: data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.getAdminLogoFailed') } = error;
      return Promise.reject({
        status: false,
        message: message,
      });
    }
  },
  async getPaymentsStatistics(): Promise<DataResponse<IPaymentSummaryChart[]>> {
    try {
      const res: AxiosResponse<{ message: string; data: IPaymentSummaryChart[] }> =
        await httpClient.get(`${basePath}/payment-statistics`);
      const { data } = res;

      return {
        status: true,
        data: data.data,
        message: data.message,
      };
    } catch (error: any) {
      const { message } = error;
      return Promise.reject({
        status: false,
        message: message,
      });
    }
  },
  async getJobOverview(params: { locationId?: number; startDate?: string; endDate?: string }): Promise<DataResponse<IJobOverview>> {
    const { locationId, startDate, endDate } = params;
    try {
      const res: AxiosResponse<{ message: string; data: IJobOverview }> = await httpClient.get(
        `${basePath}/jobs-overview`,
        {
          params: {
            location_id: locationId,
            start_date: startDate,
            end_date: endDate,
          },
        },
      );
      const { data } = res;

      return {
        status: true,
        data: data.data,
        message: data.message,
      };
    } catch (error: any) {
      const { message } = error;
      return Promise.reject({
        status: false,
        message: message,
      });
    }
  },
  async getHiringOverview(locationId?: number): Promise<DataResponse<IHiringOverview>> {
    try {
      const res: AxiosResponse<{ message: string; data: IHiringOverview }> = await httpClient.get(
        `${basePath}/hiring-overview`,
        {
          params: {
            location_id: locationId,
          },
        },
      );
      const { data } = res;

      return {
        status: true,
        data: data.data,
        message: data.message,
      };
    } catch (error: any) {
      const { message } = error;
      return Promise.reject({
        status: false,
        message: message,
      });
    }
  },
  async getCreditOverview(): Promise<DataResponse<ICreditOverview[]>> {
    try {
      const res: AxiosResponse<{ message: string; data: ICreditOverview[] }> = await httpClient.get(
        `${basePath}/credit-overview`,
      );
      const { data } = res;

      return {
        status: true,
        data: data.data,
        message: data.message,
      };
    } catch (error: any) {
      const { message } = error;
      return Promise.reject({
        status: false,
        message: message,
      });
    }
  },
  async getRatingOverview(): Promise<DataResponse<IRatingOverview>> {
    try {
      const res: AxiosResponse<{ message: string; data: IRatingOverview }> = await httpClient.get(
        `${basePath}/rating-overview`,
      );
      const { data } = res;

      return {
        status: true,
        data: data.data,
        message: data.message,
      };
    } catch (error: any) {
      const { message } = error;
      return Promise.reject({
        status: false,
        message: message,
      });
    }
  },
  async updateTermsAndConditions(file: FileList) {
    try {
      if (!file) {
        throw new Error(i18next.t<string>('message.userAdminLogoFileHasNotBeenUploadYet'));
      }
      const formData = parseToFormData({
        file: file,
      });
      const res: AxiosResponse<{ message: string; data: string }> = await httpClient.post(
        `${basePath}/upload-aptus-technologies-terms`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      const { data } = res;
      return {
        status: true,
        // data: { admin_logo_url: data.data },
        message: data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.updateFailed') } = error;
      return Promise.reject({
        status: false,
        message: message,
      });
    }
  },
};

export type DashboardApi = typeof dashboardApi;
