import { IJob, JobStatus } from 'models/job';
import { Role } from 'models/user';

import { PartnerPermission } from 'types/permission';

import { useCurrentPermission, useCurrentRole } from '../auth';
import { useCanCreateJob } from './useCanCreateJob';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'store/modules/auth';

type FunctionReturn = {
  canCreate: boolean;
  canCopy: (job: IJob) => boolean;
  canEdit: (job: IJob) => boolean;
  canDelete: (job: IJob) => boolean;
  canApprove: (job: IJob) => boolean;
  canReject: (job: IJob) => boolean;
};

type PrimitiveReturn = Record<keyof FunctionReturn, boolean>;

export const useJobPermission = <T extends (IJob | null) | undefined>(
  job?: T,
): T extends undefined ? FunctionReturn : PrimitiveReturn => {
  const currentRole = useCurrentRole();
  const [, hasPermission] = useCurrentPermission();
  const canCreate = useCanCreateJob();

  const isLocationManager = currentRole === Role.LocationManager;
  const isHqManager = currentRole === Role.HqManager;

  const user = useSelector(currentUserSelector);

  const canCopy = (job: IJob) => {
    const isCancel = [JobStatus.Cancel].includes(job.status);
    if (isCancel) return false;
    return canCreate;
  };

  const canEdit = (job: IJob) => {
    let editable =
      [JobStatus.Open, JobStatus.Pending].includes(job.status) && job.no_users_apply_job === 0;
    if (user?.user_type === 'SUPER_HQ_INTERNAL') {
      editable = [JobStatus.Open, JobStatus.Pending, JobStatus.Active, JobStatus.Completed].includes(job.status);
    }
    if (isLocationManager) return canCreate && editable;
    return editable && hasPermission(PartnerPermission.UPDATE_JOB);
  };

  const canDelete = (job: IJob) => {
    return (
      [JobStatus.Open, JobStatus.Pending].includes(job.status) &&
      hasPermission(PartnerPermission.DELETE_JOB)
    );
  };

  const canApprove = (job: IJob) => {
    return (
      job.status === JobStatus.Pending &&
      hasPermission(PartnerPermission.APPROVE_REJECT_PENDING_JOB)
    );
  };

  const canReject = (job: IJob) => {
    return (

      job.status === JobStatus.Pending &&
      hasPermission(PartnerPermission.APPROVE_REJECT_PENDING_JOB)
    );
  };

  if (job !== undefined) {
    return {
      canCreate,
      canCopy: !!job && canCopy(job),
      canEdit: !!job && canEdit(job),
      canDelete: !!job && canDelete(job),
      canApprove: !!job && canApprove(job),
      canReject: !!job && canReject(job),
    } as T extends undefined ? FunctionReturn : PrimitiveReturn;
  }
  return {
    canCreate,
    canCopy,
    canEdit,
    canDelete,
    canApprove,
    canReject,
  } as T extends undefined ? FunctionReturn : PrimitiveReturn;
};
