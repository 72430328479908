import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { creditSelector } from 'store/modules/credit/selector';
import { creditActions } from 'store/modules/credit';

export const useTotalLocationAvailableCredit = () => {
  const dispatch = useDispatch();
  const credit = useSelector(creditSelector);

  useEffect(() => {
    dispatch(creditActions.getTotalLocationAvailableCredit());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return credit.totalLocationAvailableCredit;
};
