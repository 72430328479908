import {
  Box,
  BoxProps,
  Circle,
  Collapse,
  Grid,
  GridItem,
  Icon,
  useBoolean,
} from '@chakra-ui/react';
import classNames from 'classnames';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import React, { useEffect, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

import { colors } from 'themes/foundations/colors';

import { Card, CardProps, Typography } from 'components/atoms';

import { DownOutlinedIcon } from 'assets/icons';

import { addHexOpacity } from 'utils/opacity';

import { ColumnsType, TableProps } from '.';

interface CardItemProps<RecordType extends object = any>
  extends Pick<
      TableProps<RecordType>,
      'columns' | 'expandable' | 'rowStyleProps' | 'gridMobileProps'
    >,
    CardProps {
  activeCellStyles?: BoxProps;
  isActive: boolean;
  record: RecordType;
  recordIndex: number;
  collapsible?: boolean;
  cardStyles?: any;
  defaultExpanded?: boolean;
  allExpandable?: boolean;
}

export const CardItem = <RecordType extends object = any>({
  columns,
  activeCellStyles,
  cardStyles,
  isActive = false,
  record,
  recordIndex,
  collapsible = true,
  expandable,
  allExpandable,
  gridMobileProps,
  defaultExpanded,
  ...props
}: CardItemProps<RecordType>) => {
  const visibleColumns = columns.filter((item) => {
    if (typeof item.hideColumnInMobile === 'function') return !item.hideColumnInMobile(record);
    return !item.hideColumnInMobile;
  });
  const orderedColumns = sortBy(visibleColumns, ['orderInMobile', 'pined']);
  const pinedColumns = orderedColumns.filter((item, index) => item.pined === true || index === 0);

  const { rowExpandable, expandedRowRender, showExpandedRender = false } = expandable || {};

  const canExpand =
    typeof rowExpandable === 'function' ? rowExpandable(record, recordIndex) : rowExpandable;

  const { ref: pinedRef, height: pinedHeight = (18 + 20) * pinedColumns.length - 20 } =
    useResizeObserver();

  const [expanded, { on, off, toggle }] = useBoolean(defaultExpanded);
  const cardRef = useRef<HTMLDivElement>(null);
  const [isChildrenExpand, setIsChildrenExpand] = useState<boolean>();

  const renderContent = (cols: ColumnsType<RecordType>[]) => {
    return (
      <Grid
        templateColumns="75px 1fr"
        rowGap="20px"
        columnGap="10px"
        {...gridMobileProps}
      >
        {cols.map((column, index) => {
          const { title, shouldShowTitleInMobile = true, mobileTitleProps, titleInMobile } = column;
          const render = column.renderMobile ?? column.render;
          return (
            <React.Fragment key={index}>
              {title && shouldShowTitleInMobile && (
                <GridItem {...column.gridItemProps}>
                  <Typography.Title
                    maxW="100px"
                    {...mobileTitleProps}
                    {...column.titleColStyleMobileProps}
                  >
                    {titleInMobile ?? title}
                  </Typography.Title>
                </GridItem>
              )}
              <GridItem
                colSpan={title && shouldShowTitleInMobile ? 1 : 2}
                pr={collapsible && index === 0 ? '25px' : 0}
              >
                <Box
                  key={column.key}
                  {...column.cellStyleProps}
                  className={classNames({ 'row-active': isActive })}
                  fontSize={{ base: 'md', md: 'lg', lg: 'sm-lg', '2xl': 'lg' }}
                >
                  {render ? (
                    render(get(record, column.dataIndex || ''), record, recordIndex, {
                      isActive,
                      isExpanded: isChildrenExpand,
                      toggleExpand: () => setIsChildrenExpand(!isChildrenExpand),
                    })
                  ) : (
                    <Typography.Text
                      fontSize={{ base: 'md', md: 'lg', lg: 'sm-lg', '2xl': 'lg' }}
                      {...column.innerCellStyleProps}
                    >
                      {get(record, column.dataIndex || '') ?? column.defaultValue}
                    </Typography.Text>
                  )}
                </Box>
              </GridItem>
            </React.Fragment>
          );
        })}
      </Grid>
    );
  };

  useEffect(() => {
    allExpandable ? on() : off();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allExpandable]);

  useEffect(() => {
    if (!expanded) {
      setIsChildrenExpand(false);
    }
    if (expanded && showExpandedRender) {
      setIsChildrenExpand(true);
    }
  }, [expanded, showExpandedRender]);

  useEffect(() => {
    if (defaultExpanded) {
      cardRef.current?.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Card
        ref={cardRef}
        p="15px 23px"
        transition="box-shadow 0.2s ease-in"
        boxShadow={
          expanded
            ? `0 3px 6px ${addHexOpacity(colors.primary, 20)}`
            : `0 0px 0 ${addHexOpacity(colors.primary, 0)}`
        }
        position="relative"
        {...props}
        {...cardStyles}
      >
        <Box position="relative">
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            visibility="hidden"
            opacity="0"
            ref={pinedRef}
          >
            {renderContent(pinedColumns)}
          </Box>
        </Box>

        {collapsible && (
          <Circle
            size="25px"
            bg="secondary"
            position="absolute"
            right="23px"
            top="11.5px"
            onClick={toggle}
          >
            <Icon
              as={DownOutlinedIcon}
              w="13.39px"
              h="8.93"
              transition="transform 0.2s ease-in"
              transform={
                expanded ? 'translateY(0px) rotate(180deg)' : 'translateY(1px) rotate(0deg)'
              }
            />
          </Circle>
        )}
        <Collapse startingHeight={`${pinedHeight}px`} in={expanded || !collapsible}>
          {renderContent(orderedColumns)}
        </Collapse>
      </Card>
      {canExpand && expandedRowRender && (
        <Collapse
          unmountOnExit={true}
          in={expanded ? (isChildrenExpand ? isChildrenExpand : false) : false}
        >
          {expandedRowRender(record, recordIndex)}
        </Collapse>
      )}
    </>
  );
};
