import { useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { hqUserActions, hqUserSummarySelector } from 'store/modules/hq-user';

import { HqUserApi, hqUserApi } from 'apis/hq-user';

import { Typography } from 'components/atoms';

import { useQuery, useTranslate } from 'hooks/common';

export const useHqUserSummary = (refreshOnUnmounted: boolean = false) => {
  const dispatch = useDispatch();
  const storedSummary = useSelector(hqUserSummarySelector);
  const t = useTranslate();

  const [summary, loading, , tool] = useQuery<
    { totalActive: number; totalDisable: number; total: number },
    any,
    HqUserApi['summary']
  >(null, hqUserApi.summary, {
    preventInitCall: storedSummary.init,
  });

  const summarySections = useMemo(() => {
    const { totalActive, totalDisable, total } = storedSummary;
    return [
      {
        label: (
          <Typography.Text>
            <Trans t={t} i18nKey={'label.totalNumberOfHQUsers'} components={[<br key={0} />]} />
          </Typography.Text>
        ),
        count: total,
      },
      {
        label: (
          <Typography.Text>
            <Trans t={t} i18nKey={'label.numberOfActiveHQUsers'} components={[<br key={0} />]} />
          </Typography.Text>
        ),
        count: totalActive,
      },
      {
        label: (
          <Typography.Text>
            <Trans t={t} i18nKey={'label.numberOfDisableHQUsers'} components={[<br key={0} />]} />
          </Typography.Text>
        ),
        count: totalDisable,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedSummary]);
  useEffect(() => {
    if (summary) {
      dispatch(hqUserActions.updateSummary(summary));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary]);

  useEffect(() => {
    return () => {
      refreshOnUnmounted && tool.refetch();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo<[typeof summarySections, boolean, typeof tool.refetch, typeof summary]>(
    () => [summarySections, loading, tool.refetch, summary],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [summarySections, loading],
  );
};
