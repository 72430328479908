import { BooleanStatus } from 'types';

import { ICompany } from 'models/company';

export enum PermissionType {
  Internal = 'internal',
  Partner = 'partner',
  SuperHq = 'superHq',
}

export enum Role {
  SuperAdmin = 'SUPER_ADMIN',
  UserAdmin = 'USER_ADMIN',
  SuperHqExternal = 'SUPER_HQ_EXTERNAL',
  SuperHqInternal = 'SUPER_HQ_INTERNAL',
  HqManager = 'HQ',
  AreaManager = 'AREA',
  AreaManagerApproval = 'AREA',
  LocationManager = 'LOCATION',
  Internal = 'INTERNAL',
  Partner = 'PARTNER',
}

export enum UserType {
  SuperAdmin = 'SUPER_ADMIN',
  UserAdmin = 'USER_ADMIN',
  SuperHqExternal = 'SUPER_HQ_EXTERNAL',
  SuperHqInternal = 'SUPER_HQ_INTERNAL',
  HqManager = 'HQ',
  AreaManager = 'AREA',
  AreaManagerApproval = 'AREA',
  LocationManager = 'LOCATION',
}

export const PartnerRoleType: string[] = [
  Role.Partner,
  Role.HqManager,
  Role.AreaManager,
  Role.AreaManagerApproval,
  Role.LocationManager,
];

export interface IUser {
  id: number;
  first_name: string;
  last_name: string;
  name: string;
  full_name?: string | null;
  email: string;
  role: IRole;
  role_name: string;
  role_id: number;
  user_type: Role;
  email_verified_at: string | null;
  created_at: string;
  updated_at: string;
  status: BooleanStatus;
  job_title: string | null;
  contact_number: string;
  company_id: number | null;
  language_code: string | null;
  display_name: string | null;
  date_of_birth: string | null;
  country_code?: string;
  unique_id: string | null;
  school: string | null;
  is_food_hygiene_certified: boolean | null;
  referral_code: string | null;
  avatar_url?: string;
  company?: ICompany;
  logo?: string;
  tfa_method?: string;
  tfa_verified:boolean;
  otp_created_at?: string;
  permissions: Array<number>;
  user_setting_sms_notification_status: boolean;
  user_setting_email_notification_status: boolean;
  user_setting_whatsapp_notification_status: boolean;
  super_hq_companies?: Array<ICompany>;
  super_hq_role?: IRole;
}

export interface IHqUser {
  id: number;
  name?: string;
  email: string;
  role_id: number;
  avatar_url: string;
  email_verified_at?: string | null | boolean;
  first_name: string;
  last_name: string;
  full_name?: string;
  job_title: string;
  company_name: string;
  company_id?: number;
  contact_number: string;
  logo?: string;
  status: BooleanStatus;
  created_at?: string;
  updated_at?: string;
  company?: ICompany;
  user_setting_sms_notification_status: boolean;
  user_setting_email_notification_status: boolean;
  user_setting_whatsapp_notification_status: boolean;
}

export interface IRole {
  role_name: string;
  name?: string;
  id: number;
  description: string;
  type: Role;
  status: BooleanStatus;
}

export interface IRolePermission {
  id: number;
  role_name: string;
  role_id: number;
  role_type: string;
  permissions: number[];
}

export interface IChangePassword {
  password: string;
  password_confirm: string;
}
