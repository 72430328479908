import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions, dashboardSelector } from 'store/modules/dashboard';

export const useAdminLogo = () => {
  const dispatch = useDispatch();
  const adminLogo = useSelector(dashboardSelector);

  useEffect(() => {
    dispatch(dashboardActions.getAdminLogo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return adminLogo;
};
