import { createSlice } from '@reduxjs/toolkit';

import {
  getReasonTemplateOptions,
} from './action';
import { ReasonTemplateState } from './type';

const initialState: ReasonTemplateState = {
  templateOptions: null,
  fetchTemplateStatus: null
};

const { actions, reducer: reasonTemplateReducer } = createSlice({
  name: 'reasonTemplate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReasonTemplateOptions.fulfilled, (state, action) => {
      state.templateOptions = action.payload;
      state.fetchTemplateStatus = action.meta.requestStatus;
    });
    builder.addCase(getReasonTemplateOptions.pending, (state, action) => {
      state.fetchTemplateStatus = action.meta.requestStatus;
    });
    builder.addCase(getReasonTemplateOptions.rejected, (state, action) => {
      state.fetchTemplateStatus = action.meta.requestStatus;
      if (!state.templateOptions) {
        state.templateOptions = [];
      }
    });
  },
});

const reasonTemplateActions = {
  ...actions,
  getReasonTemplateOptions,
};
export { reasonTemplateReducer, reasonTemplateActions };
export * from './type';
export * from './selector';
export * from './action';
