import { Box, BoxProps } from '@chakra-ui/react';
import { ApexOptions } from 'apexcharts';
import React, { useLayoutEffect } from 'react';
import ApexChart from 'react-apexcharts';
import ScrollContainer from 'react-indiana-drag-scroll';
import { DeviceMode } from 'types';

import { colors } from 'themes/foundations/colors';

import { useDeviceMode } from 'hooks/common';

import { LAYOUT_CONFIG } from 'configs/layout';

import { createArray } from 'utils/array';

export type BarCharProps = {
  width?: number;
  height?: number;
  styleProps?: BoxProps;
  datasets: Array<{ name: string; data: number[] }>;
  labels: Array<string | string[]>;
  stacked?: boolean;
  legend?: boolean;
  chart?: Omit<ApexOptions['chart'], 'type'>;
  labelColor?: string;
  numOfDataset?: number;
  numOfColumns?: number;
  hightLightEndLabel?: boolean;
} & Omit<ApexOptions, 'chart' | 'labels'>;

export const BarChar: React.FC<BarCharProps> = ({
  styleProps,
  datasets,
  labels,
  stacked,
  legend,
  chart,
  labelColor,
  width,
  numOfDataset = 1,
  numOfColumns = 1,
  height,
  hightLightEndLabel,
  ...props
}) => {
  const transparentColors = '#ffffff00';
  const px = 20;
  const columnWidth = 32;
  const leftOffset = 45;

  let paddingWrap = () => {
    if (!stacked) {
      if (datasets.length < 2) {
        return datasets[0].data.forEach((val) => val >= 200);
      } else {
        return (
          datasets[0].data.some((val) => val >= 200) || datasets[1].data.some((val) => val >= 200)
        );
      }
    }
  };
  const groupColumnWidth = columnWidth * numOfColumns;
  const chartWidth = Math.max(
    labels.length * (groupColumnWidth + px),
    (width ?? 0) - leftOffset ?? 0,
  );

  const device = useDeviceMode();
  const columnWidthPercent = (groupColumnWidth / (chartWidth / labels.length)) * 100;

  const behindChartOptions: ApexOptions = {
    annotations: {
      yaxis: [
        {
          y: 0,
        },
      ],
      xaxis: [
        {
          x: 0,
        },
      ],
    },
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      stacked,
      background: 'transparentColors',
      width: chartWidth,
      ...chart,
      animations: {
        enabled: false,
      },
    },
    legend: {
      show: false,
    },
    labels: labels as string[],
    plotOptions: {
      bar: {
        columnWidth: `${columnWidthPercent}%`,
      },
    },
    grid: {
      padding: {
        bottom: 0,
      },
      borderColor: transparentColors,
    },
    dataLabels: {
      enabled: false,
      style: {
        fontSize: '16px',
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    fill: {
      opacity: 1,
    },
    yaxis: {
      min: 0,
      forceNiceScale: true,
      labels: {
        offsetX: paddingWrap() ? -15 : 0,
        style: { colors: labelColor, ...LAYOUT_CONFIG.chart.yStyles },
        formatter: (value) => {
          return value < 0 ? '' : value.toLocaleString('en-US');
        },
      },
    },
    xaxis: {
      labels: {
        style: { colors: transparentColors, cssClass: 'xaxis-label' },
      },
      axisTicks: {
        color: transparentColors,
      },
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        color: transparentColors,
      },
    },

    tooltip: {
      enabled: false,
    },
    ...props,
    colors: createArray(props.colors?.length || numOfDataset, transparentColors),
  };
  const options: ApexOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      stacked,
      background: transparentColors,
      ...chart,
    },
    grid: {
      padding: {
        bottom: 0,
        left: device === DeviceMode.Mobile ? 30 : device === DeviceMode.Tablet ? 40 : 50,
      },
    },
    legend: {
      show: legend,
      position: 'top',
      horizontalAlign: 'center',
      offsetX: 40,
    },
    colors: [colors.primary],
    labels: labels as string[],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: `${columnWidthPercent}%`,
      },
    },
    dataLabels: {
      enabled: false,
      style: {
        fontSize: '16px',
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    fill: {
      opacity: 1,
    },
    yaxis: {
      labels: {
        style: { colors: labelColor, ...LAYOUT_CONFIG.chart.yStyles },
      },
      show: false,
    },
    xaxis: {
      labels: {
        style: {
          colors: labelColor,
          cssClass: 'xaxis-label',
        },
      },
    },
    ...props,
  };

  useLayoutEffect(() => {
    const scrollContainer = document.querySelectorAll('.scroll-container');
    if (scrollContainer) {
      scrollContainer.forEach((item) => (item.scrollLeft = item.scrollWidth + 100));
    }
  }, [datasets]);

  return (
    <Box
      position="relative"
      id="chart"
      width={width}
      h={height ?? '100%'}
      sx={{
        '& .xaxis-label': {
          fontSize: 'inherit',
          fontFamily: 'Roboto !important',
          colors: labelColor,
          '&.hidden': {
            colors: transparentColors,
          },
          '&:last-of-type': hightLightEndLabel
            ? {
                fontFamily: 'Roboto Bold !important',
              }
            : undefined,
        },
      }}
    >
      {width !== undefined && (
        <>
          <Box maxWidth={width} overflow="hidden" h={height ?? '100%'}>
            <ApexChart
              options={behindChartOptions}
              series={datasets}
              type="bar"
              height={height ?? '100%'}
              width={chartWidth}
            />
          </Box>
          <Box
            position="absolute"
            top="0"
            bottom="0"
            right="0"
            left={`${leftOffset}px`}
            height={height ?? '100%'}
            // paddingLeft={paddingWrap() ? '35px' : 0}
          >
            <ScrollContainer
              style={{
                maxWidth: '100%',
                height: !height ? '100%' : undefined,
              }}
              vertical={false}
              className="scroll-container"
            >
              <ApexChart
                options={options}
                series={datasets}
                type="bar"
                height={height ?? '100%'}
                width={chartWidth}
              />
            </ScrollContainer>
          </Box>
        </>
      )}
    </Box>
  );
};
