import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { companyActions, companySummarySelector } from 'store/modules/company';

import { CompanyApi, companyApi } from 'apis/company';

import { Typography } from 'components/atoms';

import { useQuery } from 'hooks/common';

import i18next from 'configs/i18n';

export const useCompanySummary = (refreshOnUnmounted: boolean = false) => {
  const dispatch = useDispatch();
  const storedSummary = useSelector(companySummarySelector);

  const [summary, loading, , tool] = useQuery<
    { totalActive: number; totalDisable: number; total: number },
    any,
    CompanyApi['summary']
  >(null, companyApi.summary, {
    preventInitCall: storedSummary.init,
  });

  const summarySections = useMemo(() => {
    const { totalActive, totalDisable, total } = storedSummary;
    return [
      {
        label: (
          <Typography.Text>
            {i18next.t<string>('message.totalNumberOf')} <br />{' '}
            {i18next.t<string>('label.partnerCompanies')}
          </Typography.Text>
        ),
        count: total,
      },
      {
        label: (
          <Typography.Text>
            {i18next.t<string>('message.numberOf')} <br />{' '}
            {i18next.t<string>('label.activeCompanies')}
          </Typography.Text>
        ),
        count: totalActive,
      },
      {
        label: (
          <Typography.Text>
            {i18next.t<string>('message.numberOf')}
            <br /> {i18next.t<string>('label.disabledCompanies')}
          </Typography.Text>
        ),
        count: totalDisable,
      },
    ];
  }, [storedSummary]);

  useEffect(() => {
    if (summary) {
      dispatch(companyActions.updateSummary(summary));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary]);

  useEffect(() => {
    return () => {
      refreshOnUnmounted && tool.refetch();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo<[typeof summarySections, boolean, typeof tool.refetch, typeof summary]>(
    () => [summarySections, loading, tool.refetch, summary],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [summarySections, loading],
  );
};
