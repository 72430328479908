import { Stack, VStack } from '@chakra-ui/react';
import { PATH_NAME } from 'configs';
import { PermissionGuard } from 'guards';
import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';
import { DeviceMode } from 'types';

import { InternalPermission } from 'types/permission';

import { Card, PrimaryButton, Typography } from 'components/atoms';

import { useDeviceMode, useRouter, useTranslate } from 'hooks/common';
import { useHqUserSummary } from 'hooks/components/hq-user';

import { LAYOUT_CONFIG } from 'configs/layout';

export const HqManagerWidget: React.FC = () => {
  const router = useRouter();
  const device = useDeviceMode();
  const t = useTranslate();
  const [, loading, , summary] = useHqUserSummary();
  const handleGoNewManager = () => {
    router.push(PATH_NAME.HQ_USER_NEW);
  };
  return (
    <Card
      loading={loading}
      round={LAYOUT_CONFIG.dashboard.round}
      fitParent
      bg="secondary"
      color="white"
      p={LAYOUT_CONFIG.dashboard.widgetPadding}
      textAlign="center"
    >
      <Stack
        h="100%"
        direction={{ base: 'column', md: 'row' }}
        align={{ base: 'stretch', md: 'center' }}
      >
        <VStack w={{ base: '100%', md: 'max-content', '2xl': '50%' }} mb={{ base: '20px', md: 0 }}>
          {device === DeviceMode.Mobile && (
            <Typography.Title size="lg" alignSelf="flex-start" textTransform="uppercase">
              {t('label.hqManager')}
            </Typography.Title>
          )}
          <Typography.Title
            fontSize={{ base: 'lg', md: '19px', lg: 'sm-3xl', '2xl': '3xl' }}
            lineHeight="1"
          >
            {summary?.total || 0}
          </Typography.Title>
          <Typography.Text whiteSpace="nowrap">
            <Trans
              t={t}
              i18nKey={'message.totalNumberOfHQManagers'}
              components={[device !== DeviceMode.Mobile ? <br key={0} /> : <Fragment />]}
            />
          </Typography.Text>
        </VStack>
        <VStack flex={{ base: 'initial', md: '1' }}>
          {device !== DeviceMode.Mobile && (
            <Typography.Title size="lg" textTransform="uppercase">
              {t('label.hqManager')}
            </Typography.Title>
          )}
          <PermissionGuard
            permissions={InternalPermission.CREATE_HQ_USER}
            component={PrimaryButton}
            minW={LAYOUT_CONFIG.button.width}
            onClick={handleGoNewManager}
            textTransform="uppercase"
          >
            {t('button.addNewHQManager')}
          </PermissionGuard>
        </VStack>
      </Stack>
    </Card>
  );
};
