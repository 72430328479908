import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store/root-reducer';

import { reasonTemplateApi } from 'apis/reason-template';

export const getReasonTemplateOptions = createAsyncThunk(
  'reasonTemplate/getReasonTemplateOptions',
  async () => {
    const res = await reasonTemplateApi.getReasonTemplateOptions();
    return res.data;
  },
  {
    condition: (_, { getState }) => {
      const { reasonTemplate } = getState() as RootState;
      if (reasonTemplate.fetchTemplateStatus === 'pending') {
        return false;
      }
    },
  },
);
