import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import SwapRightOutlined from '@ant-design/icons/SwapRightOutlined';
import { ThemeProvider } from '@chakra-ui/react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { debounce } from 'lodash';
import { RangePicker as RCRangePicker } from 'rc-picker';
import { GenerateConfig } from 'rc-picker/lib/generate';
import * as React from 'react';
import { overrides } from 'themes';

import { Components, RangePickerProps, getTimeProps } from '.';
import enUS from '../locale/en_US';
import { getRangePlaceholder } from '../util';

let inputs: NodeListOf<HTMLInputElement> | undefined;

export default function generateRangePicker<DateType>(
  generateConfig: GenerateConfig<DateType>,
): React.ComponentClass<RangePickerProps<DateType>> {
  class RangePicker extends React.Component<RangePickerProps<DateType>> {
    pickerRef = React.createRef<RCRangePicker<DateType>>();
    containerRef = React.createRef<HTMLDivElement>();

    state = {
      startDate: null,
      endDate: null,
      isStartDateFocused: false,
      isEndDateFocused: false,
    };

    focus = () => {
      if (this.pickerRef.current) {
        this.pickerRef.current.focus();
      }
    };

    blur = () => {
      if (this.pickerRef.current) {
        this.pickerRef.current.blur();
      }
    };

    handleFocusStartDate = () => {
      this.setState({ isStartDateFocused: true, isEndDateFocused: false });
    };
    handleFocusEndDate = () => {
      this.setState({ isStartDateFocused: false, isEndDateFocused: true });
    };
    handleBlurStartDate = () => {
      this.setState({ isStartDateFocused: false });
    };
    handleBlurEndDate = () => {
      this.setState({ isEndDateFocused: false });
    };

    handleSelectDate = (event: MouseEvent) => {
      if (
        (event.target as HTMLDivElement).classList.contains('date-picker-cell-inner') ||
        (event.target as HTMLDivElement).classList.contains('date-picker-time-panel-cell-inner')
      ) {
        inputs = this.containerRef.current?.querySelectorAll('.date-picker-input > input');
        if (this.state.isStartDateFocused) {
          setTimeout(() => {
            this.setState({ startDate: dayjs(inputs?.[0].value, this.props.format as any) });
          }, 10);
        }
        if (this.state.isEndDateFocused) {
          setTimeout(() => {
            this.setState({ endDate: dayjs(inputs?.[1].value, this.props.format as any) });
          }, 10);
        }
      }
    };

    // Debouce to avoid triggering onChange when change from start date to end date
    debouncedOnChange = debounce(() => {
      if (!this.state.isStartDateFocused && !this.state.isEndDateFocused) {
        if (this.state.startDate || this.state.endDate) {
          // console.log('should trigger onChange with values', [
          //   this.state.startDate,
          //   this.state.endDate,
          // ]);

          this.props.onChange?.([this.state.startDate, this.state.endDate], ['', '']);
          this.setState({ isStartDateFocused: true, isEndDateFocused: true });
        }
      }
    }, 10);

    componentDidMount() {
      inputs = this.containerRef.current?.querySelectorAll('.date-picker-input > input');
      if (inputs) {
        inputs?.[0].addEventListener('focus', this.handleFocusStartDate);
        inputs?.[1].addEventListener('focus', this.handleFocusEndDate);
        inputs?.[0].addEventListener('blur', this.handleBlurStartDate);
        inputs?.[1].addEventListener('blur', this.handleBlurEndDate);
      }
      document.addEventListener('click', this.handleSelectDate);
    }

    componentWillUnmount() {
      inputs = this.containerRef.current?.querySelectorAll('.date-picker-input > input');
      if (inputs) {
        inputs?.[0].removeEventListener('focus', this.handleFocusStartDate);
        inputs?.[1].removeEventListener('focus', this.handleFocusEndDate);
        inputs?.[0].removeEventListener('blur', this.handleBlurStartDate);
        inputs?.[1].removeEventListener('blur', this.handleBlurEndDate);
      }
      document.removeEventListener('click', this.handleSelectDate);
    }

    componentDidUpdate() {
      this.debouncedOnChange();
    }

    render() {
      const {
        getPopupContainer: customGetPopupContainer,
        className,
        placeholder,
        size,
        isInvalid,
        ...restProps
      } = this.props;
      const prefixCls = 'date-picker';
      const { format, showTime, picker } = this.props as any;

      let additionalOverrideProps: any = {};

      additionalOverrideProps = {
        ...additionalOverrideProps,
        ...(showTime ? getTimeProps({ format, picker, ...showTime }) : {}),
        ...(picker === 'time' ? getTimeProps({ format, ...this.props, picker }) : {}),
      };
      return (
        <ThemeProvider theme={overrides}>
          <div ref={this.containerRef}>
            <RCRangePicker<DateType>
              placeholder={getRangePlaceholder(picker, enUS, placeholder)}
              prefixCls={prefixCls}
              separator={<SwapRightOutlined />}
              ref={this.pickerRef}
              suffixIcon={picker === 'time' ? <ClockCircleOutlined /> : <CalendarOutlined />}
              clearIcon={<CloseCircleFilled />}
              allowClear
              {...restProps}
              {...additionalOverrideProps}
              locale={{
                ...enUS.lang,
                shortMonths: [
                  t('value.jan'),
                  t('value.feb'),
                  t('value.mar'),
                  t('value.apr'),
                  t('value.may'),
                  t('value.jun'),
                  t('value.jul'),
                  t('value.aug'),
                  t('value.sep'),
                  t('value.oct'),
                  t('value.nov'),
                  t('value.dec'),
                ],
                shortWeekDays: [
                  t('value.su'),
                  t('value.mo'),
                  t('value.tu'),
                  t('value.we'),
                  t('value.th'),
                  t('value.fr'),
                  t('value.sa'),
                ],
                ok: t('button.ok'),
              }}
              generateConfig={generateConfig}
              prevIcon={<span className={`${prefixCls}-prev-icon`} />}
              nextIcon={<span className={`${prefixCls}-next-icon`} />}
              superPrevIcon={<span className={`${prefixCls}-super-prev-icon`} />}
              superNextIcon={<span className={`${prefixCls}-super-next-icon`} />}
              components={Components}
              className={classNames(
                { 'size-sm': size === 'sm', 'size-md': size === 'md' },
                { invalid: isInvalid },
                className,
              )}
            />
          </div>
        </ThemeProvider>
      );
    }
  }

  return RangePicker;
}
