import { useSelector } from 'react-redux';

import { assignedLocationSelector } from 'store/modules/location-profile';

import { Role } from 'models/user';

import { PartnerPermission } from 'types/permission';

import { useCurrentRole } from '../auth';
import { useHasPermissions } from '../auth/useHasPermissions';

export const useCanCreateJob = (): boolean => {
  const role = useCurrentRole();
  const hasCreatePermission = useHasPermissions(PartnerPermission.CREATE_JOB);
  const assignedLocation = useSelector(assignedLocationSelector);

  if (!role) return false;
  if (role !== Role.LocationManager && hasCreatePermission) return true;
  if (!assignedLocation) return false;
  return !!assignedLocation.location_manager_can_create_job;
};
