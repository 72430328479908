import { Box, BoxProps, Divider, HStack, Icon, IconButton, VStack } from '@chakra-ui/react';
import { PATH_NAME } from 'configs';
import noop from 'lodash/noop';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { DeviceMode } from 'types';

import { authActions } from 'store/modules/auth';

import { createResponsiveStyle } from 'themes/foundations/breakpoints';
import { colors } from 'themes/foundations/colors';

import { authApi } from 'apis/auth';

import { Typography } from 'components/atoms';
import { ProfileInfo } from 'components/molecules';
import { CollapsableProfileInfo } from 'components/molecules/CollapsableProfileInfo';

import { useDeviceMode, useRouter, useTranslate } from 'hooks/common';

import { ExportIcon, LogoIcon, TextLogoIcon } from 'assets/icons';

import { authStorage, rememberMeStorage, hqStorage, userStorage } from 'configs/browser-storage';
import { LAYOUT_CONFIG } from 'configs/layout';

import { NavMenu, navMenuConfig } from './NavMenu';
import { useAuthorizedMenu } from './NavMenu/useAuthorizedMenu';

export type SideBarProps = {
  onChangeMenu?: () => void;
} & BoxProps;
export const SideBar: FC<SideBarProps> = ({ onChangeMenu = noop, ...props }) => {
  const device = useDeviceMode();
  const router = useRouter();
  const t = useTranslate();
  const dispatch = useDispatch();

  const menu = useAuthorizedMenu(navMenuConfig);

  const handleLogout = () => {
    authApi.logout();
    authStorage.clear();
    userStorage.clear();
    hqStorage.clear();
    rememberMeStorage.clear();
    dispatch(authActions.clear());
  };

  const handleClickLogo = () => {
    onChangeMenu();
    router.push(PATH_NAME.DASHBOARD);
  };

  return (
    <Box
      w="100%"
      h="100%"
      px={createResponsiveStyle({ base: '36px', md: LAYOUT_CONFIG.gap, lg: 'initial' })}
      className="SideBar"
      {...props}
    >
      {device === DeviceMode.Mobile && (
        <VStack spacing="14px">
          <Icon w="60px" height="auto" as={LogoIcon} onClick={handleClickLogo} />
          <Icon w="170px" height="auto" as={TextLogoIcon} onClick={handleClickLogo} />
        </VStack>
      )}
      {device === DeviceMode.Tablet && (
        <HStack spacing="14px" justifyContent="center">
          <Icon w="45px" height="auto" as={LogoIcon} onClick={handleClickLogo} />
          <Icon w="170px" height="auto" as={TextLogoIcon} onClick={handleClickLogo} />
        </HStack>
      )}
      {device !== DeviceMode.Mobile ? (
        <>
          <ProfileInfo onClickProfileIcon={onChangeMenu} mt="14px" />
          <Divider
            mt="16px"
            w={`calc(100% + ${LAYOUT_CONFIG.gap})`}
            boxShadow={`0 1px 2px ${colors.tango[600]}`}
            borderColor="tangoOpacity.20"
            orientation="horizontal"
          />
        </>
      ) : (
        <CollapsableProfileInfo mt="14px" onClickProfileIcon={onChangeMenu} />
      )}
      <Box mt="32px" pl={LAYOUT_CONFIG.sidebar.paddingLeft} pb="24px">
        <NavMenu menu={menu} onClickChange={onChangeMenu} />
        {device === DeviceMode.Mobile && (
          <HStack mt="30px" mb="100px" spacing="22px">
            <IconButton
              aria-label="logout"
              icon={<Icon as={ExportIcon} w="21px" h="24px" />}
              onClick={handleLogout}
            />
            <Typography.Text fontSize="lg" onClick={handleLogout}>
              {t('button.logOut')}
            </Typography.Text>
          </HStack>
        )}
      </Box>
    </Box>
  );
};
