import { httpClient } from 'apis';
import { AxiosResponse } from 'axios';
import i18next from 'i18next';
import { DataResponse, MessageAxiosResponse, ObjectAxiosResponse } from 'types';
import clevertap from 'clevertap-web-sdk';
import { IUser } from 'models/user';

import { userStorage } from 'configs/browser-storage';

import { mapErrorDescriptions } from './utils';

const baseUrl = 'auth';

export const authApi = {
  async login(
    email: string,
    password: string,
    tfa_code: string,
    remember_me?: 1 | 0,
  ): Promise<
    DataResponse<{ accessToken: string; tokenType: string; expiresIn: number; user: IUser; hqUser?: IUser; }>
  > {
    try {
      const res: AxiosResponse<{
        access_token: string;
        token_type: string;
        expires_in: number;
        user: IUser;
        hqUser?: IUser;
      }> = await httpClient.post(`${baseUrl}/login`, { email, password, remember_me, tfa_code });
      const { data } = res;
      clevertap.onUserLogin.push({
        "Site": {
          "Name": data.user.first_name + " " + data.user.last_name,
          "Identity": data.user.id,
          "Email": data.user.email,
        }
      })
      localStorage.setItem('clevertap', 'true');
      clevertap.event.push("Login");
      return {
        data: {
          hqUser: data.hqUser,
          user: data.user,
          accessToken: data.access_token,
          tokenType: data.token_type,
          expiresIn: data.expires_in,
        },
        status: true,
        message: '',
      };
    } catch (error: any) {
      const message = error.message ?? i18next.t<string>('message.somethingWentWrong');
      return Promise.reject({
        status: false,
        message,
      });
    }
  },
  async selectOtpMethod(
    tfa_method: string
  ): Promise<
    DataResponse<{ status: boolean; message?: string; }>
  > {
    try {
      const res = await httpClient.post(`${baseUrl}/2fa-method`, { tfa_method });
      return {
        status: true,
        message: res.data.message,
        data: {
          status: res.data.status,
          message: res.data.message,
        }
      };
    } catch (error: any) {
      const message = error.message ?? i18next.t<string>('message.somethingWentWrong');
      return Promise.reject({
        status: false,
        message,
      });
    }
  },
  async getOtp2fa(): Promise<
    DataResponse<{ status: boolean; message?: string; otp_created_at?: string; expiration_mins?: number; qrCodeUrl?: string; otpauthUrl?: string; user: IUser;}>
  > {
    try {
      const res = await httpClient.get(`${baseUrl}/generate-2fa-otp`);

      // Check if the response contains the QR code image
      if (res.data.data && typeof res.data.data === 'string' && res.data.data.startsWith('data:image')) {
        return {
          status: true,
          message: 'QR code fetched successfully',
          data: {
            status: true,
            otp_created_at: '',
            expiration_mins: undefined,
            user: res.data.user,
            qrCodeUrl: res.data.data,
            otpauthUrl: res.data.otp_path_url
          },
        };
      }

      // Otherwise, handle the response with status, message, and additional data
      return {
        status: true,
        message: res.data.message ?? 'OTP generated successfully',
        data: {
          status: res.data.status,
          otp_created_at: res.data.otp_created_at ?? '',
          expiration_mins: res.data.expiration_mins ?? undefined,
          user: res.data.user ?? null,
          qrCodeUrl: res.data.qrCodeUrl ?? null,
          otpauthUrl: res.data.otp_path_url ?? null,
        },
      };
    } catch (error: any) {
      const message = error.response?.data?.message ?? i18next.t<string>('message.somethingWentWrong');
      return Promise.reject({
        status: false,
        message,
      });
    }
  },
  async getOtpTimeExpire(): Promise<DataResponse<{ status: boolean; expiration_mins?: number; }>> {
    try {
      const res = await httpClient.get(`${baseUrl}/get-otp-expiration`);
      return {
        status: true,
        message: 'OTP expiration minutes',
        data: {
          status: res.data.status,
          expiration_mins: res.data.expiration_time !== undefined ? Number(res.data.expiration_time) : undefined,
        },
      };
    } catch (error: any) {
      const message = error.response?.data?.message ?? i18next.t<string>('message.somethingWentWrong');
      return Promise.reject({
        status: false,
        message,
      });
    }
  },  
  async verifyOtpCode(
    tfa_code: string
  ): Promise<
    DataResponse<{ status: boolean; message?: string; }>
  > {
    try {
      const res = await httpClient.post(`${baseUrl}/verify-otp-2fa`, { tfa_code });
      return {
        status: true,
        message: res.data.message,
        data: {
          status: res.data.status,
          message: res.data.message,
        }
      };
    } catch (error: any) {
      const message = error.message ?? i18next.t<string>('message.somethingWentWrong');
      return Promise.reject({
        status: false,
        message,
      });
    }
  },
  async getLogInQrCode(
    email: string,
  ): Promise<DataResponse<{ qrCode: string }>> {
    try {
      const res: AxiosResponse = await httpClient.get(`${baseUrl}/login-qrcode`, {
        params: {
          email: email,
        },
      });

      return {
        data: { qrCode: res.data },
        message: '',
        status: true,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async refreshToken(): Promise<
    DataResponse<{ accessToken: string; tokenType: string; expiresIn: number; user: IUser }>
  > {
    try {
      const res: AxiosResponse<{
        access_token: string;
        token_type: string;
        expires_in: number;
        user: IUser;
      }> = await httpClient.post(`${baseUrl}/refresh`);
      const { data } = res;
      return {
        data: {
          user: data.user,
          accessToken: data.access_token,
          tokenType: data.token_type,
          expiresIn: data.expires_in,
        },
        status: true,
        message: '',
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error?.error?.message || i18next.t<string>('message.emailOrPasswordIncorrect'),
      });
    }
  },
  async logout() {
    try {
      await httpClient.post(`${baseUrl}/logout`);
      httpClient.deleteAuthorization();
    } catch (error: any) { }
  },
  async forgotPassword(email: string): Promise<DataResponse<null>> {
    try {
      const res: MessageAxiosResponse = await httpClient.post(`${baseUrl}/forgot-password`, {
        email,
      });
      const { data } = res;
      return {
        status: true,
        message: data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.somethingWentWrong'), description } = error;
      return Promise.reject({
        status: false,
        message,
        description: mapErrorDescriptions({ email: 'email' }, description),
      });
    }
  },
  async resetPassword(
    password: string,
    confirmPassword: string,
    key: string,
  ): Promise<DataResponse<null>> {
    try {
      const res: MessageAxiosResponse = await httpClient.post(`${baseUrl}/reset-password`, {
        password,
        confirm_password: confirmPassword,
        key,
      });
      const { data } = res;
      // clevertap.event.push('Reset Password')
      return {
        status: true,
        message: data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.somethingWentWrong') } = error;
      return Promise.reject({
        status: false,
        message,
      });
    }
  },
  async checkResetToken(key: string): Promise<DataResponse<{ validToken: boolean }>> {
    try {
      const res: MessageAxiosResponse = await httpClient.post(
        `${baseUrl}/check-reset-password-token`,
        {
          key: key,
        },
      );
      const { data } = res;
      return {
        status: true,
        message: data.message,
        data: {
          validToken: true,
        },
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.somethingWentWrong') } = error;
      return Promise.reject({
        status: false,
        message,
      });
    }
  },
  async getCurrentPermission(): Promise<DataResponse<IUser>> {
    try {
      const res: ObjectAxiosResponse<IUser> = await httpClient.get(`portal/user/current-user-info`);
      const { data } = res;
      // authStorage.update({ user: data.data });
      userStorage.update(data.data);
      return {
        status: true,
        data: data.data,
        message: '',
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.somethingWentWrong') } = error;
      return Promise.reject({
        status: false,
        message,
      });
    }
  },
  async verifyEmailHq(email: string, token: string) {
    try {
      const res = await httpClient.get(`${baseUrl}/verify-email-api`, {
        params: {
          email: email,
          signingcode: token,
        }
      });

      return {
        data: res.data.data,
        message: res.data.message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: error.status,
        message: error.message,
      });
    }
  }
};

export type AuthApi = typeof authApi;
