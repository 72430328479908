import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  isFetchingProfileInfoSelector,
  locationProfileActions,
  locationProfileInfoSelector,
} from 'store/modules/location-profile';

import { Role } from 'models/user';

import { useCurrentRole } from '../auth';

export const useLocationProfileIndividual = (refreshOnUnmounted?: boolean) => {
  const dispatch = useDispatch();
  const role = useCurrentRole();
  const storedSummary = useSelector(locationProfileInfoSelector);
  const loading = useSelector(isFetchingProfileInfoSelector);

  const reFetch = () => {
    if (role === Role.LocationManager) {
      dispatch(locationProfileActions.getLocationProfileInfo());
    }
  };

  useEffect(() => {
    reFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo<[typeof storedSummary, boolean, typeof reFetch]>(
    () => [storedSummary, loading, reFetch],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storedSummary, loading],
  );
};
