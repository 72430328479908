import { httpClient } from 'apis';
import { AxiosResponse } from 'axios';
import { DataResponse, ListAxiosResponse, Payload, Query } from 'types';

import { IReasonTemplate, ReasonTemplateOption } from 'models/reason';

import { mapPaginationState, mapQueryToParams } from './utils';
import { PermissionType } from 'models/user';
import { orderOptions } from 'utils/array';

const entity = 'reasonTemplate';
const basePath = `/${entity}` as const;

export const reasonTemplateApi = {
  async getReasonTemplateList(
    payload: Payload<null, { type: PermissionType }, Query>,
  ): Promise<DataResponse<IReasonTemplate[]>> {
    try {
      const { pagination, query, params } = payload;
      const { sort, search } = query || {};

      const res: ListAxiosResponse<IReasonTemplate> = await httpClient.get(`${basePath}/list-roles`, {
        params: mapQueryToParams({ sort, search }, pagination, { type: params.type }),
      });
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data.data,
        message: message,
        pagination: mapPaginationState(data),
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async getReasonTemplateOptions(): Promise<DataResponse<ReasonTemplateOption[]>> {
    try {
      const res: AxiosResponse<{ data: IReasonTemplate[] }> = await httpClient.get(
        `${basePath}/index?type=1`,
      );
      return {
        data: orderOptions(
          res.data.data.map((item) => ({ ...item, value: item.id, label: item.name })),
        ),
        message: '',
        status: true,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
};

export type ReasonTemplateApi = typeof reasonTemplateApi;