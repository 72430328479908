import { useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  educationalInstituteActions,
  educationalInstituteSummarySelector,
} from 'store/modules/educational-institute';

import { EducationApi, educationApi } from 'apis/education';

import { InternalPermission } from 'types/permission';

import { Typography } from 'components/atoms';

import { useQuery, useTranslate } from 'hooks/common';

export const useEducationalInstituteSummary = (refreshOnUnmounted: boolean = false) => {
  const dispatch = useDispatch();
  const storedSummary = useSelector(educationalInstituteSummarySelector);
  const t = useTranslate();

  const [summary, loading, , tool] = useQuery<{ total: number }, any, EducationApi['summary']>(
    null,
    educationApi.summary,
    {
      preventInitCall: storedSummary.init,
      permission: [
        InternalPermission.VIEW_EDUCATIONAL_INSTITUTE,
        InternalPermission.CREATE_EDUCATIONAL_INSTITUTE,
        InternalPermission.UPDATE_EDUCATIONAL_INSTITUTE,
      ],
    },
  );
  const summarySections = useMemo(() => {
    const { total } = storedSummary;
    return [
      {
        label: (
          <Typography.Text>
            <Trans
              t={t}
              i18nKey={'label.totalNumberOfEducationalInstitutes'}
              components={[<br key={0} />]}
            />
          </Typography.Text>
        ),
        count: total,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedSummary]);

  useEffect(() => {
    if (summary) {
      dispatch(educationalInstituteActions.updateSummary(summary));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary]);

  useEffect(() => {
    return () => {
      refreshOnUnmounted && tool.refetch();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo<[typeof summarySections, boolean, typeof tool.refetch, typeof storedSummary]>(
    () => [summarySections, loading, tool.refetch, storedSummary],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [summarySections, loading],
  );
};
