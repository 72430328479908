import { httpClient } from 'apis';
import { AxiosResponse } from 'axios';
import { DEFAULT_PAGE_SIZE } from 'constant';
import i18next from 'i18next';
import { AnyAxiosResponse, DataResponse, ListAxiosResponse, Option, Payload, Query } from 'types';
import { parseToFormData } from 'utils';

import { IJobType } from 'models/job';

import { JobTypeFilterData } from 'components/molecules/JobTypeFilter';

import { concatParams, mapErrorDescriptions, mapQueryToParams } from './utils';

export type FormData = {
  jobTitle: string;
  jobDescription: string;
  wageRange: string;
};

const entity = 'jobtype';
const basePath = `portal/${entity}` as const;

const jobtypePayloadScheme = {
  title: 'jobTitle',
  description: 'jobDescription',
  wage_range: 'wageRange',
  logo: 'logo',
};

export const jobTypeApi = {
  async addNewJobType(
    payload: Payload<{ title: string; description: string; wage_range?: string; logo?: FileList; hex_color?: string }>,
  ): Promise<DataResponse<{ data: IJobType; message: string }>> {
    try {
      const { body } = payload;

      const formData = parseToFormData({
        title: body.title,
        description: body.description,
        wage_range: body.wage_range,
        logo: body.logo,
        hex_color: body.hex_color
      });

      const res: AxiosResponse<{
        data: IJobType;
        message: string;
      }> = await httpClient.post(`${basePath}/store`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return {
        data: res.data,
        message: res.data.message,
        status: true,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message || i18next.t<string>('message.createdJobTypeUnsuccess'),
        description: mapErrorDescriptions(jobtypePayloadScheme, error.description),
      });
    }
  },
  async updateJobType(
    id: number | string,
    payload: Payload<{ title: string; description: string; wage_range?: string; logo?: FileList, hex_color?: string }>,
  ): Promise<DataResponse<{ data: IJobType; message: string }>> {
    try {
      const { body } = payload;
      const formData = parseToFormData({
        title: body.title,
        description: body.description,
        wage_range: body.wage_range,
        logo: body.logo,
        hex_color: body.hex_color,
      });
      const res: AxiosResponse<{
        data: IJobType;
        message: string;
      }> = await httpClient.post(`${basePath}/edit/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return {
        data: res.data,
        message: res.data.message,
        status: true,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message || i18next.t<string>('message.updatedJobTypeUnsuccess'),
        description: mapErrorDescriptions(jobtypePayloadScheme, error.description),
      });
    }
  },
  async disableJobType(id: number): Promise<DataResponse<{ data: IJobType; message: string }>> {
    try {
      const res: AxiosResponse<{
        data: IJobType;
        message: string;
      }> = await httpClient.delete(`${basePath}/disable/${id}`);
      return {
        data: res.data,
        message: res.data.message,
        status: true,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.disableJobTypeUnsuccess') } = error;
      return Promise.reject({
        status: false,
        message: message,
      });
    }
  },
  async enableJobType(id: number): Promise<DataResponse<{ data: IJobType; message: string }>> {
    try {
      const res: AxiosResponse<{
        data: IJobType;
        message: string;
      }> = await httpClient.put(`${basePath}/enable/${id}`);
      return {
        data: res.data,
        message: res.data.message,
        status: true,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.enableJobTypeUnsuccess') } = error;
      return Promise.reject({
        status: false,
        message: message,
      });
    }
  },
  async getJobTypeDetail(id: string): Promise<DataResponse<IJobType>> {
    try {
      const res: AxiosResponse<{
        data: IJobType;
        message: string;
      }> = await httpClient.get(`${basePath}/show/${id}`);
      return {
        data: res.data.data,
        message: res.data.message,
        status: true,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async SearchJobType(id: string): Promise<DataResponse<{ data: IJobType[]; message: string }>> {
    try {
      const res: AxiosResponse<{
        data: IJobType[];
        message: string;
      }> = await httpClient.get(`${basePath}/search/${id}`);
      return {
        data: res.data,
        message: '',
        status: true,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async getOptions(): Promise<DataResponse<Option[]>> {
    try {
      const res: AnyAxiosResponse<Option[]> = await httpClient.get(`${basePath}/option`);
      const { data, message } = res.data;

      return {
        status: true,
        data,
        message,
      };
    } catch (error: any) {
      return {
        status: true,
        data: [],
        message: '',
      };
    }
  },
  async getListJobType(
    payload: Payload<null, null, Query & Partial<JobTypeFilterData>>,
  ): Promise<DataResponse<IJobType[]>> {
    try {
      const { pagination, query } = payload;
      const {
        sort,
        search,
        recent,
        jobTypeStatus,
        minAvailablePositions,
        maxAvailablePositions,
        minAvailableCompanies,
        maxAvailableCompanies,
      } = query || {};

      const filterTypes = concatParams(recent ? 'recent' : '', jobTypeStatus ?? '');

      const res: ListAxiosResponse<IJobType> = await httpClient.get(`${basePath}/index`, {
        params: mapQueryToParams(
          { sort, search },
          pagination,
          {
            filter_types: filterTypes,
            min_available_positions: minAvailablePositions,
            max_available_positions: maxAvailablePositions,
            min_available_companies: minAvailableCompanies,
            max_available_companies: maxAvailableCompanies,
          },
          {
            sortDefaultByCreatedAt: true,
          },
        ),
      });
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data.data,
        message: message,
        pagination: {
          current: data.page || 1,
          pageSize: +data.limit || DEFAULT_PAGE_SIZE,
          total: data.total,
        },
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async summary(): Promise<DataResponse<{ total: number }>> {
    try {
      const res: AxiosResponse<{ data: { total: number }; message: string }> = await httpClient.get(
        `${basePath}/summary`,
      );
      return {
        status: true,
        data: res.data.data,
        message: '',
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
};

export type JobTypeApi = typeof jobTypeApi;
