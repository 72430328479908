import { JobStatusText } from 'models/job';

import { useTranslate } from 'hooks/common';

export const useJobStatusTextLabels = () => {
  const t = useTranslate();
  const JOB_STATUS_TEXT_LABEL: Record<JobStatusText, string> = {
    [JobStatusText.Pending]: t('value.pending'),
    [JobStatusText.Open]: t('value.open'),
    [JobStatusText.Active]: t('value.active'),
    [JobStatusText.Completed]: t('value.completed'),
    [JobStatusText.Cancel]: t('value.cancelled'),
    [JobStatusText.ExpiredNoApplicants]: t('value.expiredWithNoApplicants'),
    [JobStatusText.ExpiredNoSelection]: t('value.expiredNoSelection'),
    [JobStatusText.ActiveWithRejected]: t('value.activeWithRejected'),
    [JobStatusText.ActiveWithCancelled]: t('value.activeWithCancelled'),
    [JobStatusText.AllRejected]: t('value.allRejected'),
    [JobStatusText.AllCancelled]: t('value.allCancelled'),
    [JobStatusText.ClosedRejected]: t('value.closedRejected'),
    [JobStatusText.ClosedCancelled]: t('value.closedCancelled'),
    [JobStatusText.PartiallyCompleted]: t('value.partiallyCompleted'),
    [JobStatusText.Incomplete]: t('value.incomplete'),
    [JobStatusText.Closed]: t('value.closed'),
    [JobStatusText.Expired]: t('value.expired'),
  };

  return JOB_STATUS_TEXT_LABEL;
};
