import { JobStatus } from 'models/job';

import { useTranslate } from '../../common';

export const useJobStatusLabels = () => {
  const t = useTranslate();
  const JOB_STATUS_LABEL: Record<any, string> = {
    [JobStatus.Pending]: t('value.pending'),
    [JobStatus.Open]: t('value.open'),
    [JobStatus.Active]: t('value.active'),
    [JobStatus.Completed]: t('value.completed'),
    [JobStatus.Cancel]: t('value.cancelled'),
    [JobStatus.ExpiredNoApplicants]: t('value.expiredWithNoApplicants'),
    [JobStatus.ExpiredNoSelection]: t('value.expiredNoSelection'),
    [JobStatus.ActiveWithRejected]: t('value.activeWithRejected'),
    [JobStatus.ActiveWithCancelled]: t('value.activeWithCancelled'),
    [JobStatus.AllRejected]: t('value.allRejected'),
    [JobStatus.AllCancelled]: t('value.allCancelled'),
    [JobStatus.ClosedRejected] : t('value.closedRejected'),
    [JobStatus.ClosedCancelled] : t('value.closedCancelled'),
    [JobStatus.PartiallyCompleted]: t('value.partiallyCompleted'),
    [JobStatus.Incomplete]: t('value.incomplete'),

    // [JobStatus.CompletedWoApplicant]: t('value.completedWithoutAppliedApplicant'),
    // [JobStatus.CompletedWoManager]: t('value.completedWithoutHiringManager'),
  };

  return JOB_STATUS_LABEL;
};

export const useReportJobStatusLabels = () => {
  const t = useTranslate();
  const JOB_STATUS_LABEL: Record<JobStatus, string> = {
    [JobStatus.Pending]: t('value.pending'),
    [JobStatus.Open]: t('value.open'),
    [JobStatus.Active]: t('value.active'),
    [JobStatus.Completed]: t('value.completed'),
    [JobStatus.Cancel]: t('value.cancelled'),
    // [JobStatus.CompletedWoApplicant]: t('value.completedWithoutAppliedApplicant'),
    // [JobStatus.CompletedWoManager]: t('value.completedWithoutHiringManager'),
    [JobStatus.ExpiredNoApplicants]: t('value.expiredWithNoApplicants'),
    [JobStatus.ExpiredNoSelection]: t('value.expiredNoSelection'),
    [JobStatus.AllRejected]: t('value.allRejected'),
    [JobStatus.ActiveWithRejected]: t('value.activeWithRejected'),
    [JobStatus.AllCancelled]: t('value.allCancelled'),
    [JobStatus.ActiveWithCancelled]: t('value.activeWithCancelled'),
    [JobStatus.ClosedRejected]: t('value.closedRejected'),
    [JobStatus.ClosedCancelled]: t('value.closedCancelled'),
    [JobStatus.PartiallyCompleted]: t('value.partiallyCompleted'),
    [JobStatus.Incomplete]: t('value.incomplete'),
  };

  return JOB_STATUS_LABEL;
};
