import { Box, BoxProps, Center, Flex, Grid, GridItem, HStack, Text } from '@chakra-ui/react';
import { ENV, PATH_NAME } from 'configs';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeviceMode } from 'types';

import { authActions } from 'store/modules/auth';
import { headerTitleSelector } from 'store/modules/config';

import { authApi } from 'apis/auth';

import { Logo, PrimaryButton } from 'components/atoms';

// import { NotificationTracker } from 'components/molecules/NotificationTracker';
import { useDeviceMode, useRouter } from 'hooks/common';

import { ExportIcon } from 'assets/icons';

import { authStorage, rememberMeStorage, hqStorage, userStorage } from 'configs/browser-storage';
import { LAYOUT_CONFIG } from 'configs/layout';
import packageJson from '../../../package.json';
import { clearHq } from 'store/modules/superHq-user';

export const Header: FC<BoxProps> = (props) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const device = useDeviceMode();
  const { headerTitle: title, headerTitleBreakLine: breakLineTitle = [] } =
    useSelector(headerTitleSelector);
  const handleLogout = () => {
    authApi.logout();
    authStorage.clear();
    userStorage.clear();
    hqStorage.clear();
    rememberMeStorage.clear();
    dispatch(authActions.clear());
    dispatch(clearHq());
  };

  const handleClickLogo = () => {
    router.push(PATH_NAME.DASHBOARD);
  };

  return (
    <Grid
      className="Header"
      templateColumns={{ base: '100%', lg: `repeat(${LAYOUT_CONFIG.columns}, 1fr)` }}
      gap={{ lg: LAYOUT_CONFIG.gap }}
      h="100%"
    >
      {device !== DeviceMode.Desktop ? (
        <GridItem>
          <Flex className="aaa" h="100%" justifyContent="center" position="relative">
            <Box maxW={{ md: '680px', lg: 'initial' }}>
              {device === DeviceMode.Tablet || !breakLineTitle?.length ? (
                <Text
                  px="65px"
                  textTransform="uppercase"
                  textAlign="center"
                  variant="bold"
                  as="h1"
                  lineHeight="1.3"
                  fontSize={{ base: '24px', md: '33px' }}
                  whiteSpace={{ base: 'inherit', lg: 'nowrap' }}
                >
                  {title}
                </Text>
              ) : (
                <>
                  <Text
                    px="65px"
                    textTransform="uppercase"
                    textAlign="center"
                    variant="bold"
                    as="h1"
                    lineHeight="1.3"
                    fontSize={{ base: '24px', md: '33px' }}
                    whiteSpace={{ base: 'inherit', lg: 'nowrap' }}
                  >
                    {breakLineTitle[0]}
                  </Text>
                  <Text
                    textTransform="uppercase"
                    textAlign="center"
                    as="h1"
                    lineHeight="1"
                    mt="10px"
                    variant="bold"
                    fontSize={{ base: '24px', md: '33px' }}
                    whiteSpace={{ base: 'inherit', lg: 'nowrap' }}
                    px="10px"
                  >
                    {breakLineTitle[1]}
                  </Text>
                </>
              )}
            </Box>
            <HStack
              position="absolute"
              top="0"
              right="20px"
              spacing={{ base: '20px', md: '25px' }}
              alignItems={{ base: 'flex-start', md: 'center' }}
            >
              {/* <NotificationTracker /> */}
              {device !== DeviceMode.Mobile && (
                <PrimaryButton onClick={handleLogout} px="0" h={10} minW={10}>
                  <ExportIcon />
                </PrimaryButton>
              )}
            </HStack>
          </Flex>
        </GridItem>
      ) : (
        <>
          <GridItem colSpan={LAYOUT_CONFIG.sidebar.colSpan}>
            <Center h="100%">
              <Box maxWidth="100%" width={'100%'} position="relative">
                <Logo onClick={handleClickLogo} cursor="pointer" />
                <Box
                  position="absolute"
                  left={'70px'}
                  top="2.5px"
                  right={ENV.INSTANCE.VIETNAM ? '-70px' : '0px'}
                  color="#707070b3"
                  fontWeight="500"
                  lineHeight={1.5}
                  fontSize="14px"
                >
                  {ENV.INSTANCE.VIETNAM && 'beta '}v{packageJson.version}
                </Box>
              </Box>
            </Center>
          </GridItem>
          <GridItem colSpan={LAYOUT_CONFIG.main.colSpan}>
            <HStack h="100%" spacing="24px" justify="flex-end">
              <PrimaryButton onClick={handleLogout} px="0" h={10} minW={10}>
                <ExportIcon />
              </PrimaryButton>
            </HStack>
          </GridItem>
        </>
      )}
    </Grid>
  );
};
