import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { configActions, counterFlagSelector } from 'store/modules/config';

export const useCounterFlag = () => {
  const counterFlag = useSelector(counterFlagSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (counterFlag !== 0) {
      dispatch(configActions.resetCounter());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => {
    return {
      value: counterFlag,
      increase: (step?: number) => dispatch(configActions.increaseCounter(step)),
      decrease: (step?: number) => dispatch(configActions.decreaseCounter(step)),
      reset: () => dispatch(configActions.resetCounter()),
    };
  }, [counterFlag, dispatch]);
};
