import { httpClient } from 'apis';
import { AxiosResponse } from 'axios';
import i18next from 'i18next';
import { AnyAxiosResponse, DataResponse, Option, Payload } from 'types';
import { removeEmpty, removeNullish } from 'utils';

import { IManager } from 'models/manager';
import { Role } from 'models/user';

import { ManagerFormData } from 'components/organisms/ManagerForm';
import { SystemManagerFormData } from 'components/organisms/SystemManagerForm';

import { orderOptions } from 'utils/array';

import { getPathByRole, mapErrorDescriptions } from './utils';

const entity = 'locationUser';
const basePath = `portal/${entity}` as const;

const managerPayloadScheme = {
  first_name: 'firstName',
  last_name: 'lastName',
  manager_type: 'managerType',
  contact_number: 'contactNumber',
  email: 'workEmail',
  location_id: 'location',
  job_title: 'jobTitle',
};

export const locationManagerApi = {
  async getDetail(payload: Payload<null, { id: string }>): Promise<DataResponse<IManager>> {
    try {
      const {
        params: { id },
      } = payload;

      const res: AxiosResponse<{ data: IManager; message: string }> = await httpClient.get(
        `${basePath}/show/${id}`,
      );

      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        message: message,
        data: data,
      };
    } catch (error: any) {
      return Promise.reject({ message: error.message, status: false });
    }
  },
  async create(payload: Payload<ManagerFormData>): Promise<DataResponse<null>> {
    try {
      const { body } = payload;
      const bodyData = {
        first_name: body.firstName,
        last_name: body.lastName,
        job_title: body.jobTitle,
        email: body.workEmail,
        contact_number: body.contactNumber,
        location_id: body.location?.value,
        user_setting_sms_notification_status: body.userSettingSMSNotificationStatus ? 1 : 0,
        user_setting_email_notification_status: body.userSettingEmailNotificationStatus ? 1 : 0,
        user_setting_whatsapp_notification_status: body.userSettingWhatsappNotificationStatus ? 1 : 0,
      };
      const res: AxiosResponse<{ message: string; data: IManager }> = await httpClient.post(
        `${basePath}/store`,
        removeEmpty(bodyData),
      );
      const { data } = res;
      return {
        status: true,
        message: data.message,
      };
    } catch (error: any) {
      const {
        message = i18next.t<string>('message.createdLocationManagerUnsuccess'),
        description,
      } = error;
      return Promise.reject({
        status: false,
        description: mapErrorDescriptions(managerPayloadScheme, description),
        message,
      });
    }
  },
  async createBySuperAdmin(payload: Payload<SystemManagerFormData>): Promise<DataResponse<null>> {
    try {
      const { body } = payload;
      const bodyData = {
        company_id: body.company.value,
        first_name: body.firstName,
        last_name: body.lastName,
        job_title: body.jobTitle,
        email: body.workEmail,
        contact_number: body.contactNumber,
        location_id: body.location?.value,
        user_setting_sms_notification_status: body.userSettingSMSNotificationStatus ? 1 : 0,
        user_setting_email_notification_status: body.userSettingEmailNotificationStatus ? 1 : 0,
        user_setting_whatsapp_notification_status: body.userSettingWhatsappNotificationStatus ? 1 : 0,
        managerType: body.managerType.value,
      };
      const res: AxiosResponse<{ message: string; data: IManager }> = await httpClient.post(
        `portal/manager/create-location-manager`,
        removeEmpty(bodyData),
      );
      const { data } = res;
      return {
        status: true,
        message: data.message,
      };
    } catch (error: any) {
      const {
        message = i18next.t<string>('message.createdLocationManagerUnsuccess'),
        description,
      } = error;
      return Promise.reject({
        status: false,
        description: mapErrorDescriptions(managerPayloadScheme, description),
        message,
      });
    }
  },
  async update(payload: Payload<ManagerFormData, { id: string }>): Promise<DataResponse<null>> {
    try {
      const { body, params } = payload;
      const bodyData = {
        first_name: body.firstName,
        last_name: body.lastName,
        job_title: body.jobTitle,
        email: body.workEmail,
        contact_number: body.contactNumber,
        location_id: body.location?.value,
        user_setting_sms_notification_status: body.userSettingSMSNotificationStatus ? 1 : 0,
        user_setting_email_notification_status: body.userSettingEmailNotificationStatus ? 1 : 0,
        user_setting_whatsapp_notification_status: body.userSettingWhatsappNotificationStatus ? 1 : 0
      };
      const res: AxiosResponse<{ message: string; data: IManager }> = await httpClient.put(
        `${basePath}/edit/${params.id}`,
        removeNullish(removeEmpty(bodyData)),
      );
      const { data } = res;
      return {
        status: true,
        message: data.message,
      };
    } catch (error: any) {
      const { message, description } = error;
      return Promise.reject({
        status: false,
        description: mapErrorDescriptions(managerPayloadScheme, description),
        message: message || i18next.t<string>('message.updatedLocationManagerUnsuccess'),
      });
    }
  },
  async updateBySuperAdmin(
    payload: Payload<SystemManagerFormData, { id: string }>,
  ): Promise<DataResponse<null>> {
    try {
      const { body, params } = payload;
      const bodyData = {
        company_id: body.company.value,
        first_name: body.firstName,
        last_name: body.lastName,
        job_title: body.jobTitle,
        email: body.workEmail,
        contact_number: body.contactNumber,
        location_id: body.location?.value,
        user_setting_sms_notification_status: body.userSettingSMSNotificationStatus ? 1 : 0,
        user_setting_email_notification_status: body.userSettingEmailNotificationStatus ? 1 : 0,
        user_setting_whatsapp_notification_status: body.userSettingWhatsappNotificationStatus ? 1 : 0
      };
      const res: AxiosResponse<{ message: string; data: IManager }> = await httpClient.put(
        `portal/manager/edit-location-manager/${params.id}`,
        removeNullish(removeEmpty(bodyData)),
      );
      const { data } = res;
      return {
        status: true,
        message: data.message,
      };
    } catch (error: any) {
      const { message, description } = error;
      return Promise.reject({
        status: false,
        description: mapErrorDescriptions(managerPayloadScheme, description),
        message: message || i18next.t<string>('message.updatedLocationManagerUnsuccess'),
      });
    }
  },
  async disable(payload: Payload<null, { id: string }>): Promise<DataResponse<null>> {
    try {
      const { params } = payload;
      const res: AxiosResponse<{ message: string; data: IManager }> = await httpClient.delete(
        `${basePath}/disable/${params.id}`,
      );
      return {
        status: true,
        message: res.data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.disableLocationUnsuccess') } = error;
      return Promise.reject({
        status: false,
        message,
      });
    }
  },
  async enable(payload: Payload<null, { id: string }>): Promise<DataResponse<null>> {
    try {
      const { params } = payload;
      const res: AxiosResponse<{ message: string; data: IManager }> = await httpClient.put(
        `${basePath}/enable/${params.id}`,
      );
      return {
        status: true,
        message: res.data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.enableLocationUnsuccess') } = error;
      return Promise.reject({
        status: false,
        message,
      });
    }
  },
  async disableSystemManager(payload: Payload<null, { id: string }>): Promise<DataResponse<null>> {
    try {
      const { params } = payload;
      const res: AxiosResponse<{ message: string; data: IManager }> = await httpClient.put(
        `/portal/manager/disable-location-manager/${params.id}`,
      );
      return {
        status: true,
        message: res.data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.disableLocationUnsuccess') } = error;
      return Promise.reject({
        status: false,
        message,
      });
    }
  },
  async enableSystemManager(payload: Payload<null, { id: string }>): Promise<DataResponse<null>> {
    try {
      const { params } = payload;
      const res: AxiosResponse<{ message: string; data: IManager }> = await httpClient.put(
        `/portal/manager/enable-location-manager/${params.id}`,
      );
      return {
        status: true,
        message: res.data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.enableLocationUnsuccess') } = error;
      return Promise.reject({
        status: false,
        message,
      });
    }
  },
  async updateStatus(id: string, action: 'enable' | 'disable'): Promise<DataResponse<null>> {
    if (action === 'enable') {
      return locationManagerApi.enable({ params: { id } });
    }
    return locationManagerApi.disable({ params: { id } });
  },
  async updateStatusSystemManager(
    id: string,
    action: 'enable' | 'disable',
  ): Promise<DataResponse<null>> {
    if (action === 'enable') {
      return locationManagerApi.enableSystemManager({ params: { id } });
    }
    return locationManagerApi.disableSystemManager({ params: { id } });
  },
  async locationOptions(): Promise<DataResponse<Option[]>> {
    try {
      const res: AnyAxiosResponse<Option[]> = await httpClient.get(`${basePath}/location`);
      const { data, message } = res.data;

      return {
        status: true,
        data: orderOptions(data),
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async summary(): Promise<
    DataResponse<{ total: number; totalActive: number; totalDisable: number }>
  > {
    try {
      const authorizedPath = getPathByRole({
        [Role.SuperAdmin]: 'portal/manager/total-number-of-location-managers',
        [Role.Internal]: 'portal/manager/total-number-of-location-managers',
        [Role.UserAdmin]: 'portal/manager/total-number-of-location-managers',
        [Role.HqManager]: 'portal/locationUser/summary',
      });
      const res: AxiosResponse<{
        data: { total: number; total_enable: number; total_disable: number };
        message: string;
      }> = await httpClient.get(authorizedPath ?? `${basePath}/summary`);
      const {
        data: {
          data: { total, total_enable: totalActive, total_disable: totalDisable },
          message,
        },
      } = res;
      return {
        status: true,
        data: { total, totalActive, totalDisable },
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async exportTermsAndConditions() {
    try {
      const res = await httpClient.get(`portal/dashboard/download-aptus-technologies-terms`, {
        responseType: 'arraybuffer',
      });

      return {
        status: true,
        data: res.data,
        message: i18next.t<string>('message.exportSuccess'),
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
};

export type LocationManagerApi = typeof locationManagerApi;
