import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Image,
  Link,
  Spacer,
  Stack,
  StackProps,
  VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import { ENV } from 'configs';
import { FC, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useAppDispatch } from 'store';
import { DeviceMode, ScreenMode } from 'types';
import {
  addHexOpacity,
  convertTimeToHour,
  getFilenameExtension,
  isNotSupportBackdropFilter,
} from 'utils';

import { modalActions } from 'store/modules/modal';

import { colors } from 'themes/foundations/colors';

import { applicantApi } from 'apis/applicant';
import { displayUniqueId, mapContactNumber } from 'apis/utils';

import {
  APPLICANT_IDENTITY_STATUS_COLOR,
  APPLICANT_IDENTITY_STATUS_LABEL,
  ApplicantIdentityStatus,
  ApplicantIdentityType,
  IApplicant,
} from 'models/applicant';
import { IUserCertificate } from 'models/certificate';

import { InternalPermission } from 'types/permission';

import { PrimaryButton, Typography, WorkExperience, fontSizes } from 'components/atoms';
import RatingInput from 'components/atoms/RatingInput';
import PayslipRequestModal, {
  PAYSLIP_REQUEST_MODAL,
} from 'components/organisms/ApplicantForm2/PayslipRequestModal';

import {
  useConfirmState,
  useDeviceMode,
  useExportQuery,
  useGenderLabels,
  useScreenMode,
  useToasts,
  useTranslate,
} from 'hooks/common';
import { useHasPermissions } from 'hooks/components/auth/useHasPermissions';

import { BackIcon, ExternalLinkIcon } from 'assets/icons';

import { Rate } from './';
import { ApplicantAvatar } from './ApplicantAvatar';
import { ApplicantSuspendButtonAction } from './ApplicantSuspendButtonAction';
import { BadgeDropdown } from './BadgeDropdown';
import { ConfirmModal, ConfirmModalProps } from './ConfirmModal';
import { EmployeeBadges } from './EmployeeBadges';

export interface ApplicantInfoProps extends StackProps {
  applicant: IApplicant | null;
  popupContainer?: ConfirmModalProps['containerRef'];
  onBack?: () => void;
  onApplicantUpdate?: () => void;
}

export const ApplicantInfo: FC<ApplicantInfoProps> = ({
  applicant,
  popupContainer,
  onBack,
  onApplicantUpdate,
  ...props
}) => {
  const [rating, setRating] = useState<string | undefined>('');
  const { notiSuccess, notiFailed } = useToasts();
  const screen = useScreenMode();
  const t = useTranslate();
  const device = useDeviceMode();
  const dispatch = useAppDispatch();

  const hasVerifyPermission = useHasPermissions(InternalPermission.VERIFY_IDENTITY_DOCUMENT);

  const [, openConfirm, , modalController] = useConfirmState();
  const [isRatingOpen, openRatingModal, , updateRatingModalController] = useConfirmState();

  const [updateIdentityActionType, setUpdateIdentityActionType] = useState<
    null | 'valid' | 'invalid'
  >(null);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [download, isDownloading] = useExportQuery(
    applicantApi.downloadDocument,
    applicant?.identity_document
      ? `${applicant.full_name}_document.${getFilenameExtension(applicant.identity_document)}`
      : 'attachment',
  );
  // const [downloadVn] = useExportQuery(applicantApi.downloadDocumentVn, (payload) => {
  //   return `${applicant?.full_name}_document.${getFilenameExtension(payload)}`;
  // });

  const handleOpenCertification = (url: string) => {
    window.open(url, '_blank');
  };

  const downloadVn = async (documentUrl: string) => {
    try {
      const res = await axios.get(documentUrl, { responseType: 'arraybuffer' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(new Blob([res.data]));
      link.href = url;
      link.download = `${applicant?.full_name}_document.${getFilenameExtension(documentUrl)}`;
      link.click();
      URL.revokeObjectURL(url);
      link.remove();
    } catch (error: any) {
      notiFailed(error.message || 'Download Failed');
    }
  };

  const genderLabels = useGenderLabels();

  const {
    company,
    is_deleted,
    address,
    domicile,
    work_experiences,
    education,
    unique_id,
    rate,
    full_name,
    gender,
    age,
    otp_code,
    email,
    identity_type,
    identity_document,
    identity_status,
    jod_badges,
    avatar_url,
    total_working_hours,
    total_completed_jobs,
    food_hygiene_certificate_required: food_hygiene,
    food_hygiene_certificate_url,
    citizen_identity_card_urls,
    certificate_urls,
  } = applicant || {};

  const canVerifyIdentity = hasVerifyPermission && !!applicant?.unique_id;
  const isDeleted = is_deleted === 1;
  const firstSuspendLabel =
    identity_status && ['-1', '-2', '-4'].includes(identity_status) && 'Invalid ID';
  const secondSuspendLabel: string =
    identity_status && ['-3', '-4'].includes(identity_status)
      ? t('value.suspended')
      : applicant?.pending_suspension
        ? 'Pending Suspension'
        : t('value.unsuspended');

  const handleOkConfirm = async () => {
    try {
      if (!applicant || !updateIdentityActionType) throw new Error('');
      const res = await applicantApi.updateIdentityStatus(applicant.id, updateIdentityActionType);
      onApplicantUpdate && onApplicantUpdate();
      notiSuccess(res.message);
    } catch (error: any) {
      notiFailed(error.message || t('message.actionFailed'));
    }
  };

  const handleClickValidIdentity = () => {
    setUpdateIdentityActionType('valid');
    openConfirm();
  };
  const handleClickInvalidIdentity = () => {
    setUpdateIdentityActionType('invalid');
    openConfirm();
  };

  const handleDownloadDocument = () => {
    if (applicant && !isDownloading) {
      download(applicant.id);
    }
  };

  const identityActionButtons = (
    <Stack
      direction={{
        base: device === DeviceMode.Mobile ? 'column' : 'row',
        md: 'column',
        '2xl': 'row',
      }}
      // w="min-content"
      pt="5px"
    >
      <Button
        width={{ base: '115px', md: 'initial' }}
        variant="primary"
        fontSize="sm"
        size="xs"
        minHeight="1.5rem"
        height="auto"
        px="16px"
        whiteSpace="normal"
        isDisabled={applicant?.identity_status === ApplicantIdentityStatus.Valid}
        onClick={handleClickValidIdentity}
      >
        {t('label.maskAsValid')}
      </Button>
      <Button
        width={{ base: '115px', md: 'initial' }}
        variant="primary"
        fontSize="sm"
        size="xs"
        minHeight="1.5rem"
        height="auto"
        px="16px"
        whiteSpace="normal"
        isDisabled={applicant?.identity_status === ApplicantIdentityStatus.Invalid}
        onClick={handleClickInvalidIdentity}
      >
        {t('label.maskAsInvalid')}
      </Button>
    </Stack>
  );

  const scrollBarStyles = {
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      width: '6px',
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#e0e0e0',
      borderRadius: '24px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#bdbdbd',
    },
  };

  useEffect(() => {
    setRating(String(rate));
  }, [rate, isRatingOpen]);

  return (
    <>
      <ConfirmModal
        controller={modalController}
        header={false}
        containerRef={popupContainer}
        typeOverlay="blurWhite"
        type="card"
        bodyProps={{
          maxW: { base: '80vw', lg: '50vw' },
          textAlign: 'center',
        }}
        onOk={handleOkConfirm}
        footer={(close, ok) => {
          return (
            <VStack>
              <PrimaryButton onClick={ok} px="40px">
                {t('button.yesIDo')}
              </PrimaryButton>
              <Typography.Text cursor="pointer" fontSize="md" onClick={close}>
                {t('button.noIDoNot')}
              </Typography.Text>
            </VStack>
          );
        }}
      >
        <Typography.Title align="center" fontSize={{ base: 'md', lg: 'lg' }}>
          {updateIdentityActionType === 'valid' && t('message.choseMarkApplicantIdentityAsValid')}
          {updateIdentityActionType === 'invalid' &&
            t('message.choseMarkApplicantIdentityAsInvalid')}
          <br />
          {t('message.wantToContinue')}
        </Typography.Title>
      </ConfirmModal>

      <ConfirmModal
        typeOverlay="blurWhite"
        // bodyProps={{
        //   maxW: { base: '80vw', lg: '50vw' },
        //   textAlign: 'center',
        // }}

        overlayProps={{
          bg: addHexOpacity('#ffffff', isNotSupportBackdropFilter ? 100 : 0),
          backdropFilter: 'blur(30px) brightness(105%)',
          borderRadius: 'xl',
        }}
        contentProps={{
          boxShadow: `0px 3px 6px ${addHexOpacity('#000000', 16)}`,
          borderRadius: '8px',
          p: { base: '20px 20px', lg: '20px 40px', '2xl': '50px 80px' },
          w: { base: '100%', lg: 'initial' },
        }}
        controller={updateRatingModalController}
        headerProps={{ p: 0 }}
        header={false}
        bodyProps={{ p: 0 }}
        footerProps={{ p: 0, mt: { base: '20px', lg: '20px', '2xl': '30px' } }}
        containerRef={popupContainer}
        // onOk={handleApproveApplicant}
        onOk={async () => {
          if (!applicant?.id || rating === undefined) {
            return;
          }
          try {
            const res = await applicantApi.updateAverageRating({
              applicantId: applicant?.id,
              rate: rating,
            });
            onApplicantUpdate?.();
            notiSuccess(res.message);
          } catch (error: any) {
            notiFailed(error.message || t('message.actionFailed'));
          }
        }}
        type="card"
        footer={(close, ok) => {
          return (
            <Stack
              w="100%"
              direction={{ base: 'column', md: 'row' }}
              spacing={{ base: '5px', md: '25px' }}
              align={{ base: 'stretch', md: 'center' }}
              justify="center"
            >
              <Button
                variant="primary"
                fontFamily="bold"
                size={device === DeviceMode.Mobile ? 'md' : 'lg'}
                fontSize={fontSizes.sm}
                w={{ base: '100%', md: '150px', lg: '120px', xl: '130px', '2xl': '150px' }}
                onClick={ok}
              >
                {t('button.confirm')}
              </Button>

              {device === DeviceMode.Mobile ? (
                <Typography.Text
                  textAlign="center"
                  cursor="pointer"
                  onClick={close}
                  fontWeight={{ base: 'initial', lg: 'bold' }}
                  fontSize="md"
                >
                  {t('button.cancel')}
                </Typography.Text>
              ) : (
                <Button
                  variant="secondaryOutline"
                  fontFamily="bold"
                  size="lg"
                  fontSize={fontSizes.sm}
                  w={{ base: '100px', md: '150px', lg: '120px', xl: '130px', '2xl': '150px' }}
                  onClick={close}
                  textTransform="uppercase"
                >
                  {t('button.cancel')}
                </Button>
              )}
            </Stack>
          );
        }}
      >
        <VStack>
          <Typography.Title fontSize={{ base: 'md', md: 'sm-lg', lg: 'sm-lg', '2xl': 'lg' }}>
            {t('message.pleaseSelectAverageRating')}
          </Typography.Title>
          <RatingInput
            value={rating}
            onValueChange={(value) => {
              setRating(value);
            }}
          />
        </VStack>
      </ConfirmModal>

      <PayslipRequestModal applicant={applicant} />
      <Box
        boxShadow={{ base: `0 3px 3px ${colors.tangoOpacity[10]}`, md: 'none' }}
        background={{ base: '#fff', md: 'none' }}
        p={{ base: '10px', md: '0' }}
        borderRadius={{ base: '10px', md: 'none' }}
        mb={{ base: '10px', md: '0' }}
        position="relative"
      >
        <Grid
          columnGap="20px"
          templateColumns={{ base: 'min-content 1fr', md: 'min-content 1fr 1fr' }}
          pr={{ base: 0, md: '35px', lg: '48px' }}
        >
          <Stack direction={{ base: 'column' }} alignItems={{ base: 'center', md: 'inherit' }}>
            <BadgeDropdown
              popoverStyleProps={{
                bottom: { base: '-15px', md: '-20px' },
                right: { base: '-15px', md: '-20px' },
              }}
              label="JOD Badges"
              badges={jod_badges}
            >
              {!isDeleted ? (
                <ApplicantAvatar
                  boxSize={{ base: '60px', md: '80px', lg: '100px', '2xl': '120px' }}
                  subLogoProps={{
                    boxSize: { base: '25px', md: '40px', '2xl': '42px' },
                    top: { base: '-5px', lg: '-15px' },
                    left: { base: '-5px', lg: '-15px' },
                  }}
                  applicantName={full_name}
                  applicantImage={avatar_url}
                  companyImage={company?.url_company_logo}
                  companyName={company?.name}
                />
              ) : (
                <ApplicantAvatar
                  boxSize={{ base: '60px', md: '80px', lg: '100px', '2xl': '120px' }}
                  subLogoProps={{
                    boxSize: { base: '25px', md: '40px', '2xl': '42px' },
                    top: { base: '-5px', lg: '-15px' },
                    left: { base: '-5px', lg: '-15px' },
                  }}
                />
              )}
            </BadgeDropdown>
          </Stack>
          {screen !== ScreenMode.Mobile && (
            <Box h="95%" position="relative">
              <Box>
                {!isDeleted ? (
                  <Typography.Title
                    lineHeight="1"
                    fontSize={{ md: 'md', lg: 'sm-lg', '2xl': 'lg' }}
                    minW="50px"
                    noOfLines={{ base: undefined, '2xl': 3 }}
                    wordBreak="break-all"
                  >
                    {applicant ? full_name : '...'}
                  </Typography.Title>
                ) : (
                  <Typography.Title
                    lineHeight="1"
                    fontSize={{ md: 'md', lg: 'sm-lg', '2xl': 'lg' }}
                    minW="50px"
                    color="danger"
                  >
                    {t('label.deletedAccount')}
                  </Typography.Title>
                )}

                <Typography.Text whiteSpace="nowrap">
                  {!isDeleted ? (
                    <Trans t={t} i18nKey={'label.yearOld'} values={{ age: age }} />
                  ) : (
                    ''
                  )}
                </Typography.Text>
                {gender !== undefined && (
                  <div>
                    <Typography.Text>{genderLabels[gender]}</Typography.Text>
                  </div>
                )}
                <Typography.Text minW="50px" noOfLines={{ base: undefined, '2xl': 5 }} breakWord>
                  {ENV.INSTANCE.VIETNAM ? domicile : address}
                </Typography.Text>

                {!isDeleted && (
                  <GridItem mt="5px">
                    <Typography.Text variant="bold">{t('label.workHistory')}</Typography.Text>
                    <Box
                      // w={{ base: 'auto', md: '220px', xl: '300px' }}
                      h="70px"
                      borderRadius="5px"
                      overflowY="scroll"
                      py="5px"
                      css={{
                        ...scrollBarStyles,
                      }}
                    >
                      {!!work_experiences?.length ? (
                        <WorkExperience experiences={work_experiences} />
                      ) : (
                        <Typography.Text variant="italic">{t('label.noHistory')}</Typography.Text>
                      )}
                    </Box>
                  </GridItem>
                )}
                <GridItem mt="5px">
                  <Typography.Text variant="bold">{t('label.certificates')}</Typography.Text>
                  <Box
                    // w={{ base: 'auto', md: '220px', xl: '300px' }}
                    h="70px"
                    borderRadius="5px"
                    overflowY="scroll"
                    py="5px"
                    css={{
                      ...scrollBarStyles,
                    }}
                  >
                    {!!certificate_urls?.length ? (
                      certificate_urls?.map((item, index) => {
                        return (
                          <HStack key={index}>
                            <Typography.Text variant="italic">
                              {item.name} - {item.content}
                            </Typography.Text>
                            <Link href={item.certificate_url} target="_blank">
                              <ExternalLinkIcon />
                            </Link>
                          </HStack>
                        );
                      })
                    ) : (
                      <Typography.Text variant="italic">
                        {t('label.noCertificates')}
                      </Typography.Text>
                    )}
                  </Box>
                </GridItem>
                <Box colSpan={10}>
                  <Typography.Text variant="bold" whiteSpace="nowrap">
                    {t('label.certifications')}
                  </Typography.Text>
                  <HStack
                    borderRadius="5px"
                    py="5px"
                    w="100%"
                    alignItems="flex-start"
                    overflowY="scroll"
                    css={{
                      ...scrollBarStyles,
                    }}
                  >
                    {!!applicant?.user_certificates?.length ? (
                      applicant?.user_certificates.map(
                        (certificate: IUserCertificate, index: number) => {
                          if (certificate.file_url !== null) {
                            return (
                              <VStack
                                key={index}
                                alignItems="center"
                                mb="2"
                                onClick={() => handleOpenCertification(certificate.file_url)}
                                w="100px"
                                flexShrink={0}
                                cursor="pointer"
                              >
                                <Image
                                  size="md"
                                  name={certificate.name}
                                  src={certificate.logo}
                                  borderColor="transparent"
                                  mb="2"
                                  style={{ width: '50px', height: '50px' }}
                                />
                                <Typography.Text maxWidth="150px" textAlign="center" mb="2">
                                  {certificate.name}
                                </Typography.Text>
                              </VStack>
                            );
                          }
                        },
                      )
                    ) : (
                      <Typography.Text variant="italic">
                        {t('label.noCertificates')}
                      </Typography.Text>
                    )}
                  </HStack>
                </Box>
                {/* <HStack spacing={{ md: '15px', lxl: '20px' }}>
                  <Typography.Text variant="bold">{t('label.foodHygieneCert')}</Typography.Text>
                  <Checkbox size="lg" isChecked={!!food_hygiene} cursor="initial" />
                  {food_hygiene_certificate_url && (
                    <Link href={food_hygiene_certificate_url} target="_blank">
                      <ExternalLinkIcon />
                    </Link>
                  )}
                </HStack> */}
                <Box mt={5}>
                  <Button
                    boxShadow="0 3px 6px #f05e144d"
                    variant="primary"
                    type="button"
                    lineHeight="1"
                    size="xs"
                    px="24px"
                    textTransform="uppercase"
                    onClick={() => dispatch(modalActions.open(PAYSLIP_REQUEST_MODAL))}
                    isLoading={false}
                  >
                    {t('button.payslipRequest')}
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
          <Grid
            h="95%"
            templateColumns={{
              base: '90px minmax(100px, 1fr)',
              md: 'max-content minmax(100px, 1fr)',
            }}
            columnGap="10px"
            rowGap={{ base: '5px', md: 'initial' }}
            pl={{ md: '30px', lg: 'initial' }}
          >
            {screen === ScreenMode.Mobile && (
              <>
                {!isDeleted ? (
                  <Typography.Title lineHeight="1" size="md" minW="50px">
                    {applicant ? full_name : '...'}
                  </Typography.Title>
                ) : (
                  <Typography.Title lineHeight="1" size="md" color="danger" minW="50px">
                    {t('label.deletedAccount')}
                  </Typography.Title>
                )}

                <Typography.Text whiteSpace="nowrap">
                  {!isDeleted ? (
                    <Trans t={t} i18nKey={'label.yearOld'} values={{ age: age }} />
                  ) : (
                    ''
                  )}
                </Typography.Text>
                <GridItem colSpan={2}>
                  <Typography.Text noOfLines={{ base: undefined, '2xl': 5 }} breakWord>
                    {ENV.INSTANCE.VIETNAM ? domicile : address}
                  </Typography.Text>
                </GridItem>
                <GridItem colSpan={2}>
                  <Typography.Text variant="bold">{t('label.workHistory')}</Typography.Text>
                  {!isDeleted && (
                    <Box
                      w={{ base: 'auto', md: '220px', xl: '300px' }}
                      h="70px"
                      borderRadius="5px"
                      overflowY="scroll"
                      py="5px"
                      css={{
                        ...scrollBarStyles,
                      }}
                    >
                      {!!work_experiences?.length ? (
                        <WorkExperience experiences={work_experiences} />
                      ) : (
                        <Typography.Text variant="italic">{t('label.noHistory')}</Typography.Text>
                      )}
                    </Box>
                  )}
                </GridItem>
              </>
            )}
            <Typography.Title>{t('label.userAccountID')}</Typography.Title>
            <Typography.Text>{applicant?.id}</Typography.Text>
            <Typography.Title>{t('label.contactNumber')}</Typography.Title>
            <Typography.Text>
              {applicant && !isDeleted ? mapContactNumber(applicant) : null}
            </Typography.Text>
            <Typography.Title>{t('label.email')}</Typography.Title>
            <Typography.Text>{email}</Typography.Text>
            <Typography.Title>{t('label.verifyCode')}</Typography.Title>
            <Typography.Text>{otp_code}</Typography.Text>
            {identity_type === ApplicantIdentityType.FIN && (
              <>
                <Typography.Title>{t('label.school')}</Typography.Title>
                <Typography.Text breakWord>{education?.name}</Typography.Text>
              </>
            )}
            <Typography.Title>
              {ENV.INSTANCE.VIETNAM ? 'CMND/CCCD' : t('label.nric')}
            </Typography.Title>

            <VStack align="flex-start" spacing="2px">
              {!isDeleted && applicant && unique_id && (
                <Typography.Text>{displayUniqueId(applicant)}</Typography.Text>
              )}

              {/* Idenity check (NRIC) */}
              {!isDeleted && identity_status && (
                <Typography.Title
                  color={
                    firstSuspendLabel
                      ? APPLICANT_IDENTITY_STATUS_COLOR[identity_status]
                      : APPLICANT_IDENTITY_STATUS_COLOR[1]
                  }
                >
                  {firstSuspendLabel || 'Valid ID'}
                </Typography.Title>
              )}
              {ENV.INSTANCE.SINGAPORE && identity_document && (
                <Typography.Text
                  cursor={isDownloading ? 'default' : 'pointer'}
                  onClick={handleDownloadDocument}
                  opacity={isDownloading ? 0.6 : 1}
                >
                  {`attachment.${getFilenameExtension(identity_document)}`}
                </Typography.Text>
              )}
              {ENV.INSTANCE.VIETNAM &&
                citizen_identity_card_urls?.map((item, index) => {
                  return (
                    <Typography.Text
                      key={index}
                      cursor={isDownloading ? 'default' : 'pointer'}
                      onClick={async () => {
                        await downloadVn(item.identity_document_url);
                      }}
                    >
                      {`attachment.${getFilenameExtension(item.identity_document_url)}`}
                    </Typography.Text>
                  );
                })}

              {!isDeleted &&
                screen !== ScreenMode.Mobile &&
                canVerifyIdentity &&
                identityActionButtons}
              {!isDeleted && screen === ScreenMode.Mobile && canVerifyIdentity && (
                <GridItem colSpan={2} justifySelf="center">
                  {identityActionButtons}
                </GridItem>
              )}
            </VStack>

            {/* Suspended user - cannot apply for jobs */}
            <Typography.Title>{t('label.suspended')}</Typography.Title>

            <VStack align="flex-start" spacing="2px">
              {applicant && (
                <HStack>
                  {applicant.suspension_reason && (
                    <Typography.Text variant="italic">
                      Reason:{' '}
                      {applicant.suspension_reason
                        ? applicant.suspension_reason
                        : 'Banned by admin'}
                      , Job ID:{' '}
                      {applicant.more_suspension_details && applicant.more_suspension_details.id
                        ? applicant.more_suspension_details.id
                        : ''}
                    </Typography.Text>
                  )}

                  {applicant.unsuspend_request &&
                    applicant.unsuspend_request.unsuspend_document && (
                      <Link href={applicant?.unsuspend_request?.unsuspend_document} target="_blank">
                        <ExternalLinkIcon />
                      </Link>
                    )}
                </HStack>
              )}

              {!isDeleted && screen !== ScreenMode.Mobile && identity_status && (
                <Typography.Title
                  color={
                    secondSuspendLabel
                      ? APPLICANT_IDENTITY_STATUS_COLOR[identity_status]
                      : APPLICANT_IDENTITY_STATUS_COLOR[0]
                  }
                >
                  {secondSuspendLabel}
                </Typography.Title>
              )}

              {!isDeleted &&
                screen !== ScreenMode.Mobile &&
                applicant?.pending_suspension_details && (
                  <Box>
                    <Typography.Title onClick={toggleDropdown} style={{ cursor: 'pointer' }}>
                      {isOpen ? 'Hide Details' : 'Show Details'}
                    </Typography.Title>
                    <Collapse in={isOpen} animateOpacity>
                      <Box>
                        <Typography.Title>
                          Reason: {applicant?.pending_suspension_details.incident}
                        </Typography.Title>
                        <Typography.Paragraph>
                          ID: {applicant?.pending_suspension_details.id}
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                          Title: {applicant?.pending_suspension_details.title}
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                          Start Time: {applicant?.pending_suspension_details.start_time}
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                          Description: {applicant?.pending_suspension_details.description}
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                          Location: {applicant?.pending_suspension_details.location}
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                          Date of pending applied: {applicant?.pending_suspension_details.date}
                        </Typography.Paragraph>
                      </Box>
                    </Collapse>
                  </Box>
                )}

              {!isDeleted && screen !== ScreenMode.Mobile && (
                <ApplicantSuspendButtonAction
                  applicant={applicant}
                  popupContainer={popupContainer}
                  onApplicantUpdate={onApplicantUpdate}
                />
              )}

              {!isDeleted && screen === ScreenMode.Mobile && identity_status && (
                <Typography.Title
                  color={
                    secondSuspendLabel
                      ? APPLICANT_IDENTITY_STATUS_COLOR[identity_status]
                      : APPLICANT_IDENTITY_STATUS_COLOR[0]
                  }
                >
                  {secondSuspendLabel}
                </Typography.Title>
              )}

              {!isDeleted && screen === ScreenMode.Mobile && (
                <GridItem colSpan={2} justifySelf="center">
                  <ApplicantSuspendButtonAction
                    applicant={applicant}
                    popupContainer={popupContainer}
                    onApplicantUpdate={onApplicantUpdate}
                  />
                </GridItem>
              )}
            </VStack>
          </Grid>
        </Grid>

        {screen !== ScreenMode.Mobile && (
          <IconButton
            position="absolute"
            top="0"
            right="0"
            aria-label="go back"
            icon={<BackIcon />}
            variant="iconOutline"
            onClick={onBack}
          />
        )}

        <HStack align="flex-start" mt={{ base: '25px', md: '15px' }}>
          <Spacer />
          <VStack textAlign="center" spacing="0" flex={1} flexBasis="30%">
            <Typography.Title lineHeight="1" fontSize="lg">
              {convertTimeToHour(total_working_hours ?? 0, 's')}
            </Typography.Title>
            <Typography.Text
              // whiteSpace="nowrap"
              fontFamily="italic"
            >
              <Trans
                t={t}
                i18nKey={'label.totalWorkingHours_breakLine'}
                // components={[<br key={0} />]}
              />
            </Typography.Text>
          </VStack>
          {screen !== ScreenMode.Mobile && <Spacer />}
          <Box
            cursor="pointer"
            onClick={() => {
              openRatingModal();
            }}
          >
            {screen === ScreenMode.Mobile ? (
              <>
                {screen !== ScreenMode.Mobile && <Spacer />}
                <VStack textAlign="center" spacing="4px" flex={1} flexBasis="30%">
                  <VStack spacing="4px">
                    <Rate
                      spacing={{ base: '3px', md: 'initial' }}
                      value={rate ?? 0}
                      viewOnly
                      size="20px"
                    />
                    <Typography.Text fontFamily="italic">
                      {t('label.averageRating')}
                    </Typography.Text>
                  </VStack>
                </VStack>
              </>
            ) : (
              <>
                <Spacer />
                <VStack spacing="2px">
                  <Rate value={rate ?? 0} viewOnly size="20px" />
                  <Typography.Text fontFamily="italic">{t('label.averageRating')}</Typography.Text>
                </VStack>
                <Spacer />
              </>
            )}
          </Box>
          {screen !== ScreenMode.Mobile && <Spacer />}
          <VStack textAlign="center" spacing="0" flex={1} flexBasis="30%">
            <Typography.Title lineHeight="1" fontSize="lg">
              {total_completed_jobs ?? 0}
            </Typography.Title>
            <Typography.Text
              // whiteSpace="nowrap"
              fontFamily="italic"
            >
              <Trans
                t={t}
                i18nKey={'label.totalCompletedJobs_breakLine'}
                components={[<br key={0} />]}
              />
            </Typography.Text>
          </VStack>
          <Spacer />
        </HStack>
        <HStack align="flex-start" mt={{ base: '25px', md: '15px' }}>
          <Spacer />
          <VStack textAlign="center" spacing="0" flex={1} flexBasis="30%">
            <Typography.Title lineHeight="1" fontSize="lg">
              {t('label.employeeBadges').toString()}
            </Typography.Title>
            <EmployeeBadges badges={jod_badges} />
          </VStack>
          <Spacer />
        </HStack>
      </Box>
    </>
  );
};
