import dayjs, { Dayjs } from 'dayjs';
import 'rc-picker/assets/index.css';
import dayjsGenerator from 'rc-picker/lib/generate/dayjs';

import generatePicker, {
  RangePickerProps as BaseRangePickerProps,
  PickerDateProps,
  PickerProps,
} from './generatePicker';
import './styles/index.scss';

export type DatePickerProps = PickerProps<Dayjs>;
export type MonthPickerProps = Omit<PickerDateProps<Dayjs>, 'picker'>;
export type WeekPickerProps = Omit<PickerDateProps<Dayjs>, 'picker'>;
export type RangePickerProps = BaseRangePickerProps<Dayjs>;

type IlocaleMapObject = Record<string, string>;
const localeMap: IlocaleMapObject = {
  // ar_EG:
  // az_AZ:
  // bg_BG:
  bn_BD: 'bn-bd',
  by_BY: 'be',
  // ca_ES:
  // cs_CZ:
  // da_DK:
  // de_DE:
  // el_GR:
  en_GB: 'en-gb',
  en_US: 'en',
  // es_ES:
  // et_EE:
  // fa_IR:
  // fi_FI:
  fr_BE: 'fr', // todo: dayjs has no fr_BE locale, use fr at present
  fr_CA: 'fr-ca',
  // fr_FR:
  // ga_IE:
  // gl_ES:
  // he_IL:
  // hi_IN:
  // hr_HR:
  // hu_HU:
  hy_AM: 'hy-am',
  // id_ID:
  // is_IS:
  // it_IT:
  // ja_JP:
  // ka_GE:
  // kk_KZ:
  // km_KH:
  kmr_IQ: 'ku',
  // kn_IN:
  // ko_KR:
  // ku_IQ: // previous ku in antd
  // lt_LT:
  // lv_LV:
  // mk_MK:
  // ml_IN:
  // mn_MN:
  // ms_MY:
  // nb_NO:
  // ne_NP:
  nl_BE: 'nl-be',
  // nl_NL:
  // pl_PL:
  pt_BR: 'pt-br',
  // pt_PT:
  // ro_RO:
  // ru_RU:
  // sk_SK:
  // sl_SI:
  // sr_RS:
  // sv_SE:
  // ta_IN:
  // th_TH:
  // tr_TR:
  // uk_UA:
  // ur_PK:
  // vi_VN:
  zh_CN: 'zh-cn',
  zh_HK: 'zh-hk',
  zh_TW: 'zh-tw',
};

const parseLocale = (locale: string) => {
  const mapLocale = localeMap[locale];
  return mapLocale || locale.split('_')[0];
};

const capitalMonth = (date: string) => {
  const loweredDate = date.toLowerCase();
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  for (let index = 0; index < months.length; index++) {
    const item = months[index];
    const loweredMonth = item.toLowerCase();
    if (loweredDate.includes(loweredMonth)) {
      return loweredDate.replace(loweredMonth, item);
    }
  }
  return date;
};

dayjsGenerator.locale.parse = (locale, text, formats) => {
  const localeStr = parseLocale(locale);
  for (let i = 0; i < formats.length; i += 1) {
    const format = formats[i];
    const formatText = text;
    if (format.includes('wo') || format.includes('Wo')) {
      // parse Wo
      const year = formatText.split('-')[0];
      const weekStr = formatText.split('-')[1];
      const firstWeek = dayjs(year, 'YYYY').startOf('year').locale(localeStr);
      for (let j = 0; j <= 52; j += 1) {
        const nextWeek = firstWeek.add(j, 'week');
        if (nextWeek.format('Wo') === weekStr) {
          return nextWeek;
        }
      }
      return null;
    }
    const date = dayjs(formatText, format).locale(localeStr);
    if (date.isValid()) {
      return date;
    }
    if (/[a-zA-Z]/g.test(formatText)) {
      const invalidDate = dayjs(capitalMonth(formatText), format).locale(localeStr);
      if (invalidDate.isValid()) {
        return invalidDate;
      }
    }
  }
  return null;
};

dayjsGenerator.getNow = () => dayjs.tz(Date.now());
const DatePicker = generatePicker<Dayjs>(dayjsGenerator);

export default DatePicker;
