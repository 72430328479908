import { Stack, VStack } from '@chakra-ui/react';
import { PATH_NAME } from 'configs';
import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';
import { DeviceMode } from 'types';

import { Card, PrimaryButton, Typography } from 'components/atoms';

import { useDeviceMode, useRouter, useTranslate } from 'hooks/common';
import { usePaymentSummary } from 'hooks/components/payment';

import { LAYOUT_CONFIG } from 'configs/layout';

export const FuturePaymentWidget: React.FC = () => {
  const router = useRouter();
  const device = useDeviceMode();
  const t = useTranslate();
  const [, , { unpaidTotal }, loading] = usePaymentSummary();
  const handleGoFuturePayment = () => {
    router.push(PATH_NAME.PAYMENT_FUTURE);
  };
  return (
    <Card
      round={LAYOUT_CONFIG.dashboard.round}
      fitParent
      p={LAYOUT_CONFIG.dashboard.widgetPadding}
      textAlign="center"
      loading={loading}
    >
      <Stack
        h="100%"
        direction={{ base: 'column', md: 'row' }}
        align={{ base: 'stretch', md: 'center' }}
      >
        <VStack w={{ base: '100%', md: 'max-content', '2xl': '50%' }} mb={{ base: '20px', md: 0 }}>
          {device === DeviceMode.Mobile && (
            <Typography.Title size="lg" alignSelf="flex-start" textTransform="uppercase">
              {t('label.futurePayment')}
            </Typography.Title>
          )}
          <Typography.Title
            fontSize={{ base: 'lg', md: '19px', lg: 'sm-3xl', '2xl': '3xl' }}
            lineHeight="1"
          >
            {unpaidTotal || 0}
          </Typography.Title>
          <Typography.Text whiteSpace="nowrap">
            <Trans
              t={t}
              i18nKey={'message.totalNumberOfFuturePayments'}
              components={[device !== DeviceMode.Mobile ? <br key={0} /> : <Fragment />]}
            />
          </Typography.Text>
        </VStack>
        <VStack flex={{ base: 'initial', md: '1' }}>
          {device !== DeviceMode.Mobile && (
            <Typography.Title size="lg" textTransform="uppercase">
              {t('label.futurePayment')}
            </Typography.Title>
          )}
          <PrimaryButton
            whiteSpace="normal"
            minW={LAYOUT_CONFIG.button.width}
            onClick={handleGoFuturePayment}
            textTransform="uppercase"
          >
            {t('label.allFuturePayments')}
          </PrimaryButton>
        </VStack>
      </Stack>
    </Card>
  );
};
