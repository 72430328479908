import { Icon, useBreakpointValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import noop from 'lodash/noop';
import { useRef } from 'react';
import Select, {
  ActionMeta,
  DropdownIndicatorProps,
  MenuProps,
  MultiValueRemoveProps,
  PropsValue,
  components,
} from 'react-select';
import { DeviceMode, Option } from 'types';
import { isNotSupportBackdropFilter } from 'utils';

import { colors } from 'themes/foundations/colors';
import { typography } from 'themes/foundations/typography';
import { zIndices } from 'themes/foundations/z-index';

import { useDeviceMode, useScreenMode, useTranslate } from 'hooks/common';

import { CaretDownIcon, CloseIcon } from 'assets/icons';

import { addHexOpacity } from 'utils/opacity';
import { JobTypeOption } from 'models/job';

export interface SelectBoxProps<OptionType extends Option = any, IsMulti extends boolean = false> {
  isMulti?: IsMulti;
  allowClear?: boolean;
  disabled?: boolean;
  allowSearch?: boolean;
  initialValue?: PropsValue<OptionType>;
  options?: OptionType[];
  borderRadius?: string;
  px?: string;
  autoResize?: boolean;
  autoResizeInMobile?: boolean;
  value?: PropsValue<OptionType>;
  loading?: boolean;
  name?: string;
  placeholder?: string;
  maxMenuHeight?: number;
  closeMenuOnSelect?: boolean;
  hideSelectedOptions?: boolean;
  id?: string;
  width?: string | number;
  blurInputOnSelect?: boolean;
  isInvalid?: boolean;
  valueContainerMaxHeight?: string | number;
  valueContainerHeight?: string | number;
  hasIndicator?: boolean;
  showJobTypeBackground? : boolean,
  onChange?: (
    newValue: IsMulti extends true ? OptionType[] : OptionType,
    actionMeta: ActionMeta<OptionType>,
  ) => void;
  onBlur?: () => void;
}

const MultiValueRemove = <OptionType extends Option = any, IsMulti extends boolean = false>(
  props: MultiValueRemoveProps<OptionType, IsMulti>,
) => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon as={CloseIcon} boxSize={{ base: '8px', md: '10px', lg: '12px', '2xl': '14px' }} />
    </components.MultiValueRemove>
  );
};

export const Menu = <OptionType extends Option = any, IsMulti extends boolean = false>(
  props: MenuProps<OptionType, IsMulti>,
) => {
  return (
    <components.Menu {...props}>
      <motion.div
        initial="collapsed"
        animate="open"
        exit="collapsed"
        transition={{ duration: 0.3, ease: 'easeOut' }}
        variants={{
          open: { opacity: 1, height: 'auto' },
          collapsed: { opacity: 1, height: 0, overflow: 'hidden' },
        }}
        style={{ overflow: 'hidden' }}
      >
        {props.children}
      </motion.div>
    </components.Menu>
  );
};

export const DropdownIndicator = <OptionType extends Option = any, IsMulti extends boolean = false>(
  props: DropdownIndicatorProps<OptionType, IsMulti>,
) => {
  const { isDisabled } = props;
  return (
    <components.DropdownIndicator {...props}>
      <Icon color="tango.300" as={CaretDownIcon} opacity={isDisabled ? '0.4' : 1} />
    </components.DropdownIndicator>
  );
};

export const SelectBox = <OptionType extends Option = any, IsMulti extends boolean = false>(
  props: SelectBoxProps<OptionType, IsMulti>,
) => {
  const {
    isMulti,
    disabled,
    allowClear,
    allowSearch,
    initialValue,
    options,
    borderRadius = '8px',
    px = '16px',
    autoResize = false,
    onChange = noop,
    onBlur = noop,
    value: controlledValue,
    loading,
    name,
    placeholder,
    maxMenuHeight,
    closeMenuOnSelect,
    hideSelectedOptions = true,
    width,
    autoResizeInMobile,
    isInvalid,
    valueContainerMaxHeight,
    valueContainerHeight,
    hasIndicator = true,
    showJobTypeBackground = false,
    ...rest
  } = props;

  const device = useDeviceMode();
  const screen = useScreenMode();
  const t = useTranslate();
  const valuePx = useBreakpointValue(
    {
      base: '8px',
      md: '10px',
      lg: '14px',
      lxl: '14px',
      '2xl': '16px',
    },
    screen,
  );

  const fontFamily = useBreakpointValue(
    {
      base: 'Roboto',
      md: 'Roboto',
      lg: 'Roboto',
      xl: 'Roboto',
      lxl: 'Roboto',
      '2xl': 'Roboto',
    },
    screen,
  );
  const fontSize = useBreakpointValue(
    {
      base: typography.fontSizes.md,
      md: typography.fontSizes.md,
      lg: typography.fontSizes['sm-md'],
      lxl: typography.fontSizes['sm-md'],
      '2xl': typography.fontSizes.md,
    },
    screen,
  );
  const containerHeight = useBreakpointValue(
    { base: '40px', md: '40px', lg: '40px', xl: '40px', lxl: '40px', '2xl': '48px' },
    screen,
  );
  const selectRef = useRef<any>();

  const hasHexColor = (option: Option): option is JobTypeOption => {
    return (option as JobTypeOption).hex_color !== undefined;
  };

  return (
    <Select
      placeholder={placeholder || t('placeholder.pleaseSelectAnOption')}
      maxMenuHeight={maxMenuHeight}
      name={name}
      options={options}
      hideSelectedOptions={hideSelectedOptions}
      onChange={(data, { action }) => {
        if (action === 'clear' || action === 'remove-value') {
          setTimeout(() => selectRef.current.blur(), 1);
        }
        onChange(data);
      }}
      onBlur={onBlur}
      isClearable={allowClear ?? true}
      isMulti={isMulti}
      isSearchable={allowSearch ?? true}
      value={controlledValue}
      isDisabled={disabled}
      isLoading={loading}
      defaultValue={initialValue}
      closeMenuOnSelect={closeMenuOnSelect ?? (isMulti ? false : true)}
      components={{
        DropdownIndicator,
        MultiValueRemove,
        Menu,
      }}
      styles={{
        container: (base) => ({ ...base, width: width ?? '100%', fontSize }),
        control: (base: any, state: any) => {
          const { isFocused, isDisabled } = state;
          const focusedStyle = isFocused
            ? {
                boxShadow: '0 0 0 1px #3182ce',
                borderColor: '#3182ce',
                '&:hover ': {
                  borderColor: '#3182ce',
                },
              }
            : undefined;

          const invalidStyle = isInvalid
            ? {
                boxShadow: `0 0 0 1px ${colors.danger}`,
                borderColor: colors.danger,
                '&:hover ': {
                  borderColor: colors.danger,
                },
              }
            : undefined;

          return {
            ...base,
            outline: 0,
            inline: 0,
            minHeight: containerHeight,
            height: valueContainerHeight,
            backgroundColor: '#fefefe',
            borderRadius,
            borderColor: 'inherit',
            zIndex: zIndices.dropdown + 1,
            '&:hover': {
              borderColor: '#cbd5e0',
            },
            ...invalidStyle,
            ...focusedStyle,
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            opacity: isDisabled ? 0.4 : 1,
            pointerEvents: 'auto',
          };
        },
        multiValue: (base) => ({
          ...base,
          position: 'relative',
          backgroundColor: 'unset',
          cursor: 'pointer',
          alignItems: 'center',
          display: 'flex',
          borderRadius: '10px',
          border: `1px solid ${addHexOpacity('#324562', 50)}`,
          padding: `2px ${valuePx} 2px ${valuePx}`,
          '& > div:first-of-type': {
            fontSize: 'inherit',
            padding: `0 ${valuePx} 0 0 `,
          },
        }),
        singleValue: (base, {data}) => ({
          ...base,
          position: 'absolute',
          backgroundColor: showJobTypeBackground && hasHexColor(data) ? data.hex_color : 'unset',
          cursor: 'pointer',
          alignItems: 'normal',
          display: 'flex',
          borderRadius: '20px',
          padding: `2px ${valuePx} 2px ${valuePx}`,
          '& > div:first-of-type': {
            fontSize: 'inherit',
            padding: `0 ${valuePx} 0 0 `,
          },
        }),
        valueContainer: (base) => ({
          ...base,
          // fontSize: '1rem',
          fontFamily,
          color: colors.secondary,
          paddingLeft: px,
          paddingRight: px,
          overflowY: 'auto',
          maxHeight:
            autoResize || (autoResizeInMobile && device === DeviceMode.Mobile)
              ? valueContainerMaxHeight ?? 'unset'
              : containerHeight,
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#e0e0e0',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#bdbdbd',
          },
        }),
        multiValueRemove: (base) => ({
          ...base,
          '&:hover': {
            backgroundColor: 'unset',
            color: 'unset',
          },
        }),
        menuList: (base) => ({
          ...base,
          paddingBottom: '0px',
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#e0e0e0',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#bdbdbd',
          },
        }),
        option: (provided: any) => {
          return {
            ...provided,
            fontSize: '1rem',
            cursor: 'pointer',
            backgroundColor: 'transparent',
            '&:not(:last-of-type)': {
              borderBottom: '1px solid #C0C4CB',
            },
            '&:hover': {
              backgroundColor: `${colors.tango[200]}`,
            },
          };
        },
        menu: (base) => {
          return {
            ...base,
            marginTop: '-4px',
            borderTopLeftRadius: '0 !important',
            backdropFilter: 'blur(16px) brightness(100%)',
            backgroundColor: `${addHexOpacity(
              '#ffffff',
              isNotSupportBackdropFilter ? 100 : 80,
            )} !important`,
            borderTopRightRadius: '0 !important',
            zIndex: zIndices.dropdown,
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#e0e0e0',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#bdbdbd',
            },
          };
        },
        placeholder: (base) => {
          return {
            ...base,
            // fontSize: '1rem',
            fontFamily: 'Roboto Italic',
            whiteSpace: 'nowrap',
            color: '#707070',
            opacity: 0.5,
            position: 'absolute',
          };
        },
        indicatorsContainer: (base, state) => {
          const { menuIsOpen } = state.selectProps;
          return {
            ...base,
            cursor: 'pointer',
            display: hasIndicator ? 'flex' : 'none',
            '& div:last-child': {
              transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform .3s linear',
            },
          };
        },
        indicatorSeparator: (base) => ({ ...base, display: 'none' }),
        input: (base) => ({ ...base, visibility: 'inherit' }),
      }}
      theme={(theme) => {
        return {
          ...theme,
          colors: { ...theme.colors, neutral80: colors.secondary, neutral40: colors.secondary },
        };
      }}
      ref={selectRef}
      noOptionsMessage={() => t('value.noOptions')}
      {...rest}
    />
  );
};
