import { Button, Stack, VStack } from '@chakra-ui/react';
import { FC, useState } from 'react';

import { applicantApi } from 'apis/applicant';

import { IApplicant } from 'models/applicant';

import { PrimaryButton, Typography } from 'components/atoms';

import { useConfirmState, useToasts, useTranslate } from 'hooks/common';

import { ConfirmModal, ConfirmModalProps } from './ConfirmModal';

interface ApplicantSuspendButtonActionProps {
  applicant: IApplicant | null;
  popupContainer?: ConfirmModalProps['containerRef'];
  onApplicantUpdate?: () => void;
}

type SuspendStatusActionType = null | 0 | 1;

export const ApplicantSuspendButtonAction: FC<ApplicantSuspendButtonActionProps> = ({
  applicant,
  popupContainer,
  onApplicantUpdate,
}) => {
  const t = useTranslate();
  const [, openConfirm, , modalController] = useConfirmState();
  const { notiSuccess, notiFailed } = useToasts();

  const suspended = ['-3', '-4'].includes(`${applicant?.identity_status}`);
  const pending = applicant?.pending_suspension ? true : false;
  const isSuspended = suspended || pending;

  const [updateSuspendStatusActionType, setUpdateSuspendStatusActionType] =
    useState<SuspendStatusActionType>(null);

  const handleOpenConfirmationModal = (status: SuspendStatusActionType) => {
    setUpdateSuspendStatusActionType(status);
    openConfirm();
  };

  const handleOkConfirm = async () => {
    try {
      if (!applicant) throw new Error('');
      const res = await applicantApi.updateSuspendStatus(
        applicant.id,
        updateSuspendStatusActionType,
      );
      onApplicantUpdate && onApplicantUpdate();
      notiSuccess(res.message);
    } catch (error: any) {
      notiFailed(error.message || t('message.actionFailed'));
    } finally {
      setUpdateSuspendStatusActionType(null);
    }
  };

  return (
    <>
      <ConfirmModal
        controller={modalController}
        header={false}
        containerRef={popupContainer}
        typeOverlay="blurWhite"
        type="card"
        bodyProps={{
          maxW: { base: '80vw', lg: '50vw' },
          textAlign: 'center',
        }}
        onOk={handleOkConfirm}
        footer={(close, ok) => {
          return (
            <VStack>
              <PrimaryButton onClick={ok} px="40px">
                {t('button.yesIDo')}
              </PrimaryButton>
              <Typography.Text cursor="pointer" fontSize="md" onClick={close}>
                {t('button.noIDoNot')}
              </Typography.Text>
            </VStack>
          );
        }}
      >
        <Typography.Title align="center" fontSize={{ base: 'md', lg: 'lg' }}>
          {updateSuspendStatusActionType === 0 && t('message.choseMarkApplicantAsUnsuspended')}
          {updateSuspendStatusActionType === 1 && t('message.choseMarkApplicantAsSuspended')}
          <br />
          {t('message.wantToContinue')}
        </Typography.Title>
      </ConfirmModal>

      <Stack direction={{ base: 'row', md: 'column', '2xl': 'row' }} pt="5px">
        <Button
          width={{ base: '115px', md: 'initial' }}
          variant="primary"
          fontSize="sm"
          size="xs"
          minHeight="1.5rem"
          height="auto"
          px="16px"
          whiteSpace="normal"
          isDisabled={isSuspended}
          onClick={() => handleOpenConfirmationModal(1)}
        >
          {t('label.suspend')}
        </Button>
        <Button
          width={{ base: '115px', md: 'initial' }}
          variant="primary"
          fontSize="sm"
          size="xs"
          minHeight="1.5rem"
          height="auto"
          px="16px"
          whiteSpace="normal"
          isDisabled={!isSuspended}
          onClick={() => handleOpenConfirmationModal(0)}
        >
          {t('label.unsuspend')}
        </Button>
      </Stack>
    </>
  );
};
