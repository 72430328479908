import { useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { VoidFunc } from 'types';

import {
  PaymentState,
  isFetchingPaymentSummarySelector,
  paymentActions,
  paymentSummarySelector,
} from 'store/modules/payment';

import { Typography } from 'components/atoms';

import { useTranslate } from 'hooks/common';

export const usePaymentSummary = () => {
  const dispatch = useDispatch();
  const summary = useSelector(paymentSummarySelector);
  const loading = useSelector(isFetchingPaymentSummarySelector);
  const t = useTranslate();

  const fetchSummary = () => {
    dispatch(paymentActions.getPaymentSummary());
  };

  const sections = useMemo(() => {
    const { paymentTotal, processedTotal, unpaidTotal } = summary;
    return {
      all: [
        {
          label: (
            <Typography.Text>
              <Trans t={t} i18nKey={'label.totalNumberOfPayments'} components={[<br key={0} />]} />
            </Typography.Text>
          ),
          count: paymentTotal,
        },
      ],
      processed: [
        {
          label: (
            <Typography.Text>
              <Trans
                t={t}
                i18nKey={'label.totalNumberOfProcessedPayments'}
                components={[<br key={0} />]}
              />
            </Typography.Text>
          ),
          count: processedTotal,
        },
      ],
      unpaid: [
        {
          label: (
            <Typography.Text>
              <Trans
                t={t}
                i18nKey={'label.totalNumberOfUnpaidPayments'}
                components={[<br key={0} />]}
              />
            </Typography.Text>
          ),
          count: unpaidTotal,
        },
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary]);

  useEffect(() => {
    fetchSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const result: [typeof sections, VoidFunc, PaymentState['summary'], boolean] = [
    sections,
    fetchSummary,
    summary,
    loading,
  ];

  return result;
};
