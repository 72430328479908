import {
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import clevertap from 'clevertap-web-sdk';
import { ENV } from 'configs';
import { DATE_TIME_BE, FULL_HOUR_DISPLAY, dateFullHourDisplay } from 'constant';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store';

import { modalActions } from 'store/modules/modal';

import { applicantApi } from 'apis/applicant';

import { IApplicant } from 'models/applicant';

import DatePicker from 'components/atoms/DatePicker';

import { useToasts, useTranslate } from 'hooks/common';

import { LAYOUT_CONFIG } from 'configs/layout';

type PayslipRequestValue = {
  dateRange: [Dayjs, Dayjs] | null;
};

interface PayslipRequestModalProps {
  applicant: IApplicant | null;
}

export const PAYSLIP_REQUEST_MODAL = 'payslipRequest';

const PayslipRequestModal: FC<PayslipRequestModalProps> = ({ applicant }) => {
  const isOpen = useAppSelector((state) => state.modal[PAYSLIP_REQUEST_MODAL]);
  const dispatch = useAppDispatch();
  const { notiSuccess, notiFailed } = useToasts();
  const t = useTranslate();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PayslipRequestValue>({
    defaultValues: {
      dateRange: null,
    },
  });

  useEffect(() => {
    if (isOpen) {
      reset({
        dateRange: null,
      });
    }
  }, [reset, isOpen]);

  const handlePayslipRequest = async ({ dateRange }: PayslipRequestValue) => {
    try {
      if (!applicant) throw new Error('');
      const start_date = dayjs(dateRange?.[0])
        .tz(ENV.TIMEZONE, true)
        .set('second', 0)
        .format(DATE_TIME_BE);
      const end_date = dayjs(dateRange?.[1])
        .tz(ENV.TIMEZONE, true)
        .set('second', 0)
        .format(DATE_TIME_BE);

      const res = await applicantApi.payslipRequest({
        applicantId: applicant.id,
        start_date,
        end_date,
      });

      dispatch(modalActions.close(PAYSLIP_REQUEST_MODAL));
      clevertap.event.push('Payslip Request (Admin)');
      notiSuccess(res.message);
    } catch (error: any) {
      notiFailed(error.message || t('message.actionFailed'));
    }
  };

  return (
    <Modal
      onClose={() => {
        dispatch(modalActions.close(PAYSLIP_REQUEST_MODAL));
        setTimeout(() => {
          reset({ dateRange: null });
        }, 200);
      }}
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('title.payslipRequest')}</ModalHeader>
        <ModalBody>
          <form noValidate>
            <FormControl position="relative" isInvalid={!!errors.dateRange}>
              <Controller
                name="dateRange"
                control={control}
                rules={{
                  required: t('message.dateRangeRequired'),
                  validate: (value) => {
                    if (!value) return undefined;
                    const [start, end] = value;
                    if (!start || !end) return t('message.dateRangeRequired');
                    if (start >= end) return t('message.jobStartBeforeJobEnd');
                    return undefined;
                  },
                }}
                render={({ field: { onChange, ...fieldProps } }) => {
                  return (
                    <DatePicker.RangePicker
                      {...fieldProps}
                      picker="date"
                      placeholder={[t('placeholder.startDate'), t('placeholder.endDate')]}
                      showTime={{ format: FULL_HOUR_DISPLAY }}
                      format={dateFullHourDisplay()}
                      id="dateRange"
                      isInvalid={!!errors.dateRange}
                      onChange={(values) => {
                        if (!values) {
                          return;
                        }
                        const [start, end] = values;
                        onChange(start?.isAfter(end) ? [end, start] : [start, end]);
                      }}
                    />
                  );
                }}
              />
              <FormErrorMessage {...LAYOUT_CONFIG.form.errorMessage}>
                {(errors.dateRange as any)?.message}
              </FormErrorMessage>
            </FormControl>
          </form>
        </ModalBody>
        <ModalFooter>
          <HStack justify="flex-end">
            <Button
              onClick={handleSubmit(
                (data) => {
                  handlePayslipRequest(data);
                },
                (errors) => {
                  console.log(errors);
                },
              )}
            >
              CONFIRM
            </Button>
            <Button
              onClick={() => {
                dispatch(modalActions.close(PAYSLIP_REQUEST_MODAL));
              }}
            >
              CANCEL
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PayslipRequestModal;
